<template>
    <div>
        <EditTimestamp ref="EditTimestamp" @reload="handleReloadList" />
        <div>
            <Table
                :columns="tblColumns"
                :data="tblData"
                size="small"
                :loading="isLoading"
                show-summary
                :summary-method="handleSummary"
            />
            <Table
                v-if="tblData && tblData.length > 0"
                :show-header="false"
                :columns="overAllColumns"
                :data="overAllTotal"
                size="small"
                :loading="isLoading"
                no-data-text=""
            />
        </div>
        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="25"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
    </div>
</template>

<script>
import moment from 'moment';
import EditTimestamp from '@/components/Timetracker/EditTimestamp';
export default {
    components: {
        EditTimestamp,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        timeLogsList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        totalPerPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            tblColumns: [
                // {
                //   title: 'Date',
                //   key: 'createdAt',
                //   width: 200,
                //   render: (h, params) => {
                //     return h('div', (params.row.createdAt) ? moment(params.row.createdAt).format('YYYY-MM-DD hh:mm:ss') : '')
                //   }
                // },
                {
                    title: 'Project',
                    key: 'project',
                    //fixed: 'left',
                    minWidth: 200,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.elem.project && params.row.elem.project.name ? params.row.elem.project.name : ''
                        );
                    },
                },
                {
                    title: 'Member',
                    key: 'user',
                    minWidth: 200,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.elem.user && params.row.elem.user.name ? params.row.elem.user.name : ''
                        );
                    },
                },
                {
                    title: 'Start Time',
                    key: 'startTime',
                    width: 200,
                    // sortable: true,
                    // sortType: 'desc',
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.elem.startTime
                                ? moment(params.row.elem.startTime).utc().format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'End Time',
                    key: 'endTime',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.elem.endTime
                                ? moment(params.row.elem.endTime).utc().format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'Daily 8',
                    key: 'daily8',
                    width: 100,
                    render: (h, params) => {
                        return h('div', params.row.elem.daily8);
                    },
                },
                {
                    title: 'Row Daily',
                    key: 'rowDaily',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.elem.rowDaily);
                    },
                },
                {
                    title: 'Row Extra',
                    key: 'rowExtra',
                    width: 150,
                    render: (h, params) => {
                        // return h('div', (params.row.rowExtra))
                        if (params.row.rowExtra < 3) {
                            return h('div', { class: 'hyphenFont' }, '-');
                        } else {
                            return h('div', params.row.elem.rowExtra);
                        }
                    },
                },
                {
                    title: 'Appr Extra',
                    key: 'approvedExtra',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.elem.approvedExtra);
                    },
                },
                {
                    title: 'Actions',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-create',
                                        size: '20',
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.EditTimestamp.openModal(params.row);
                                        },
                                    },
                                },
                                'edit'
                            ),
                        ]);
                    },
                },
            ],
            tblData: [],
            overAllTotal: [],
            overAllColumns: [
                {
                    key: 'project',
                    //fixed: 'left',
                    minWidth: 200,
                    render: (h) => {
                        return h('span', 'Over All Total');
                    },
                },
                {
                    key: 'user',
                    minWidth: 200,
                    render: (h) => {
                        return h('span', '');
                    },
                },
                {
                    key: 'startTime',
                    width: 200,
                    // sortable: true,
                    // sortType: 'desc',
                    render: (h) => {
                        return h('div', '');
                    },
                },
                {
                    key: 'endTime',
                    width: 200,
                    render: (h) => {
                        return h('div', '');
                    },
                },
                {
                    key: 'daily8',
                    width: 100,
                    render: (h, params) => {
                        return h('div', Number(params.row.overAllTotalDaily8Count).toFixed(2));
                    },
                },
                {
                    key: 'rowDaily',
                    width: 150,
                    render: (h, params) => {
                        return h('div', Number(params.row.overAllTotalRowDailyCount).toFixed(2));
                    },
                },
                {
                    key: 'rowExtra',
                    width: 150,
                    render: (h, params) => {
                        return h('div', Number(params.row.overAllTotalRowExtraCount).toFixed(2));
                    },
                },
                {
                    key: 'approvedExtra',
                    width: 150,
                    render: (h, params) => {
                        return h('div', Number(params.row.overAllTotalApprovedExtraCount).toFixed(2));
                    },
                },
                {
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    render: (h) => {
                        return h('div', '');
                    },
                },
            ],
        };
    },

    watch: {
        timeLogsList(data) {
            this.tblData = data;
        },
        totalPerPage(total) {
            const arrTotal = [];
            arrTotal.push(total);
            this.overAllTotal = arrTotal;
        },
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
        handleSummary({ columns }) {
            const sums = {};
            columns.forEach((column, index) => {
                const key = column.key;
                if (index === 0) {
                    sums[key] = {
                        key,
                        value: 'Total',
                    };
                    return;
                } else if (index === 4) {
                    sums[key] = {
                        key,
                        value: Number(this.totalPerPage.totalDaily8Count).toFixed(2),
                    };
                    return;
                } else if (index === 5) {
                    sums[key] = {
                        key,
                        value: Number(this.totalPerPage.totalRowDailyCount).toFixed(2),
                    };
                    return;
                } else if (index === 6) {
                    sums[key] = {
                        key,
                        value: Number(this.totalPerPage.totalRowExtraCount).toFixed(2),
                    };
                    return;
                } else if (index === 7) {
                    sums[key] = {
                        key,
                        value: Number(this.totalPerPage.totalApprovedExtraCount).toFixed(2),
                    };
                    return;
                } else {
                    sums[key] = {
                        key,
                        value: '',
                    };
                    return;
                }
            });

            return sums;
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
</style>
