<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Add Time Record"
            width="1100"
            no-footer
            :before-close="handleReset"
        >
            <Form
                ref="form"
                :model="form"
                :rules="ruleInline"
                label-position="left"
                inline
                class="add-timetracker-wrapper"
            >
                <Row>
                    <Col span="12">
                        <FormItem label="Project" prop="selectedProject">
                            <Select
                                v-model="form.selectedProject"
                                filterable
                                clearable
                                @on-change="handleOnProjectChanged"
                            >
                                <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem label="Member" prop="selectedMember">
                            <Select v-model="form.selectedMember" filterable clearable>
                                <Option v-for="item in projectMembersList" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Start Time" prop="startTime">
                            <DatePicker
                                v-model="form.startTime"
                                type="datetime"
                                placeholder="Select date"
                                format="dd-MMM-yyyy hh:mm:ss A"
                                transfer
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Daily 8">
                            <Input v-model="form.daily8" />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Row Daily">
                            <Input v-model="form.rowDaily" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="End Time" prop="endTime">
                            <DatePicker
                                v-model="form.endTime"
                                type="datetime"
                                placeholder="Select date"
                                format="dd-MMM-yyyy hh:mm:ss A"
                                transfer
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Appr. Extra">
                            <Input v-model="form.approvedExtra" />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Row Extra">
                            <Input v-model="form.rowExtra" />
                        </FormItem>
                    </Col>
                </Row>
                <Row style="justify-content: center">
                    <Col span="8">
                        <FormItem label="Elapsed Time in Hours">
                            <Input v-model="form.elapsedTimeInHrs" />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" :loading="isLoading" @click="handleAddTimelog"> SAVE </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { postTimelogs, getAggregatedTimelogs } from '@/services/timelogs.services';
import { getProjectMembers, getProjectMembersByProjectId } from '@/services/project-members.services';
import { getProjects } from '@/services/projects.services';
import moment from 'moment/moment';
export default {
    data() {
        return {
            isLoading: false,
            timelogId: null,
            projectMembersList: [],
            projectsList: [],
            timeLogsList: [],
            form: {
                selectedProject: '',
                selectedMember: '',
                startTime: null,
                endTime: null,
            },
            ruleInline: {
                selectedProject: [
                    {
                        required: true,
                        message: 'Please select Project',
                        trigger: 'change',
                    },
                ],
                selectedMember: [
                    {
                        required: true,
                        message: 'Please select Project Member',
                        trigger: 'change',
                    },
                ],
                startTime: [
                    {
                        required: true,
                        type: 'date',
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                endTime: [
                    {
                        required: true,
                        type: 'date',
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            showModal: false,
        };
    },
    mounted() {
        // this.handleGetTimelogs()
        this.handleGetProjectMembersList();
        this.handleGetProjectsList();
    },
    methods: {
        handleGetTimelogs() {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({})
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.timeLogsList = res.data.data;
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs');
                    this.isLoading = false;
                });
        },

        handleAddTimelog() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.addTimelog();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        addTimelog() {
            this.isLoading = true;
            const data = {
                ...this.form,
                startTime: moment(this.form.startTime).utc().format(),
                endTime: moment(this.form.endTime).utc().format(),
                project: this.form.selectedProject,
                user: this.form.selectedMember,
            };
            postTimelogs(data)
                .then(() => {
                    this.$Message.success('Timelog successfully added');
                    this.isLoading = false;
                    this.$emit('add');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Start time and end time values should not be a future time'
                    ) {
                        this.$Message.error('Start time and end time values should not be a future time');
                    } else if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Overlapping timelogs with the current running,open timelog'
                    ) {
                        this.$Message.error('Overlapping timelogs with the current running,open timelog');
                    } else if (
                        (err.data.statusCode === 400 &&
                            err.data.message != 'Start time and end time values should not be a future time') ||
                        err.data.message != 'Overlapping timelogs with the current running,open timelog'
                    ) {
                        this.$Message.error('Failed to add timelog due to overlapping of timelog');
                    } else {
                        this.$Message.error('Failed to add timelog');
                    }
                    this.isLoading = false;
                });
        },

        handleGetTimelogsByProjectId(projId) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({ project: projId })
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.timeLogsList = res.data.data.filter((i) => i.elem.project._id == projId);
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        handleGetProjectsList() {
            this.projectsList = [];
            getProjects()
                .then((res) => {
                    if (res.data.length) {
                        const filteredData = res.data.filter(function (e) {
                            return e.isArchived === false && e.isActive === true;
                        });
                        this.projectsList = filteredData.map((i) => {
                            return {
                                value: i._id,
                                label: i.name,
                            };
                        });
                    } else {
                        this.projectsList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.projectsList = [];
                });
        },

        handleGetProjectMembersByProjectId(projId) {
            getProjectMembersByProjectId(projId)
                .then((res) => {
                    if (res.data.length) {
                        const temp = res.data.filter((x) => x.isProjectMemberActive === true);
                        this.parseMembersListData(temp);
                    } else {
                        this.projectMembersList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members by project');
                    this.projectMembersList = [];
                });
        },

        handleGetProjectMembersList() {
            this.projectMembersList = [];
            getProjectMembers()
                .then((res) => {
                    if (res.data.length) {
                        this.parseMembersListData(res.data);
                    } else {
                        this.projectMembersList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members list');
                    this.projectMembersList = [];
                });
        },

        parseMembersListData(data) {
            let resArr = [];
            data.filter((item) => {
                if (item.user) {
                    const i = resArr.findIndex((x) => x.user._id == item.user._id);
                    if (i <= -1) {
                        resArr.push(item);
                    }
                }
                return null;
            });
            this.projectMembersList = resArr
                .filter((x) => x.projectRole != 'client')
                .map((i) => {
                    return {
                        value: i.user._id,
                        label: i.user.name,
                    };
                });
            this.projectMembersList = this.projectMembersList.sort((a, b) => (a.label > b.label ? 1 : -1));
        },

        handleOnProjectChanged(projId) {
            this.selectedProject = projId;
            if (projId) {
                this.handleGetProjectMembersByProjectId(projId);
            } else {
                this.handleGetProjectMembersList();
            }
        },

        handleGetTimelogsByProjectUser(projId, userId) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({ project: projId, user: userId })
                .then((res) => {
                    if (res.data.data.length) {
                        this.timeLogsList = res.data.data.filter(
                            (i) => i.elem.project._id == projId && i.elem.user._id == userId
                        );
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs by project and user');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        handleGetTimeLogsByUser(userId) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({ user: userId })
                .then((res) => {
                    if (res.data.data.length) {
                        this.timeLogsList = res.data.data.filter((i) => i.elem.user._id == userId);
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs by user');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        openModal() {
            this.showModal = true;
        },

        handleCancel() {
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.form.resetFields();
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
}
.projClass {
    display: flex;
}

@media screen and (max-width: 767px) {
    .add-timetracker-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
