import { Pie } from 'vue-chartjs';

export default {
    extends: Pie,

    props: ['chartdataLabels', 'chartdataSets'],

    data() {
        return {
            chartdata: {
                labels: this.chartdataLabels,
                datasets: this.chartdataSets,
            },
            options: {
                data: {
                    labels: this.chartdataLabels,
                    datasets: this.chartdataSets,
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: (context, data) => {
                            let label = data.datasets[0].label[context.index];
                            let value = data.datasets[0].data[context.index];

                            if (!label) label = 'Unknown';

                            let percentage = value + '%';
                            return label + ': ' + percentage;
                        },
                    },
                },
            },
        };
    },
    mounted() {
        if (this.chartdata.datasets.length && this.chartdata.labels.length) {
            this.renderChart(this.chartdata, this.options);
        }
    },
    watch: {
        chartdataSets(val) {
            if (val) {
                this.chartdata.datasets = val;
                this.renderChart(this.chartdata, this.options);
            }
        },
        chartdataLabels(val) {
            if (val) {
                this.chartdata.labels = val;
                this.renderChart(this.chartdata, this.options);
            }
        },
    },
    // mounted() {
    //     this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    //     this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);

    //     this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)');
    //     this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    //     this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');

    //     this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    //     this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    //     this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)');
    //     this.renderChart(
    //         {
    //             labels: ['Books', 'Magazines', 'Newspapers'],
    //             datasets: [
    //                 {
    //                     backgroundColor: [this.gradient, this.gradient2, '#00D8FF'],
    //                     data: [40, 20, 10],
    //                 },
    //             ],
    //         },
    //         { responsive: true, maintainAspectRatio: false }
    //     );
    // },
};
