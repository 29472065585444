<template>
    <div class="users-table-list fullwidth">
        <Row type="flex" justify="space-between" class="code-row-bg" style="margin-bottom: 20px">
            <Col span="6">
                <Card class="page-title no-padding" dis-hover :bordered="false"> My Profile </Card>
            </Col>
            <Col span="6" class="text-right">
                <Button class="pt-btn-2" style="margin-right: 10px" @click="$router.go(-1)"> Back </Button>
            </Col>
        </Row>

        <Card class="section-title"> PERSONAL DATA </Card>
        <Form ref="form" :model="form" label-position="left" inline>
            <Row>
                <Col span="12">
                    <FormItem label="Full name" prop="name">
                        <Input v-model="form.name" readonly />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Main role" prop="mainRole" :disabled="true">
                        <!-- <Input v-model="form.mainRole" readonly style="text-transform: capitalize;"></Input> -->
                        <Select v-model="form.mainRole" :disabled="true">
                            <Option v-for="(mainRole, idx) in mainRole" :key="idx" :value="mainRole.value">
                                {{ mainRole.name }}
                            </Option>
                        </Select>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Email" prop="email">
                        <Input v-model="form.email" readonly />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Password" prop="password">
                        <Input v-model="form.password" type="password" readonly />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Archive user" class="activeSwitch">
                        <i-switch
                            v-model="form.isArchived"
                            class="switchColor"
                            false-color="#13ce66"
                            true-color="#ff4949"
                            :disabled="true"
                        />
                    </FormItem>
                </Col>
                <Col span="12">
                    <Button type="text" class="pt-btn-1 change-pass-btn" @click="$refs.ChangePasswordModal.openModal()">
                        Change Password
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Blockchain Wallet" prop="blockchainWalletAddress">
                        <Input v-model="form.blockchainWalletAddress" />
                    </FormItem>
                </Col>
            </Row>
            <Row class="md-margin-bottom">
                <Col span="12">
                    <ConnectWallet class="md-margin-left" @handleSetWalletAddress="handleSetWalletAddress" />
                </Col>
            </Row>

            <hr />
            <Row>
                <Col span="12">
                    <FormItem label="Third-party Apps" />
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Asana">
                        <Input v-model="form.asanaId" readonly />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Slack">
                        <Input v-model="form.slackId" readonly />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Trello">
                        <Input v-model="form.trelloId" readonly />
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="Bitbucket">
                        <Input v-model="form.bitbucketId" readonly />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span="12">
                    <FormItem label="Jira">
                        <Input v-model="form.jiraId" readonly />
                    </FormItem>
                </Col>
            </Row>
        </Form>
        <ChangePasswordModal ref="ChangePasswordModal" />
    </div>
</template>
<script>
import { getUsersById, updateUsersById } from '@/services/users.services';
import ChangePasswordModal from '@/components/Auth/ChangePassword';
import ConnectWallet from './ConnectWallet';
import '../../style.css';

export default {
    components: {
        ChangePasswordModal,
        ConnectWallet,
    },
    data() {
        return {
            errorMessages: [],
            user_id: localStorage.getItem('user_id'),
            form: {
                name: '',
                mainRole: '',
                email: '',
                password: '',
                jiraId: null,
                bitbucketId: null,
                isArchived: false,
            },
            valid: true,
            isLoading: false,
        };
    },
    mounted() {
        this.setFormDataValues();
    },
    methods: {
        async setFormDataValues() {
            this.form = await this.handleGetUsersById(this.user_id);
        },
        async handleGetUsersById(id) {
            return await getUsersById(id)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                });
        },
        async updateUsersById(data, syncWallet = false) {
            this.isLoading = true;
            await updateUsersById(this.form._id, {
                password: data.password,
                blockchainWalletAddress: data.blockchainWalletAddress,
            })
                .then(() => {
                    if (syncWallet) {
                        const action = data.disconnect ? 'unlinked' : 'linked';
                        this.$Message.success(`Successfully ${action} your blockchain wallet`);
                    } else {
                        this.$Message.success('Profile successfully updated!');
                    }

                    this.isLoading = false;
                    this.form = data;
                })
                .catch(() => {
                    this.isLoading = false;
                    this.$Message.error('Failed to update user profile');
                });
        },
        async handleSetWalletAddress(data) {
            const blockchainWalletAddress = data.address;
            await updateUsersById(this.form._id, {
                blockchainWalletAddress,
            })
                .then(() => {
                    const action = data.disconnect ? 'unlinked' : 'linked';
                    this.$Message.success(`Successfully ${action} your blockchain wallet`);

                    this.isLoading = false;
                    this.form = {
                        ...this.form,
                        blockchainWalletAddress,
                    };
                })
                .catch(() => {
                    this.isLoading = false;
                    this.$Message.error('Failed to update user blockchain wallet');
                });
        },
    },
};
</script>
<style lang="scss">
.switchColor.ivu-switch-checked {
    border-color: #44e53e;
    background-color: #44e53e;
}
.activeSwitch .ivu-form-item-content {
    display: block !important;
}
.change-pass-btn {
    margin-left: 130px;
}

@media screen and (max-width: 767px) {
    .users-table-list {
        form {
            .ivu-row {
                display: block;

                .ivu-col-span-12 {
                    flex: 0 0 100%;
                    max-width: 100%;

                    .change-pass-btn {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
</style>
