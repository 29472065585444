<template>
    <div class="client-projects">
        <Card class="section-title"> PROJECTS </Card>
        <Table
            highlight-row
            height="250"
            size="small"
            class="client-projects-tbl"
            :columns="tblColumns"
            :data="tblData"
            :loading="isLoading"
            @on-current-change="handleOnSelectedProjectChanged"
        />
    </div>
</template>

<script>
import moment from 'moment';
import { getProjectByClient } from '@/services/project-members.services';

export default {
    data() {
        return {
            user_id: localStorage.getItem('user_id'),
            isLoading: false,
            tblData: [],
            tblColumns: [
                {
                    title: 'Project Name',
                    key: 'name',
                },
                {
                    title: 'Start Date',
                    key: 'startDate',
                    render: (h, params) => {
                        return h('div', params.row.startDate ? moment(params.row.startDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'End Date',
                    key: 'endDate',
                    render: (h, params) => {
                        return h('div', params.row.endDate ? moment(params.row.endDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalProjectHours',
                },
                {
                    title: 'Progress %',
                    key: 'projectProgressPercentage',
                    render: (h, params) => {
                        return h('div', [
                            h('Progress', {
                                props: {
                                    percent: params.row.projectProgressPercentage,
                                    hideInfo: true,
                                    'stroke-color': '#49E97E',
                                },
                                on: {
                                    click: () => {},
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Actions',
                    key: 'id',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-analytics',
                                    size: '20',
                                },
                                on: {
                                    click: () => {
                                        this.$router.push(`/project-performance?id=${params.row.id}`);
                                    },
                                },
                            }),
                            h('Icon', {
                                props: {
                                    type: 'md-star',
                                    size: '20',
                                },
                                on: {
                                    click: () => {
                                        this.$router.push(`/project-feedback?id=${params.row.id}`);
                                    },
                                },
                            }),
                        ]);
                    },
                },
            ],
        };
    },

    mounted() {
        this.handleGetProjects();
    },

    methods: {
        handleGetProjects() {
            this.isLoading = true;
            this.tblData = [];
            getProjectByClient(this.user_id)
                .then((res) => {
                    if (res.data.length) {
                        this.tblData = res.data
                            .filter((x) => x.project.isActive === true && x.project.isArchived === false)
                            .map((i) => {
                                return {
                                    name: i.project.name,
                                    startDate: i.project.startDate,
                                    endDate: i.project.endDate,
                                    totalProjectHours: i.project.totalProjectHours,
                                    projectProgressPercentage: i.project.projectProgressPercentage,
                                    id: i.project._id,
                                    _highlight: res.data[0].project._id == i.project._id ? true : false,
                                };
                            });
                        this.$emit('on-project-select', res.data[0].project._id);
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.isLoading = false;
                });
        },
        handleOnSelectedProjectChanged(currentRow) {
            this.$emit('on-project-select', currentRow.id);
        },
    },
};
</script>

<style lang="scss">
.client-projects-tbl {
    tr.ivu-table-row.ivu-table-row-highlight td {
        background-color: #d2eafa;
    }
}
</style>
