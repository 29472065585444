<template>
    <div class="project-monitoring-wrapper">
        <Row type="flex" justify="space-between" class="code-row-bg" style="margin-bottom: 20px">
            <Col span="8">
                <Card class="page-title no-padding" dis-hover :bordered="false">
                    PROJECT MONITORING - <span style="text-transform: uppercase">{{ projectName }}</span></Card
                >
            </Col>
            <Col span="4" class="text-right">
                <Button class="pt-btn-5" style="margin-right: 10px; padding: 0 30px" @click="$router.go(-1)">
                    <strong>Go Back</strong>
                </Button>
            </Col>
        </Row>
        <Row :gutter="30" class="lg-margin-bottom">
            <Col span="16">
                <Row
                    type="flex"
                    class="lg-margin-bottom"
                    style="padding: 16px; border: 1px solid #dcdee2; border-radius: 30px; border-color: #e8eaec"
                >
                    <Col span="10"
                        ><h3 class="header1"><strong>HOURS SPENT BY TEAM</strong></h3>
                        <Table :columns="columns" :data="tblData" :loading="isLoading" class="devTable"></Table></Col
                    ><Col span="6" style="margin: auto"
                        ><h3 v-if="totalHrsSpent" class="header1" style="text-align: center">
                            <strong>{{ totalHrsSpent }} HOURS SPENT</strong>
                        </h3>
                        <TrackerPieGraph :chartdata-sets="chartData" :chartdata-labels="chartDataLabels" /></Col
                ></Row>
            </Col>
            <Col span="8">
                <Card dis-hover class="md-margin-bottom" style="border-radius: 30px"
                    ><h3 class="header2"><strong>PROJECT PROGRESS</strong></h3>
                    <div class="progress" style="display: flex; justify-content: center"></div>
                    <h3
                        v-if="totalHrsSpent"
                        style="text-align: center; margin-top: 10px; color: #463c6e; font-size: 18px"
                    >
                        <strong>{{ remainingHours }}/{{ totalProjectHours }} hours remaining</strong>
                    </h3>
                </Card>
            </Col>
        </Row>
        <Histogram />
    </div>
</template>

<script>
import { getProjectMonitoringById } from '@/services/projects.services';
import TrackerPieGraph from '@/components/Graphs/PieChart.js';
import CircleProgress from 'js-circle-progress';
import Histogram from '@/components/Projects/Histogram';

export default {
    components: {
        TrackerPieGraph,
        Histogram,
    },
    data() {
        return {
            isLoading: false,
            projectName: null,
            chartData: [],
            chartDataLabels: [],
            totalHrsSpent: null,
            remainingHours: null,
            totalProjectHours: null,
            projProgressPercentage: null,
            columns: [
                {
                    title: 'Name',
                    key: 'name',
                },
                {
                    title: 'Role',
                    key: 'projectMemberRole',
                    render: (h, params) => {
                        if (params.row.projectMemberRole) {
                            const role = this.projectRole.find((x) => x.value === params.row.projectMemberRole).name;
                            return h('div', [
                                // h('Icon', {
                                //     props: {
                                //         type: 'ios-egg',
                                //         size: '20',
                                //         color: this.userColor,
                                //     },
                                // }),
                                h('span', role),
                            ]);
                        } else {
                            return h('span', '');
                        }
                    },
                },
            ],
            tblData: [],
        };
    },
    async mounted() {
        this.handleGetProjectsById(this.$route.query.id);
    },
    methods: {
        async handleGetProjectsById(id) {
            this.chartData = [];
            this.isLoading = true;
            return await getProjectMonitoringById(id)
                .then((res) => {
                    if (res.data) {
                        this.projectName = res.data.project.name;
                        const teamData = res.data.members.map((x) => {
                            return {
                                name: x.user,
                                projectMemberRole: x.projectMemberRole,
                            };
                        });
                        this.tblData = teamData.map((i) => {
                            return {
                                name: i.name.name,
                                projectMemberRole: i.projectMemberRole,
                            };
                        });
                        this.totalProjectHours = res.data.project.totalProjectHours;
                        this.totalHrsSpent = res.data.totalHoursSpent;
                        this.remainingHours = this.totalProjectHours - this.totalHrsSpent;
                        this.remainingHours = this.remainingHours < 0 ? 0 : this.remainingHours.toFixed(2);
                        const projProgressPercent = (this.totalHrsSpent / this.totalProjectHours) * 100;
                        this.projProgressPercentage = new CircleProgress('.progress', {
                            value: this.totalHrsSpent > this.totalProjectHours ? 100 : projProgressPercent,
                            max: 100,
                            textFormat: 'percent',
                        });
                        let pieLabel = [];
                        let bColor = [];
                        let pieData = [];
                        pieLabel = res.data.roles.map((r) => {
                            return r._id;
                        });
                        bColor = res.data.roles.map((l) => {
                            return l.legendColor;
                        });
                        pieData = res.data.roles.map((p) => {
                            return p.percent.toFixed(2);
                        });
                        const dataObj = {
                            label: pieLabel,
                            backgroundColor: bColor,
                            data: pieData,
                        };
                        this.chartDataLabels = dataObj.label;
                        this.chartData.push(dataObj);
                        this.isLoading = false;
                    }
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style lang="scss">
.header1 {
    margin-bottom: 10px;
    color: #463c6e;
    font-size: 18px;
}
.header2 {
    margin-right: 5px;
    margin-bottom: 10px;
    color: #463c6e;
    text-align: center;
    font-size: 18px;
}
.devTable .ivu-table th {
    background-color: unset;
    color: #463c6e;
}
.devTable .ivu-table-cell {
    padding: 0 0px;
    color: #463c6e;
}
.devTable .ivu-table td,
.devTable .ivu-table th {
    border-bottom: 1px solid #828899;
}
.circle-progress {
    width: 200px;
    height: auto;
}
.circle-progress-text {
    fill: #49e87d;
}
.circle-progress-value {
    stroke: #49e87d;
}

@media screen and (max-width: 767px) {
    .project-monitoring-wrapper {
        .text-right {
            text-align: left;
        }
        .ivu-col {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 10px;
        }
    }
}
</style>
