<template>
    <div class="page-not-found fullheight">
        <div class="page-404">
            <h1>404</h1>
            <h2>Not Found</h2>
            <Button class="pt-btn-3" @click="$router.push('/login').catch(() => {})"> Back to home </Button>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-not-found {
    .page-404 {
        position: absolute;
        top: 50%;
        right: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        h1 {
            color: $error-color;
            font-size: 100px;
        }
        h2 {
            font-size: 30px;
            margin-bottom: 50px;
        }
    }
}
</style>
