<template>
    <div class="weekly-emails-monitoring">
        <Card class="page-title" dis-hover :bordered="false"> WEEKLY EMAILS </Card>
        <Card dis-hover :bordered="false">
            <div class="d-flex-center lg-margin-bottom">
                <Row class="fullwidth">
                    <Col flex="400px" class="d-flex-center project-selector">
                        <p class="md-margin-right">Project</p>
                        <Select
                            v-model="selectedProjectId"
                            filterable
                            clearable
                            :loading="isLoading"
                            class="md-margin-right"
                            style="max-width: 300px"
                            @on-change="handleSelectProjectChange(selectedProjectId)"
                        >
                            <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </Option>
                        </Select>
                        <DatePicker
                            v-model="monthData"
                            type="month"
                            format="yyyy-MM"
                            placeholder="Select month"
                            style="width: 350px"
                            @on-change="handleSelectProjectChange(selectedProjectId)"
                        />
                    </Col>
                </Row>
            </div>

            <div v-if="selectedProject" class="teamWeeklyEmailsSection" :loading="isLoading">
                <p
                    v-for="item in emailLogsData"
                    :key="item.value"
                    :value="item.value"
                    :loading="isLoading"
                    class="headerTeam"
                >
                    {{ item.label }}
                    <Row style="margin: 20px 0">
                        <Col
                            v-for="item in item.dateSent"
                            :key="item.value"
                            :value="item.value"
                            span="6"
                            :lg="6"
                            :md="18"
                            :sm="10"
                            :xs="24"
                        >
                            <Card>
                                <Row>
                                    <Col span="12"
                                        ><p>{{ item.dateSent }}</p></Col
                                    >
                                    <Col span="12" class="icons" style=""
                                        ><p v-if="item.type === 'plan'">
                                            <Icon type="md-list-box" size="18" /> <span>{{ item.orderlyRate }}</span>
                                        </p>
                                        <p v-else>
                                            <Icon type="md-list-box" size="18" /> <span>{{ item.getItDoneRate }}</span>
                                        </p>
                                        <p>
                                            <Icon type="ios-happy" /> <span>{{ item.chattyRate }}</span>
                                        </p>
                                        <p>
                                            <Icon type="md-git-branch" /> <span>{{ item.geekyRate }}</span>
                                        </p>
                                    </Col>
                                </Row>
                                <Col class="planSection">
                                    <Button
                                        v-if="item.type === 'plan'"
                                        class="planEmail"
                                        @click="showWeeklyEmailDetails(item)"
                                    >
                                        View Weekly Plan Email <Icon type="md-checkmark-circle" />
                                    </Button>
                                    <Button v-else class="planEmail" @click="showWeeklyEmailDetails(item)">
                                        View End of Week Email <Icon type="md-checkmark-circle" /> </Button
                                ></Col>
                                <Col class="taskList" :id="item._id"
                                    ><p v-if="item.type === 'plan'" class="taskList-header">To do</p>
                                    <p v-else class="taskList-header">Done Tasks</p>
                                    <div v-if="!item.tasks.length">No Data</div>
                                    <ul class="text">
                                        <li v-for="item in item.tasks" :key="item.value" :value="item.value">
                                            {{ item.task.title }}
                                        </li>
                                    </ul>
                                    <div class="btn-wrapper" v-if="item.tasks.length > 2">
                                        <Button
                                            class="seeToggle"
                                            type="text"
                                            icon="ios-arrow-dropdown"
                                            @click="handleSeeMore(item, $event)"
                                            ><span class="tempSpan">See More</span></Button
                                        >
                                    </div></Col
                                >
                                <Col class="taskRadarSection"
                                    ><Button
                                        class="taskRadar"
                                        @click="
                                            $router.push({
                                                name: 'TaskRadar',
                                                query: { id: item.user, email_id: item._id },
                                            })
                                        "
                                        >View Task Radar</Button
                                    ></Col
                                >
                            </Card>
                        </Col>
                    </Row>
                </p>
            </div>
            <WeeklyEmailsDetails ref="WeeklyEmailsDetails" />
        </Card>
    </div>
</template>

<script>
import moment from 'moment';
import { getUserProjectsWithTimelogs, getProjectsById, getProjects } from '@/services/projects.services';
import { getWeeklyEmailsLogs } from '@/services/weekly-emails.services';
import WeeklyEmailsDetails from '@/views/WeeklyEmailsDetails';
export default {
    components: {
        WeeklyEmailsDetails,
    },
    data() {
        return {
            isLoading: false,
            selectedProject: null,
            selectedProjectId: null,
            selectedProjectDetails: null,
            projectsList: [],
            user_id: localStorage.getItem('user_id'),
            monthData: null,
            emailLogsData: [],
            isOpen: false,
        };
    },

    mounted() {
        this.handleGetProjects();
        if (localStorage.getItem('selectedProject') && localStorage.getItem('selectedMonth')) {
            this.monthData = localStorage.getItem('selectedMonth');
            this.selectedProjectId = localStorage.getItem('selectedProject');
        } else {
            this.monthData = moment().format('yyyy-MM');
        }
    },

    methods: {
        handleReload() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },

        async handleGetProjects() {
            this.isLoading = true;
            this.projectsList = [];
            let allData = [];
            if (localStorage.getItem('mainRole') === 'admin') {
                allData = await this.handleGetAllProjects();
            } else {
                allData = await this.handleGetUserProjectsWithTimelogs();
            }
            if (allData) {
                const parsedAllData = allData.reduce((acc, obj) => {
                    if (obj.isActive && obj.isArchived === false) {
                        acc.push({
                            value: obj._id,
                            label: obj.name,
                        });
                    }
                    return acc;
                }, []);
                this.projectsList = await Promise.all(parsedAllData);
                this.projectsList = this.projectsList.sort((a, b) => (a.label > b.label ? 1 : -1));
            }

            if (this.selectedProjectId) {
                this.handleGetProjectById(this.selectedProjectId);
                this.handleGetWeeklyEmailLogs(this.selectedProjectId);
            }
            this.isLoading = false;
        },

        handleGetUserProjectsWithTimelogs() {
            return getUserProjectsWithTimelogs(localStorage.getItem('user_id'))
                .then((res) => {
                    if (res.data.length) {
                        return res.data;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                });
        },

        handleGetAllProjects() {
            return getProjects()
                .then((res) => {
                    if (res.data.length) {
                        return res.data;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get all projects');
                });
        },

        handleGetProjectById(id) {
            this.selectedProjectDetails = null;
            localStorage.setItem('selectedProject', id);
            getProjectsById(id)
                .then((res) => {
                    this.selectedProjectDetails = res.data;
                    this.selectedProject = {
                        value: this.selectedProjectDetails._id,
                        label: this.selectedProjectDetails.name,
                    };
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                });
        },

        async handleGetWeeklyEmailLogs(id) {
            this.isLoading = true;
            const month = moment(this.monthData).format('yyyy-MM');
            localStorage.setItem('selectedMonth', month);
            const { data } = await getWeeklyEmailsLogs(id, month);

            const emailLogsData =
                data &&
                data.length &&
                data.map((emailData) => {
                    const emailsList = emailData.emails.map((emailsData) => {
                        return {
                            ...emailsData,
                            dateSent: moment(emailsData.dateSent).format('MMMM D'),
                            orderlyRate: emailsData.orderlyRate ? emailsData.orderlyRate : '0',
                            getItDoneRate: emailsData.getItDoneRate ? emailsData.getItDoneRate : '0',
                            chattyRate: emailsData.chattyRate ? emailsData.chattyRate : '0',
                            geekyRate: emailsData.geekyRate ? emailsData.geekyRate : '0',
                            tasks: emailsData.tasks,
                            type: emailsData.type,
                            isOpen: false,
                        };
                    });
                    return {
                        value: emailData.user._id,
                        label: emailData.user.name,
                        dateSent: emailsList,
                    };
                });
            this.emailLogsData = emailLogsData;
            this.isLoading = false;
        },

        handleSelectProjectChange(val) {
            if (val) {
                this.handleGetProjectById(val);
                this.handleGetWeeklyEmailLogs(val);
            }
        },

        async showWeeklyEmailDetails(data) {
            if (data) {
                this.$refs.WeeklyEmailsDetails.openModal(data);
            } else {
                this.$Message.info('No email details yet');
            }
        },

        handleSeeMore(item, e) {
            const taskList = document.getElementById(item._id);
            const elements = taskList.getElementsByClassName('tempSpan');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }
            taskList.classList.toggle('open');
            item.isOpen = !item.isOpen;
            item.isOpen
                ? e.target.classList.add('ivu-icon-ios-arrow-dropup')
                : e.target.classList.add('ivu-icon-ios-arrow-dropdown');
            !item.isOpen
                ? e.target.classList.remove('ivu-icon-ios-arrow-dropup')
                : e.target.classList.remove('ivu-icon-ios-arrow-dropdown');

            item.isOpen ? (e.target.innerHTML = 'See Less') : (e.target.innerHTML = 'See More');
            //taskList.getElementsByTagName('span').remove();
        },
    },
};
</script>

<style lang="scss">
.teamWeeklyEmailsSection {
    .headerTeam {
        color: #463c6e;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;

        .ivu-row {
            align-items: stretch;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            .ivu-col.ivu-col-span-6 {
                padding: 5px 15px 15px 0;
            }
            .ivu-col.ivu-col-span-12 {
                display: flex;
            }
            i.ivu-icon {
                font-size: 18px;
                vertical-align: -1px;
            }
        }
    }
}
button.planEmail.ivu-btn.ivu-btn-default {
    display: block;
    width: 100%;
    background-color: #49e97e;
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
}
button.planEmail.ivu-btn:hover {
    border-color: unset;
}
button.taskRadar.ivu-btn.ivu-btn-default {
    display: block;
    width: 100%;
    background-color: #463c6e;
    color: white;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
}
button.taskRadar.ivu-btn:hover {
    border-color: unset;
}
.eowEmail .ivu-card-body {
    padding: 5px;
    text-align: center;
    color: #fff;
}
.sendEowEmail .ivu-card-body {
    padding: 5px;
    text-align: center;
    color: #fff;
}
.sendPlanEmail .ivu-card-body {
    padding: 5px;
    text-align: center;
    color: #fff;
}
.planSection.ivu-col {
    margin-top: 25px;
}
.sendEowSection.ivu-col {
    margin-top: 25px;
}
.sendPlanSection.ivu-col {
    margin-top: 25px;
}
.eowSection.ivu-col {
    margin-top: 25px;
}
.sendPlanEmail.ivu-card.ivu-card-bordered {
    background-color: #ffc107;
    border-radius: 8px;
}
.sendEowEmail.ivu-card.ivu-card-bordered {
    background-color: #ffc107;
    border-radius: 8px;
}
.eowEmail.ivu-card.ivu-card-bordered {
    background-color: #49e97e;
    border-radius: 8px;
}
.taskList.ivu-col {
    margin: 15px 16px 20px 0;
    font-weight: 400;
}
.taskList .taskList-header {
    font-weight: 600;
    margin-bottom: 15px;
}
.taskList li {
    margin-left: 16px;
}
.icons.ivu-col {
    justify-content: space-evenly;
}
.taskList {
    margin: 22px auto;
    width: 320px;
    padding: 12px 32px 64px;
    min-height: 210px;
    max-height: 210px;
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
    margin-bottom: 15px;
}
.taskList.open {
    height: auto;
    max-height: unset !important;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
    padding-bottom: 10px;
}
.taskList .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 12px 0;
    animation: close 0.1s linear 0.1s forwards;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}
.taskList.open .text {
    animation: open 0.1s linear 0s forwards;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
}
.btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    hr {
        flex: 1;
        border: none;
        height: 1px;
        background: #515a6e;
        margin: 30px 0;
        width: 100%;
        position: absolute;
        z-index: 0;
    }

    .seeToggle {
        width: 100%;
        z-index: 1;
        background: transparent;
        pointer-events: none !important;

        i {
            background: #fff;
            font-size: 13px !important;
            display: grid;
            font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑',
                Arial, sans-serif;
            line-height: 20px;
            pointer-events: visible !important;
        }
        i:before {
            font-family: 'Ionicons';
            font-size: 25px;
            pointer-events: visible !important;
            transition: all 0.5s;
        }
        .tempSpan {
            font-size: 13px;
        }
    }
}
/* See More/See Less animation */
@keyframes open {
    from {
        line-clamp: 5;
        -webkit-line-clamp: 5;
    }
    to {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }
}
@keyframes close {
    from {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }
    to {
        line-clamp: 5;
        -webkit-line-clamp: 5;
    }
}

@media screen and (max-width: 767px) {
    .weekly-emails-monitoring {
        .project-selector {
            display: block;

            .ivu-select {
                margin: 0 0 5px;
                width: 100% !important;
            }
            .ivu-date-picker {
                width: 100% !important;
            }
        }
        .seeToggle i {
            font-size: 13px !important;
        }
    }
}
</style>
>
