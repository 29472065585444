<template>
    <div>
        <div class="d-flex-center lg-margin-bottom">
            <Row class="fullwidth">
                <Col flex="400px" class="d-flex-center">
                    <div class="page-title md-margin-right">REWARDS</div>

                    <DatePicker
                        v-model="monthData"
                        type="month"
                        format="yyyy-MM"
                        placeholder="Select month"
                        style="width: 250px"
                        @on-change="handleGetCurrentMonth(monthData)"
                    />
                </Col>
            </Row>
        </div>
        <div>
            <Card class="reward-page-card" style="width: 100%">
                <div class="card-title">LEADERBOARD</div>
                <Row type="flex" justify="center" class="leaderboard-filter" style="margin-bottom: 10px">
                    <Button
                        :class="boardType === 'allEmp' ? 'active-board' : ''"
                        class="btn-config"
                        shape="circle"
                        style="margin-right: 15px"
                        @click="handleLeaderboardChange('allEmp')"
                        >All Employees
                    </Button>
                    <Button
                        :class="boardType === 'byProj' ? 'active-board' : ''"
                        class="btn-config"
                        shape="circle"
                        style="margin-right: 15px"
                        @click="handleLeaderboardChange('byProj')"
                        >By Project
                    </Button>
                    <Button
                        v-if="mainRole != 'client'"
                        :class="boardType === 'allProj' ? 'active-board' : ''"
                        class="btn-config"
                        shape="circle"
                        @click="handleLeaderboardChange('allProj')"
                        >All Projects
                    </Button>
                </Row>

                <div v-if="boardType === 'byProj'" class="project-dropdown-wrapper d-flex-center lg-margin-bottom">
                    <Row class="fullwidth">
                        <Col flex="500px" class="d-flex-center">
                            <div class="dropdown-title md-margin-right">Select Project</div>
                            <Select
                                v-model="selectedProjectId"
                                filterable
                                clearable
                                :loading="isLoading"
                                class="md-margin-right"
                                style="max-width: 300px"
                                @on-change="handleGetLeaderboardByProject(selectedProjectId)"
                            >
                                <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </Col>
                    </Row>
                </div>

                <!-- ALL EMPLOYEES -->
                <LeaderboardList
                    v-if="boardType === 'allEmp'"
                    ref="LeaderboardList"
                    :loading="isLoading"
                    :leaderboard-data="leaderboardData"
                    :month-data="new Date(monthData)"
                    :boardtype="boardType"
                />

                <!-- BY PROJECT -->
                <LeaderboardList
                    v-if="boardType === 'byProj'"
                    ref="LeaderboardList"
                    :loading="isLoading"
                    :leaderboard-data="leaderboardData"
                    :month-data="new Date(monthData)"
                    :boardtype="boardType"
                    :project="selectedProjectId"
                />

                <!-- ALL PROJECTS -->
                <LeaderboardList
                    v-if="mainRole != 'client' && boardType === 'allProj'"
                    ref="LeaderboardList"
                    :loading="isLoading"
                    :leaderboard-data="leaderboardData"
                    :month-data="new Date(monthData)"
                    :boardtype="boardType"
                />
            </Card>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import LeaderboardList from '@/components/Leaderboard/LeaderboardList';
import { getLeaderboard, getLeaderboardByProject, getLeaderboardAllProject } from '@/services/rewards.services';
import { getProjects } from '@/services/projects.services';
import '../style.css';
import { getProjectByClient } from '@/services/project-members.services';

export default {
    components: { LeaderboardList },
    data() {
        return {
            user_id: localStorage.getItem('user_id'),
            mainRole: localStorage.getItem('mainRole'),
            isLoading: false,
            monthData: null,
            boardType: null,
            leaderboardData: [],
            selectedProjectId: null,
            projectsList: [],
        };
    },
    mounted() {
        const shouldLoadData = localStorage.getItem('leaderboard.action') === 'reload_data';
        if (shouldLoadData) {
            // Stored values
            const storedDate = localStorage.getItem('leaderboard.date');
            this.monthData = storedDate ?? moment().format('yyyy-MM');
            this.boardType = localStorage.getItem('leaderboard.tab') ?? 'allEmp';
            this.selectedProjectId = localStorage.getItem('leaderboard.projectId');
        } else {
            // Default values
            this.monthData = moment().format('yyyy-MM');
            this.boardType = 'allEmp';
            this.selectedProjectId = null;
        }
        // Reset references
        localStorage.removeItem('leaderboard.date');
        localStorage.removeItem('leaderboard.tab');
        localStorage.removeItem('leaderboard.projectId');
        localStorage.removeItem('leaderboard.action');
        // Load data
        this.handleGetCurrentMonth(this.monthData);
    },
    methods: {
        handleGetCurrentMonth(monthData) {
            this.monthData = moment(monthData).format('yyyy-MM');
            localStorage.setItem('leaderboard.date', this.monthData);
            this.handleLeaderboardChange(this.boardType);
        },
        handleLeaderboardChange(boardType) {
            localStorage.setItem('leaderboard.tab', boardType);
            this.boardType = boardType;
            this.leaderboardData = [];

            // Something changed the monthData, convert date to string
            if (typeof this.monthData === 'object') {
                this.monthData = moment(this.monthData).format('yyyy-MM');
            }

            if (boardType === 'allEmp') {
                this.handleGetLeaderboard();
            } else if (boardType === 'byProj') {
                this.handleGetProjects();
            } else if (boardType === 'allProj') {
                this.handleGetLeaderboardAllProjects();
            }
        },
        async handleGetLeaderboard() {
            this.isLoading = true;
            this.leaderboardData = [];
            const { data } = await getLeaderboard(this.monthData);
            // Guard the update, this should not update the data after the user changed a tab while this request is not yet finished
            if (this.boardType === 'allEmp') {
                this.leaderboardData = data;
                this.isLoading = false;
            }
        },
        async handleGetProjects() {
            this.isLoading = true;
            this.projectsList = [];
            let allData = [];
            allData =
                this.mainRole === 'client'
                    ? await getProjectByClient(this.user_id).then((res) => {
                          return res.data;
                      })
                    : await getProjects().then((res) => {
                          return res.data;
                      });
            if (allData) {
                const parsedAllData = allData.reduce((acc, obj) => {
                    if (this.mainRole === 'client') {
                        if (obj.project.isActive && obj.project.isArchived === false) {
                            acc.push({
                                value: obj.project._id,
                                label: obj.project.name,
                            });
                        }
                    } else {
                        if (obj.isActive && obj.isArchived === false) {
                            acc.push({
                                value: obj._id,
                                label: obj.name,
                            });
                        }
                    }

                    return acc;
                }, []);
                this.projectsList = await Promise.all(parsedAllData);
                this.projectsList = this.projectsList.sort((a, b) => (a.label > b.label ? 1 : -1));
            }

            if (this.selectedProjectId) {
                this.handleGetLeaderboardByProject(this.selectedProjectId);
            }
            this.isLoading = false;
        },
        async handleGetLeaderboardByProject(id) {
            localStorage.setItem('leaderboard.projectId', id);
            this.isLoading = true;
            this.leaderboardData = [];
            const { data } = await getLeaderboardByProject(this.monthData, id);
            // Guard the update, this should not update the data after the user changed a tab while this request is not yet finished
            if (this.boardType === 'byProj') {
                this.leaderboardData = data;
                this.isLoading = false;
            }
        },
        async handleGetLeaderboardAllProjects() {
            this.isLoading = true;
            this.leaderboardData = [];
            const { data } = await getLeaderboardAllProject(this.monthData);
            // Guard the update, this should not update the data after the user changed a tab while this request is not yet finished
            if (this.boardType === 'allProj') {
                this.leaderboardData = data;
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.reward-page-card {
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    border-radius: 20px;

    .leaderboard-filter {
        .btn-config {
            border-color: #463c6e;
            color: #463c6e;
        }
        .active-board {
            background-color: #463c6e;
            color: #fff;
        }
    }

    .project-dropdown-wrapper {
        padding: 0 25px;

        .dropdown-title {
            font-weight: 600;
            color: #463c6e;
        }
    }

    .ivu-card-body {
        padding-top: 0 !important;

        .card-title {
            width: 300px;
            height: 60px;
            line-height: 60px;
            padding: 0px 5px;
            background-color: #463c6e;
            border-right: 0;
            position: relative;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #fff;
        }
        .card-title:before {
            content: '';
            display: block;
            border-style: solid;
            border-color: #463c6e transparent transparent transparent;
            border-width: 60px 0px 0px 35px;
            width: 0;
            height: 0;
            position: absolute;
            top: 0px;
            left: -35px;
        }
        .card-title:after {
            content: '';
            display: block;
            border-style: solid;
            border-color: #463c6e transparent transparent transparent;
            border-width: 60px 35px 0 0;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: -35px;
        }
        .leaderboard-filter {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .reward-page-card {
        .ivu-card-body {
            .card-title {
                width: 200px;
                height: 60px;
            }
            .leaderboard-filter button {
                width: 100%;
                margin-bottom: 5px;
                margin-right: 0 !important;
            }
        }
    }
}
</style>
