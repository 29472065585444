<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Edit Time Record"
            width="1100"
            no-footer
            class="editTimelog"
            :before-close="handleReset"
        >
            <Form ref="form" :model="formData" :rules="ruleInline" label-position="left" inline>
                <Row justify="center" align="middle">
                    <Col span="15">
                        <div v-if="formData && formData.length">
                            <div v-for="(item, index) in formData" :key="index">
                                <Card
                                    :padding="16"
                                    :bordered="false"
                                    style="border: 1px solid #515a6e; margin-bottom: 10px"
                                >
                                    <Row>
                                        <Col span="8">
                                            <FormItem label="Start Time" :prop="index.startTime">
                                                <DatePicker
                                                    v-model="item.startTime"
                                                    type="datetime"
                                                    placeholder="Select date"
                                                    format="dd-MMM-yyyy hh:mm:ss A"
                                                    transfer
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span="8">
                                            <FormItem label="End Time" :prop="index.endTime">
                                                <DatePicker
                                                    v-model="item.endTime"
                                                    type="datetime"
                                                    placeholder="Select date"
                                                    format="dd-MMM-yyyy hh:mm:ss A"
                                                    transfer
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span="8">
                                            <FormItem label="Elapsed Time in Hours">
                                                <Input v-model="item.elapsedTimeInHrs" readonly />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <div class="text-center">
                                        <Button
                                            class="pt-btn-3"
                                            :loading="isLoading && indexClicked === index"
                                            :disabled="isLoading && indexClicked === index"
                                            @click="handleSubmitForm(index)"
                                        >
                                            MODIFY
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <p v-else class="text-center" style="width: 1100px">No Data</p>
                    </Col>
                    <Col span="8" class="otherFields">
                        <div v-for="(item, index) in formData" :key="index">
                            <div v-if="index === 0">
                                <h3 class="text-center" style="font-size: 18px !important">
                                    Aggregated Timelogs Calculation
                                </h3>
                                <Card
                                    :padding="16"
                                    :bordered="false"
                                    style="border: 1px solid #515a6e; margin-bottom: 10px"
                                >
                                    <Row>
                                        <Col span="8">
                                            <FormItem label="Daily 8">
                                                <Input v-model="item.daily8" readonly />
                                            </FormItem>
                                        </Col>
                                        <Col span="8">
                                            <FormItem label="Row Daily">
                                                <Input v-model="item.rowDaily" readonly />
                                            </FormItem>
                                        </Col>
                                        <Col span="8">
                                            <FormItem label="Row Extra">
                                                <Input v-model="item.rowExtra" readonly />
                                            </FormItem>
                                        </Col>
                                        <Col span="8" class="text-center" style="margin: auto">
                                            <FormItem label="Appr. Extra">
                                                <Input v-model="item.approvedExtra" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <div class="text-center">
                                        <Button
                                            class="pt-btn-3"
                                            :loading="isLoading && indexClicked === index"
                                            :disabled="isLoading && indexClicked === index"
                                            @click="handleSubmitForm(index)"
                                        >
                                            MODIFY
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import { updateTimelogsById } from '@/services/timelogs.services';
export default {
    data() {
        return {
            indexClicked: undefined,
            isLoading: false,
            timelogData: null,
            startTime: '',
            endTime: '',
            formData: {
                startTime: '',
                endTime: '',
            },
            ruleInline: {
                startTime: [
                    {
                        required: true,
                        type: 'date',
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                endTime: [
                    {
                        required: true,
                        type: 'date',
                        message: 'Please fill in end time.',
                        trigger: 'change',
                    },
                ],
            },
            showModal: false,
        };
    },
    methods: {
        async setFormDataValues() {
            this.isLoading = true;
            if (this.timelogData) {
                this.formData = this.timelogData.map((item) => {
                    let elapsedTimeInHrs = item.elapsedTimeInHrs
                        ? item.elapsedTimeInHrs.toFixed(2)
                        : item.elapsedTimeInHrs;
                    let isoDate = item.startTime;
                    let ieoDate = item.endTime;
                    let newSDate = moment.utc(isoDate).format('DD-MMM-YYYY hh:mm:ss A');
                    let newEDate = moment.utc(ieoDate).format('DD-MMM-YYYY hh:mm:ss A');
                    return {
                        ...item,
                        elapsedTimeInHrs: elapsedTimeInHrs,
                        startTime: newSDate,
                        endTime: newEDate,
                    };
                });
                this.isLoading = false;
            }
        },

        async handleSubmitForm(index) {
            this.isLoading = true;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitForm(index);
                } else {
                    this.$Message.error('Fill in the required fields');
                    this.isLoading = false;
                }
            });
        },

        async submitForm(index) {
            this.isLoading = true;
            this.indexClicked = index;
            this.frmData = this.formData[index];
            this.frmData = {
                id: this.frmData._id,
                startTime: moment(this.frmData.startTime).utc().format(),
                endTime: moment(this.frmData.endTime).utc().format(),
                approvedExtra: this.frmData.approvedExtra ? this.frmData.approvedExtra : 0,
            };
            await updateTimelogsById(this.frmData.id, this.frmData)
                .then(() => {
                    this.$Message.success('Timelog successfully updated');
                    this.isLoading = false;
                    this.$emit('reload');
                    this.showModal = false;
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Start time and end time values should not be a future time'
                    ) {
                        this.$Message.error('Start time and end time values should not be a future time');
                    } else if (err.data.statusCode === 400 && err.data.message === 'Overlapping timelogs') {
                        this.$Message.error('Failed to update timelog due to overlapping of timelog');
                    } else {
                        this.$Message.error('Failed to update timelog');
                    }
                    this.isLoading = false;
                });
        },

        openModal(data) {
            this.timelogData = data.details.filter((i) => i.endTime != null);
            this.setFormDataValues();
            this.showModal = true;
        },

        handleCancel() {
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.formData = [];
            this.showModal = false;
        },
    },
};
</script>
<style>
.editTimelog form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
}
.editTimelog .ivu-modal-body {
    padding: 16px;
}
.editTimelog .ivu-modal-footer {
    display: none;
}
.editTimelog .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.otherFields.ivu-col.ivu-col-span-8 {
    margin: auto;
}
.editTimelog .ivu-form-inline .ivu-form-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 24px;
    margin-top: 0px;
    vertical-align: top;
    margin-left: unset;
}
.editTimelog .ivu-form-item {
    margin-bottom: 24px;
    vertical-align: top;
    zoom: 1;
}
.editTimelog .ivu-form-item-content {
    display: block;
}
.editTimelog .ivu-form-item-content {
    margin-left: unset;
}
.editTimelog .ivu-form .ivu-form-item-label {
    padding: 10px 0px 10px 0;
}
</style>
