import { axiosInstance, getAuthorizationHeaders } from '@/services/base';

const roleUrlString = `notifications`;

export function getPaginatedNotificationList(filter, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    const urlString = `/list`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { filter: filter, limit: limit, page: page },
        headers,
    });
}

export function postReadAllNotifications() {
    const urlString = `/readAll`;
    return axiosInstance.post(roleUrlString + urlString, {}, getAuthorizationHeaders());
}

export function getReadUnreadCount() {
    const urlString = `/count`;
    return axiosInstance.get(roleUrlString + urlString, getAuthorizationHeaders());
}
