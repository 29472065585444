<template>
    <div class="planEmail">
        <Row type="flex" justify="space-between" class="code-row-bg" style="margin-bottom: 20px">
            <Col span="8">
                <Card class="page-title no-padding" dis-hover :bordered="false">
                    SEND END OF THE WEEK EMAIL <span style="text-transform: uppercase"></span
                ></Card>
            </Col>
            <Col span="4" class="text-right">
                <Button class="pt-btn-5" style="margin-right: 10px; padding: 0 30px">
                    <strong>Go Back</strong>
                </Button>
            </Col>
        </Row>
        <Card>
            <div class="sendBtn">
                <Button class="pt-btn-1" style="width: 100px" :loading="isSending"> Send </Button>
            </div>
            <TinyMceEditor />
        </Card>
        <Row type="flex" class="taskEowList">
            <Col span="12">
                <Card
                    ><h3 class="headTitle">TASKS YOU'VE FINISHED</h3>
                    <Table
                        :columns="tblTaksColumns"
                        :data="tblData"
                        height="200"
                        size="small"
                        :loading="isLoading"
                        class="todoTasks"
                /></Card>
            </Col>
            <Col span="12">
                <Card><h3 class="headTitle">YOUR ACTIVITY FOR THIS WEEK</h3> </Card>
            </Col>
        </Row>
        <h2 class="headerLogs">HOW PRODUCTIVE YOU WERE THIS WEEK</h2>
        <Row type="flex" class="taskEowList">
            <Col span="12">
                <Card
                    ><h3 class="headTitle">CHATTY LOG</h3>
                    <Table
                        :columns="tblLogsColumns"
                        :data="tblData"
                        height="200"
                        size="small"
                        :loading="isLoading"
                        class="chattyLog"
                /></Card>
            </Col>
            <Col span="12">
                <Card
                    ><h3 class="headTitle">GEEK LOG</h3>
                    <Table
                        :columns="tblLogsColumns"
                        :data="tblData"
                        height="200"
                        size="small"
                        :loading="isLoading"
                        class="geekLog"
                /></Card>
            </Col>
        </Row>
    </div>
</template>

<script>
import TinyMceEditor from '@/components/UI/TinyMceEditor';

export default {
    components: {
        TinyMceEditor,
    },
    data() {
        return {
            tblTaksColumns: [
                // {
                //     type: 'selection',
                //     width: 60,
                //     align: 'center',
                // },
                {
                    title: 'Task Title',
                    key: 'name',
                },
                // {
                //     title: 'Due Date',
                //     key: 'due',
                //     width: 300,
                // },
                {
                    title: 'Hours #',
                    key: 'hours',
                    // width: 90,
                },
            ],
            tblLogsColumns: [
                // {
                //     type: 'selection',
                //     width: 60,
                //     align: 'center',
                // },
                {
                    title: 'Message',
                    key: 'message',
                },
                {
                    title: 'Timestamp',
                    key: 'timestamp',
                    // width: 90,
                },
            ],
            tblData: [],
        };
    },
};
</script>
<style lang="scss">
.sendBtn {
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
}
.planEmail .ivu-card.ivu-card-bordered {
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    border-color: #eee;
    border-radius: 20px;
}
.taskEowList {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}
.ivu-col-span-12 {
    max-width: 49%;
}
.headTitle {
    color: #463c6e;
}
.todoTasks .ivu-table th {
    background-color: unset;
    color: #463c6e;
}
.chattyLog .ivu-table th {
    background-color: unset;
    color: #463c6e;
}
.geekLog .ivu-table th {
    background-color: unset;
    color: #463c6e;
}
.headerLogs {
    color: #463c6e;
    margin: 20px 0;
}
</style>
