<template>
    <div class="timetracker-filter">
        <Card dis-hover>
            <Row :gutter="20">
                <Col :xs="20" :sm="16" :md="12" :lg="10" class="d-flex-center">
                    <span style="margin-right: 20px">Project</span>
                    <Select
                        v-model="selectedProject"
                        filterable
                        clearable
                        :value="$route.query.projectId"
                        @on-change="handleOnProjectChanged"
                    >
                        <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                            {{ item.label }}
                        </Option>
                    </Select>
                </Col>
                <Col :xs="2" :sm="4" :md="6" :lg="10" class="d-flex-center">
                    <span style="margin-right: 20px">Member</span>
                    <Select v-model="selectedMember" :value="$route.query.user" filterable clearable>
                        <Option v-for="item in projectMembersList" :key="item.value" :value="item.value">
                            {{ item.label }}
                        </Option>
                    </Select>
                </Col>
                <Col :xs="2" :sm="4" :md="6" :lg="10" class="d-flex-center">
                    <span style="margin-right: 20px">Date</span>
                    <DatePicker v-model="dates" type="daterange" placeholder="Select start date" />
                </Col>
                <Col :xs="2" :sm="4" :md="6" :lg="2" class="filter-btn">
                    <Button class="pt-btn-1" @click="handleOnFilter"> Filter </Button>
                </Col>
            </Row>
        </Card>
    </div>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
export default {
    props: {
        projectMembersList: {
            type: Array,
            default: () => [],
        },
        projectsList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedMember: this.$route.query.user,
            selectedProject: this.$route.query.projectId,
            dates: [null, null],
        };
    },
    created() {
        if (this.$route.query.user) {
            this.$emit('filter', this.selectedMember, this.selectedProject);
        }
    },
    methods: {
        handleOnFilter() {
            const calculateDays = moment(this.dates[1]).diff(this.dates[0], 'days');
            if (!this.selectedMember && !this.selectedProject) {
                this.$Message.error('Select member or project');
                return;
            }
            if (this.dates[0] == null) {
                this.$Message.error('Provide Date');
            } else if (calculateDays > 31) {
                this.$Message.error('Days should be less than 31 days');
            } else {
                this.$emit('filter', this.selectedMember, this.selectedProject, {
                    from: moment(`${moment(this.dates[0]).format('yyyy-MM-DD')}T00:00:00.000Z`)
                        .utc()
                        .format(),
                    to: moment(`${moment(this.dates[1]).format('yyyy-MM-DD')}T23:59:59.000Z`)
                        .utc()
                        .format(),
                });
            }
        },

        handleOnProjectChanged(proj) {
            this.selectedMember = null;
            this.$emit('project-changed', proj);
            try {
                this.$router.replace({ query: null }).catch(() => {});
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
    },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
    .timetracker-filter {
        .ivu-row {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .ivu-col-span-xs-2 {
                margin: 5px 0;
            }
            .filter-btn button {
                width: 100%;
            }
        }
    }
}
</style>
