<template>
    <Modal
        v-model="showModal"
        draggable
        sticky
        title=" "
        footer-hide
        :before-close="handleClose"
        width="900"
        class-name="email-details-modal-wrapper remove-padding"
    >
        <div slot="header" style="color: #fff; text-align: left"></div>
        <div class="img-header"><img src="@/assets/STU Logo.png" /></div>

        <div class="emailBody">
            <!-- eslint-disable vue/no-v-html -->
            <div class="email" v-html="emailDetails.emailBody" />
            <!--eslint-enable-->
            <hr />
            <h2 class="sectionTitle">TASKS COMPLETED</h2>
            <h2 class="sectionTitle">COMMUNICATION LOGS</h2>
            <h2 class="sectionTitle">DEVELOPER LOGS</h2>
            <h2 class="sectionTitle">ACTIVITY FOR THIS WEEK</h2>
            <h2 class="sectionTitle">DEVELOPER STATS</h2>
            <hr />
            <div class="footerHeader">
                <h2 class="footerTitle">THANKS FOR WORKING WITH US!</h2>
                <a href="https://projects.startechup.dev/login" target="_blank"
                    ><Button class="email-btn">Access Project Tracker</Button></a
                >
                <div style="display: flex; justify-content: center; margin: auto">
                    <div style="text-align: center; background-color: #463c6e">
                        <a href="https://www.facebook.com/startechup" target="_blank" style="margin: 0px 0px 0 5px"
                            ><img
                                src="https://www.startechup.com/wp-content/uploads/fb.png"
                                style="margin-top: 5px; margin-bottom: 2px; height: 25px" /></a
                        ><a href="https://wa.me/message/D5J327ZFZKQ3B1" target="_blank" style="margin: 0px 0px 0 5px"
                            ><img
                                src="https://www.startechup.com/wp-content/uploads/whatsapp.png"
                                style="margin-top: 5px; margin-bottom: 2px; height: 25px" /></a
                        ><a href="https://twitter.com/StarTechUp" target="_blank" style="margin: 0px 0px 0 5px"
                            ><img
                                src="https://www.startechup.com/wp-content/uploads/twitter.png"
                                style="margin-top: 5px; margin-bottom: 2px; height: 25px" /></a
                        ><a href="https://www.instagram.com/startech_up/" target="_blank" style="margin: 0px 0px 0 5px"
                            ><img
                                src="https://www.startechup.com/wp-content/uploads/insta.png"
                                style="margin-top: 5px; margin-bottom: 2px; height: 25px" /></a
                        ><a
                            href="https://www.linkedin.com/company/startechup/"
                            target="_blank"
                            style="margin: 0px 0px 0 5px"
                            ><img
                                src="https://www.startechup.com/wp-content/uploads/linkedin.png"
                                style="margin-top: 5px; margin-bottom: 2px; height: 25px" /></a
                        ><a href="https://www.youtube.com/c/Startechup" target="_blank" style="margin: 0px 5px 0 5px"
                            ><img
                                src="https://www.startechup.com/wp-content/uploads/youtube.png"
                                style="margin-top: 5px; margin-bottom: 2px; height: 25px"
                        /></a>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            showModal: false,
            emailDetails: [],
            dateFormatted: null,
        };
    },
    methods: {
        openModal(data) {
            this.showModal = true;
            this.emailDetails = data;
            this.dateFormatted = moment(this.emailDetails.dateSent).format('DD-MMM-YYYY HH:mm:ss A');
        },
        handleClose() {
            this.showModal = false;
        },
    },
};
</script>

<style>
.remove-padding .ivu-modal-body {
    padding: 16px !important;
}
.marginBtm {
    margin-bottom: 15px;
}
.emailBody {
    height: 650px;
    overflow-y: auto;
}
div.emailBody div.email div {
    max-width: 100% !important;
    margin: 0 15px 20px 15px !important;
}
.img-header,
.sectionTitle,
hr {
    margin: 30px;
    color: #463c6e;
}
.email-details-modal-wrapper a.ivu-modal-close {
    display: none;
}
.footerHeader {
    text-align: center;
}
.footerTitle {
    margin: 15px;
    color: #463c6e;
}
button.email-btn.ivu-btn.ivu-btn-default {
    border-radius: 20px;
    background-color: #e9495d;
    color: #fff;
    margin-bottom: 15px;
}
</style>
