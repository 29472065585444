<template>
    <div>
        <Button
            v-if="!walletInfo.id"
            class="pt-btn-4"
            :loading="isConnectingToWallet"
            :disabled="isLoading"
            @click="handleConnectWallet"
        >
            Link To Phantom Wallet
        </Button>

        <div v-else>
            <Button class="pt-btn-1" @click="handleDisconnectWallet"> Disconnect Wallet </Button>
        </div>
    </div>
</template>

<script>
import {
    signatureRequestToMoralis,
    signatureVerificationToMoralis,
    verifyUserConnectedWallet,
} from '@/services/nft.services';

export default {
    components: {},
    data() {
        return {
            isLoading: false,
            isConnectingToWallet: false,
            walletInfo: {},
            tokenName: 'wallet_session_token',
        };
    },
    async mounted() {
        this.isLoading = true;
        const { data } = await verifyUserConnectedWallet({ token: localStorage.getItem(this.tokenName) });

        this.isAunthenticated = data.success;
        if (this.isAunthenticated) {
            this.walletInfo = data;
        }

        this.isLoading = false;
    },
    methods: {
        async handleConnectWallet() {
            // for now we only integrate to Phantom Wallet
            if (!window.solana.isPhantom) {
                const message = 'Phantom wallet connection is required for authentication!';
                this.$Message.error(message);
                throw new Error(message);
            }

            const walletProvider = window.phantom.solana;
            const walletConnection = await walletProvider.connect();
            this.isConnectingToWallet = true;
            const address = walletConnection.publicKey.toString();

            const {
                data: { message },
            } = await signatureRequestToMoralis({ address });

            const encodedMessage = new TextEncoder().encode(message);
            const { signature } = await walletProvider.signMessage(encodedMessage, 'utf8');

            const { data } = await signatureVerificationToMoralis({
                signature,
                message,
            });

            this.walletInfo = data;
            this.$emit('handleSetWalletAddress', data);
            localStorage.setItem(this.tokenName, data.token);
            this.isConnectingToWallet = false;
        },
        handleDisconnectWallet() {
            this.walletInfo = {};
            localStorage.removeItem(this.tokenName);
            this.$emit('handleSetWalletAddress', { address: null, disconnect: true });
        },
    },
};
</script>
<style lang="scss"></style>
