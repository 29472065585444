<template>
    <div>
        <Modal
            v-model="showModal"
            title="Bibucket Repositories"
            footer-hide
            class="text-center bitbucket-modal-list"
            width="700"
        >
            <div class="md-margin">
                <Input
                    v-model="seachedText"
                    placeholder="Search repositories..."
                    clearable
                    @on-keyup="handleSearchOnChange"
                    @on-clear="handleSearchOnChange"
                />
                <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" />
            </div>
        </Modal>
    </div>
</template>

<script>
import { getBitbucketRepositories } from '@/services/bitbuckets.services';
import Bugsnag from '@bugsnag/js';

export default {
    props: {
        selected: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isLoading: false,
            seachedText: '',
            showModal: false,
            selectedId: null,
            selectedRepos: this.selected,
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                },
                {
                    title: 'Slug',
                    key: 'slug',
                    render: (h, params) => {
                        return h(
                            'span',
                            {
                                style: {
                                    color: '#bfbfbf',
                                },
                            },
                            params.row.slug
                        );
                    },
                },
                {
                    title: 'Actions',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h(
                            'Button',
                            {
                                props: {
                                    size: 'small',
                                },
                                class: 'pt-btn-2',
                                on: {
                                    click: () => {
                                        this.$emit('newSelected', params.row);
                                        this.tblData = this.tblData
                                            .filter((data) => data.slug !== params.row.slug)
                                            .map((i) => {
                                                return {
                                                    ...i,
                                                };
                                            });
                                    },
                                },
                            },
                            'select'
                        );
                    },
                },
            ],
            tblData: [],
        };
    },
    methods: {
        async openModal() {
            this.seachedText = '';
            this.showModal = true;
            this.tblData = await this.handleGetBitbucketRepos();
        },
        async handleGetBitbucketRepos(query = null) {
            this.isLoading = true;
            this.tblData = [];
            let results = [];
            results = await this.handleGetBitbucketRepositories(query);

            const allData = results.reduce((acc, obj) => {
                if (!this.selected.find((el) => el.id === obj.uuid)) {
                    acc.push({ name: obj.name, id: obj.uuid, slug: obj.slug });
                }

                return acc;
            }, []);
            this.isLoading = false;
            return await Promise.all(allData);
        },

        handleGetBitbucketRepositories(query) {
            return getBitbucketRepositories(query)
                .then((res) => {
                    if (res.data.values.length) {
                        return res.data.values;
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    this.$Message.error('Failed to get bitbucket repositories');
                    try {
                        if (err.data.statusCode === 401) {
                            localStorage.clear();
                            window.location.href = '/login';
                        }
                    } catch (e) {
                        Bugsnag.notify(e);
                    }
                    return [];
                });
        },

        handleCancel() {
            this.selectedId = null;
            this.$emit('cancel');
            this.showModal = false;
        },
        handleConfirm() {
            this.$emit('confirm', this.selectedId);
            this.showModal = false;
        },

        handleSelectionChange(data) {
            const allData = [...this.selected, ...data];
            const uniqueData = [...new Map(allData.map((item) => [item.id, item])).values()];
            this.$emit('newSelected', uniqueData);
        },

        async handleSearchOnChange(evt) {
            // if(evt && evt.target && evt.target._value) {
            //   if(evt.key === 'Backspace'){
            //     this.tblData =  await this.handleGetBitbucketRepos()
            //   }
            //   this.tblData = this.tblData.filter(data => {
            //     const full_name = data.name.toLowerCase()
            //     return full_name.includes(evt.target._value.toLowerCase())
            //     })
            // } else {
            //   this.tblData =  await this.handleGetBitbucketRepos()
            // }

            if (evt && evt.target && evt.target._value) {
                const query = `q=name~"${evt.target._value}"`;
                this.tblData = await this.handleGetBitbucketRepos(query);
            } else {
                this.tblData = await this.handleGetBitbucketRepos();
            }
        },
    },
};
</script>

<style lang="scss">
.base-modal-confirmation {
    .ivu-modal-content {
        overflow: hidden;
        .ivu-modal-header {
            background: $primary-color;
            .ivu-modal-header-inner {
                color: #ffffff;
            }
        }
        .ivu-modal-body {
            padding: 40px;
        }
    }
}
</style>
