<template>
    <div class="logs-table">
        <Card class="logs-table-card"
            ><h3>{{ title }}</h3>
            <Table
                :columns="tblColumns"
                :data="weeklyplandata"
                :loading="loading"
                size="small"
                class="todoTasks"
                height="370"
            />

            <Page
                v-if="count"
                :total="count"
                :current="currentPage"
                class="pagination"
                @on-change="handleOnPageChanged"
            />
        </Card>
    </div>
</template>

<script>
//import moment from 'moment';
export default {
    props: {
        weeklyplandata: {
            type: Array,
            default: () => [],
        },
        tblColumns: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: null,
        },
        loading: {
            type: Boolean,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            currentPage: 1,
            isLoading: false,
        };
    },
    methods: {
        async handleOnPageChanged(page) {
            this.isLoading = true;
            this.currentPage = page;
            this.$emit('on-page-change', this.currentPage);
        },
    },
};
</script>
<style lang="scss">
.logs-table {
    p,
    span {
        color: #463c6e;
    }
    .logs-table-card {
        box-shadow: 0 2px 2px rgb(0 0 0 / 10%) !important;
        border-color: #eee;
        border-radius: 20px;
        margin-bottom: 20px;
        padding: 10px 0;
        min-height: 480px;

        .ivu-table {
            color: #463c6e;

            .ivu-table-header thead tr th {
                background-color: unset;
                color: #463c6e;
            }

            table {
                border-collapse: collapse;

                tbody tr {
                    border-bottom: solid 1px rgba(70, 60, 110, 10%);
                }
            }
        }

        .pagination {
            li {
                border: none;
            }
            .ivu-page-next,
            .ivu-page-prev {
                line-height: 26px;

                a {
                    color: #463c6e;
                    font-size: 18px;
                }
            }
            .ivu-page-item-active {
                background: #463c6e;
                border-radius: 25px;

                a {
                    color: #fff !important;
                }
            }
            .ivu-page-item {
                font-weight: 600;
                font-size: 12px;
                height: 22px;
                min-width: 22px;
                line-height: 22px;

                a {
                    color: #463c6e;
                }
            }
            .ivu-page-disabled {
                a {
                    color: #ccc;
                }
            }
        }
    }
    h3 {
        color: #463c6e;
        padding: 0 18px;
        font-size: 20px;
    }
    .pagination {
        margin-top: 20px;
    }
}
</style>
