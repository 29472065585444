<template>
    <Card dis-hover class="custom-info-card">
        {{ message }}
        <div class="icon-wrap">
            <Icon type="md-checkmark-circle" size="30" :color="status ? '#44E53E' : '#E9495D'" />
        </div>
        <slot name="content" />
    </Card>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: '',
        },
        status: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style></style>
