<template>
    <div class="fullheight">
        <div class="fullheight">
            <AppAutoLogout ref="AppAutoLogout" />
            <AppHeader :rotate-icon="rotateIcon" @open="handleOpenSider" />
            <Row :wrap="false" class="app-content-wrapper">
                <Col flex="none" style="border-right: 1px solid #463c6e21">
                    <AppSider
                        ref="AppSider"
                        :is-collapsed="isCollapsed"
                        :menuitem-classes="menuitemClasses"
                        @collapsed="handleSiderCollapsed"
                    />
                </Col>
                <Col flex="auto" style="overflow: auto; overflow-x: hidden">
                    <Content style="margin: 50px">
                        <router-view></router-view>
                    </Content>
                </Col>
            </Row>
        </div>
    </div>
</template>

<script>
import AppSider from '@/components/Layouts/AppSider';
import AppHeader from '@/components/Layouts/AppHeader';
import AppAutoLogout from '@/components/Layouts/AppAutoLogout';
export default {
    components: {
        AppSider,
        AppHeader,
        AppAutoLogout,
    },
    data() {
        return {
            isCollapsed: false,
        };
    },
    computed: {
        rotateIcon() {
            return ['menu-icon', this.isCollapsed ? 'rotate-icon' : ''];
        },
        menuitemClasses() {
            return ['menu-item', this.isCollapsed ? 'collapsed-menu' : ''];
        },
    },
    methods: {
        handleOpenSider() {
            this.$refs['AppSider'].toggleCollapse();
        },
        handleSiderCollapsed(val) {
            this.isCollapsed = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.app-content-wrapper {
    height: calc(100% - 64px);
}
.layout-header-bar {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-logo-left {
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
}
</style>
