<template>
    <div class="project-client-section">
        <!-- <Card class="section-title"> CLIENT<Icon class="add-icon" type="md-add" @click="handleModal" /> </Card> -->
        <Row>
            <Col span="12">
                <h2>CLIENTS</h2>
            </Col>
            <Col span="12" style="justify-content: end; display: flex">
                <Button class="pt-btn-1" @click="handleModal">Add</Button>
            </Col>
        </Row>
        <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="md-margin-top" />
        <AddProjectClient ref="AddProjectClient" @addPT="handleGetProjectMembersByProjectId" />
    </div>
</template>

<script>
import {
    getProjectMembersByProjectId,
    updateProjectMember,
    deleteProjectMember,
} from '@/services/project-members.services';
import AddProjectClient from './AddProjectClient';

export default {
    components: {
        AddProjectClient,
    },

    data() {
        return {
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    render: (h, params) => {
                        return h('span', params.row.user.name);
                    },
                },
                {
                    title: 'Actions',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('i-switch', {
                                props: {
                                    value: params.row.isProjectMemberActive,
                                    size: 'small',
                                    trueValue: false,
                                    falseValue: true,
                                    trueColor: '#CFCFCF',
                                    falseColor: '#44E53E',
                                },
                                on: {
                                    'on-change': (val) => {
                                        this.handleUpdateProjectMember(params.row._id, val);
                                    },
                                },
                            }),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-close-circle',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    on: {
                                        click: () => {
                                            this.handleRemoveProjectMember(params.row._id);
                                        },
                                    },
                                },
                                'edit'
                            ),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    mounted() {
        this.handleGetProjectMembersByProjectId();
    },

    methods: {
        handleModal() {
            this.$refs.AddProjectClient.openModal();
        },

        handleGetProjectMembersByProjectId() {
            this.isLoading = true;
            this.tblData = [];
            getProjectMembersByProjectId(this.$route.query.id)
                .then((res) => {
                    if (res.data.length) {
                        this.tblData = res.data.filter((x) => x.projectRole == 'client' && x.user.isArchived != true);
                    }
                    this.isLoading = false;
                    return [];
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members');
                    this.isLoading = false;
                    return [];
                });
        },

        handleRemoveProjectMember(id) {
            deleteProjectMember(id)
                .then(() => {
                    this.handleGetProjectMembersByProjectId();
                    this.$Message.success('Project member removed successfully');
                })
                .catch(() => {
                    this.$Message.error('Failed to remove a project member');
                });
        },

        handleUpdateProjectMember(id, val) {
            updateProjectMember(id, { isProjectMemberActive: val })
                .then(() => {
                    this.handleGetProjectMembersByProjectId();
                })
                .catch(() => {
                    this.$Message.error('Failed to update a project member');
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.project-client-section {
    color: #463c6e;
}
</style>
