<template>
    <div>
        <div class="d-flex-center lg-margin-bottom">
            <p class="md-margin-right">Project</p>
            <Select
                :value="$route.query.id"
                filterable
                clearable
                :loading="isLoading"
                class="md-margin-right"
                style="max-width: 300px"
                @on-change="handleSelectProjectChange"
            >
                <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                    {{ item.label }}
                </Option>
            </Select>
        </div>
        <div class="questions" style="margin-bottom: 20px">
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Project Rating for the project working with Startechup:</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>How was your experience with Startechup Leadership team?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Did the project reach your expectations in terms of quality and timeframe?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Are you satisfied on the way our Project managers worked with you?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Are you satisfied with our Development team?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
        </div>
        <div class="project-feedback-composer" style="position: relative">
            <Spin v-if="isLoading" size="large" fix />
            <wysiwyg v-if="renderComponent" v-model="projectFeedback" />
            <Button
                slot="extra"
                class="pt-btn-1"
                style="width: 100px; margin-top: 20px"
                :loading="isLoading"
                @click="handleOnSendEmail"
            >
                Submit
            </Button>
        </div>
    </div>
</template>

<script>
import { getProjects, getProjectsById } from '@/services/projects.services';
import { getProjectByClient } from '@/services/project-members.services';

export default {
    data() {
        return {
            user_id: localStorage.getItem('user_id'),
            mainRole: localStorage.getItem('mainRole'),
            renderComponent: true,
            isLoading: false,
            projectsList: [],
            selectedProject: null,
            projectFeedback: '',
        };
    },

    mounted() {
        this.handleGetProjects();
    },

    methods: {
        async handleGetProjects() {
            this.isLoading = true;
            this.projectsList = [];
            let allData = [];
            allData = await this.handleGetAllProjects();

            const parsedAllData = allData.reduce((acc, obj) => {
                if (this.mainRole === 'client') {
                    if (obj.project.isActive && obj.project.isArchived === false) {
                        acc.push({
                            value: obj.project._id,
                            label: obj.project.name,
                        });
                    }
                } else {
                    if (obj.isActive && obj.isArchived === false) {
                        acc.push({
                            value: obj._id,
                            label: obj.name,
                        });
                    }
                }
                return acc;
            }, []);

            this.projectsList = await Promise.all(parsedAllData);
            this.projectsList = this.projectsList.sort((a, b) => (a.label > b.label ? 1 : -1));
            this.selectedProject = this.projectsList[0];
            this.isLoading = false;
        },

        handleGetAllProjects() {
            const projects = this.mainRole === 'client' ? getProjectByClient(this.user_id) : getProjects();
            return projects
                .then((res) => {
                    if (res.data.length) {
                        return res.data;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get all projects');
                });
        },

        handleSelectProjectChange(val) {
            if (val) {
                this.handleGetProjectById(val);
            }
        },

        handleGetProjectById(id) {
            this.selectedProject = null;
            getProjectsById(id)
                .then((res) => {
                    if (res.data) this.selectedProject;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                });
        },

        handleReload() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.projectFeedback = '';
                this.renderComponent = true;
            });
        },

        handleOnSendEmail() {},
    },
};
</script>
