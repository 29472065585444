<template>
    <div />
</template>

<script>
import Bugsnag from '@bugsnag/js';
export default {
    name: 'AutoLogout',
    data: function () {
        return {
            logoutTimer: null,
        };
    },
    mounted() {
        this.setTimers();
    },
    methods: {
        setTimers: function () {
            const token = localStorage.getItem('auth_token');
            if (!token) {
                this.logoutTimer = setTimeout(this.logoutUser, 10 * 60 * 60 * 1000); // 10 hours to force logout after login
            }
        },
        logoutUser: function () {
            try {
                localStorage.clear();
                window.location.href = '/login';
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
    },
};
</script>
