<template>
    <div>
        <Modal v-model="showModal" title="Add Project Client" no-footer footer-hide>
            <div>
                <div class="savePTBtn">
                    <Button class="btn-color" :loading="isSaving" @click="handleConfirm"> Save </Button>
                </div>
                <Select v-model="addedUser" transfer clearable filterable @on-select="handleOnUserSelect">
                    <Option v-for="item in userList" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </Option>
                </Select>
                <Table
                    :columns="tblColumns"
                    :data="tblData"
                    size="small"
                    :loading="isLoading"
                    height="300"
                    class="md-margin-top"
                />
            </div>
        </Modal>
    </div>
</template>
<script>
import { getUsers } from '@/services/users.services';
import { getProjectMembersByProjectId, addBulkProjectMembers } from '@/services/project-members.services';
export default {
    data() {
        return {
            isLoading: false,
            isSaving: false,
            showModal: false,
            userList: [],
            addedUser: null,
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    render: (h, params) => {
                        return h('span', params.row.user.name);
                    },
                },
            ],
            tblData: [],
        };
    },
    methods: {
        handleGetUsers() {
            this.isLoading = true;
            this.userList = [];
            getUsers()
                .then((res) => {
                    if (res.data.length) {
                        let resData = res.data.filter((x) => x.mainRole == 'client' && x.isArchived == false);

                        const exisitingUsers = this.tblData.map((x) => x.user._id);
                        const result = resData.filter((x) => exisitingUsers.indexOf(x._id) == -1);
                        this.userList = result.map((i) => {
                            return {
                                value: i._id,
                                label: i.name,
                                name: i.name,
                                id: i._id,
                                _id: i._id,
                            };
                        });
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                    this.isLoading = false;
                });
        },

        async handleGetProjectMembers() {
            this.isLoading = true;
            this.tblData = [];
            return await getProjectMembersByProjectId(this.$route.query.id)
                .then((res) => {
                    if (res.data.length) {
                        const resData = res.data.filter((x) => {
                            if (x.projectRole === 'client' && x.user.isArchived === false) {
                                return {
                                    project: x.project._id,
                                    projectRole: x.projectRole,
                                    user: x.user,
                                };
                            }
                        });
                        this.isLoading = false;
                        return resData;
                    } else {
                        this.isLoading = false;
                        return [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members');
                    this.isLoading = false;
                    return [];
                });
        },

        async openModal() {
            this.tblData = await this.handleGetProjectMembers();
            await this.handleGetUsers();
            this.showModal = true;
        },

        handleConfirm() {
            this.handleAddProjectMember();
        },

        async handleAddProjectMember() {
            this.isSaving = true;
            const data = this.tblData.map((x) => {
                return {
                    project: this.$route.query.id,
                    projectRole: x.projectRole,
                    user: x.user._id,
                };
            });
            addBulkProjectMembers(data)
                .then(() => {
                    this.$emit('addPT');
                    this.isSaving = false;
                    this.showModal = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to add project members');
                    this.isSaving = false;
                });
        },

        handleOnUserSelect(val) {
            const users = [...this.userList];
            this.userList = [];
            const selectedUser = users.find((u) => u.id === val.value);
            this.userList = users.filter((u) => u.id !== val.value);
            this.tblData.push({ user: selectedUser, projectRole: 'client' });
            this.addedUser = null;
        },
    },
};
</script>
<style scoped>
.savePTBtn {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}
</style>
