<template>
    <div>
        <div class="lg-margin-bottom task-radar-wrapper">
            <Row type="flex" justify="space-between" class="code-row-bg title-wrapper" style="margin-bottom: 30px">
                <div class="team-name-date-wrapper">
                    <Col>
                        <h2 class="md-margin-right team-name">TASK RADAR - {{ userData.name }}</h2>
                    </Col>
                </div>
                <div class="back-btn-wrapper">
                    <Col span="3" class="goBackBtn">
                        <Button style="margin-right: 10px; padding: 0 30px" @click="$router.go(-1)">
                            <strong>Go Back</strong>
                        </Button>
                    </Col>
                </div>
            </Row>
            <div v-if="isLoading">
                <Spin fix></Spin>
            </div>
            <div v-if="!isLoading">
                <Row class="criteria-cards" type="flex" style="margin-bottom: 20px">
                    <Col v-for="criteria in criteriaCardData" :key="criteria.key" span="4"
                        ><Card :class="criteria.class">
                            <!-- <Icon :type="criteria.icon" size="35" /> -->
                            <img :src="criteria.icon" :width="criteria.width" class="custom-icon" />
                            <span class="boldData">{{ parseInt(criteria.value) }}</span>
                            <p>{{ criteria.name }}</p></Card
                        ></Col
                    >
                </Row>

                <div v-if="$route.query.id">
                    <Row class="logs-table-wrapper" type="flex" justify="space-between" style="margin-bottom: 20px">
                        <Col span="12" v-if="taskData.type === 'plan'">
                            <LogsTableList
                                ref="LogsTableList"
                                title="TASK LOG"
                                :count="tblTaskPlanLogsData.totalCount"
                                :weeklyplandata="tblTaskPlanLogsData.data"
                                :loading="tblTaskPlanLogsData.loading"
                                :tblColumns="tblTasksPlanColumns"
                            />
                        </Col>
                        <Col span="12" v-if="taskData.type === 'wrapup'">
                            <LogsTableList
                                ref="LogsTableList"
                                title="FINISHED TASKS LOG"
                                :count="tblTaskPlanLogsData.totalCount"
                                :weeklyplandata="tblTaskPlanLogsData.data"
                                :loading="tblTaskPlanLogsData.loading"
                                :tblColumns="tblTasksWrapupColumns"
                            />
                        </Col>
                        <Col span="12">
                            <Card class="radar-card">
                                <div v-if="isLoading">
                                    <Spin fix></Spin>
                                </div>
                                <TrackerRadarChart
                                    v-if="chartData.length"
                                    :chartdata-sets="chartData"
                                    :chartdata-labels="chartDataLabels"
                                    :width="400"
                                    :height="400"
                                />
                                <div v-if="!chartData.length" class="no-data-wrapper">No Data</div>
                            </Card>
                        </Col>
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="CHATTY LOG"
                                :count="tblChattyLogsData.totalCount"
                                :weeklyplandata="tblChattyLogsData.data"
                                :loading="tblChattyLogsData.loading"
                                :tblColumns="tblChattyColumns"
                            />
                        </Col>
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="GEEKY LOG"
                                :count="tblGeekyLogsData.totalCount"
                                :weeklyplandata="tblGeekyLogsData.data"
                                :loading="tblGeekyLogsData.loading"
                                :tblColumns="tblGeekColumns"
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import TrackerRadarChart from '@/components/Graphs/RadarChart.js';
import { getUsersById } from '@/services/users.services';
import {
    getWeeklyEmailsTaskDetails,
    getWeeklyEmailsChattyLog,
    getWeeklyEmailsGeekyLog,
    getWeeklyEmailsTaskLog,
    getWeeklyEmailsTaskRadar,
} from '@/services/weekly-emails.services';
import LogsTableList from '@/components/Leaderboard/LogsTableList';
import notepadIcon from '@/assets/images/notepad.svg';
import checklistIcon from '@/assets/images/checklist.svg';
import cloudClockIcon from '@/assets/images/cloud-clock.svg';
import clockCheckIcon from '@/assets/images/clock-check.svg';
import smileIcon from '@/assets/images/smile.svg';
import gitBranchIcon from '@/assets/images/git-branch.svg';
export default {
    components: { LogsTableList, TrackerRadarChart },
    data() {
        return {
            userData: {},
            selectedMonth: null,
            taskData: {},
            isLoading: false,
            taskDay: null,
            criteriaCardData: [
                {
                    key: 'orderlyRate',
                    name: 'ORDERLY RATE',
                    value: null,
                    icon: notepadIcon,
                    class: 'orderlyBg',
                    type: 'plan',
                    width: 22,
                },
                {
                    key: 'getItDoneRate',
                    name: 'DONE RATE',
                    value: null,
                    icon: checklistIcon,
                    class: 'doneBg',
                    type: 'wrapup',
                    width: 25,
                },
                {
                    key: 'completedOrForecastedHours',
                    name: 'FORECASTED HOURS',
                    value: null,
                    icon: cloudClockIcon,
                    class: 'hoursBg',
                    type: 'plan',
                    width: 30,
                },
                {
                    key: 'completedHours',
                    name: 'COMPLETED HOURS',
                    value: null,
                    icon: clockCheckIcon,
                    class: 'hoursBg',
                    type: 'wrapup',
                    width: 25,
                },
                {
                    key: 'chattyRate',
                    name: 'CHATTY RATE',
                    value: null,
                    icon: smileIcon,
                    class: 'chattyBg',
                    type: '',
                    width: 23,
                },
                {
                    key: 'geekyRate',
                    name: 'GEEKY RATE',
                    value: null,
                    icon: gitBranchIcon,
                    class: 'geekyBg',
                    type: '',
                    width: 18,
                },
            ],
            tblChattyLogsData: {},
            tblGeekyLogsData: {},
            tblTaskPlanLogsData: {},
            tblTaskRadarData: {},
            tblTasksPlanColumns: [
                {
                    title: 'Task',
                    key: 'title',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('span', params.row.task.title);
                    },
                },
                {
                    title: 'Due Date',
                    key: 'dueDate',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.task?.dueDate ? moment(params.row.task?.dueDate).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
                {
                    title: '# of Hrs',
                    key: 'hoursCompletion',
                    width: 100,
                    render: (h, params) => {
                        return h('span', params.row.task.hoursCompletion ? params.row.task.hoursCompletion : '-');
                    },
                },
            ],
            tblTasksWrapupColumns: [
                {
                    title: 'Task',
                    key: 'title',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('span', params.row.task.title);
                    },
                },
                {
                    title: 'Done Date',
                    key: 'doneDate',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.task?.doneDate ? moment(params.row.task?.doneDate).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
                {
                    title: '# of Hrs',
                    key: 'hoursCompletion',
                    width: 100,
                    render: (h, params) => {
                        return h('span', params.row.task.hoursCompletion ? params.row.task.hoursCompletion : '-');
                    },
                },
            ],
            tblGeekColumns: [
                {
                    title: 'Commit #',
                    key: 'commitNum',
                    width: 100,
                    render: (h, params) => {
                        return h('span', params.row.commitDetails.short_hash);
                    },
                },
                {
                    title: 'Message',
                    key: 'message',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('span', params.row.commitDetails.message);
                    },
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            tblChattyColumns: [
                {
                    title: 'Message',
                    key: 'message',
                    minWidth: 250,
                    render: (h, params) => {
                        if (params.row.message) {
                            return h('span', params.row.message);
                        } else if (!params.row.message && params.row.messageDetails.files) {
                            return h('span', 'File');
                        } else {
                            return h('span', '-');
                        }
                    },
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            chartData: [],
            chartDataLabels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        };
    },
    async mounted() {
        //this.selectedMonth = moment(this.$route.params.month).format('yyyy-MM');
        this.handleGetData();
    },
    methods: {
        handleGetData() {
            if (this.$route.query.id) {
                this.handleGetTaskRadar();
                this.handleGetUserData();
                this.handleGetEmailTaskDetails();
                this.handleGetTaskLogs();
                this.handleGetChattyLogs();
                this.handleGetGeekyLogs();
            }
        },
        async handleGetUserData() {
            await getUsersById(this.$route.query.id).then((res) => {
                this.userData = res.data;
            });
        },
        async handleGetEmailTaskDetails() {
            this.isLoading = true;
            await getWeeklyEmailsTaskDetails(this.$route.query.email_id).then((res) => {
                this.taskData = res.data[0];
                this.taskData.completedHours = res.data[0].completedOrForecastedHours;
                this.criteriaCardData = this.criteriaCardData.map((criteria) => {
                    if ((Object.prototype.hasOwnProperty.call(res.data[0]), criteria.key)) {
                        criteria.value = res.data[0][criteria.key];

                        if (!criteria.type) {
                            criteria.type = res.data[0].type;
                        }
                    }
                    return criteria;
                });
                this.criteriaCardData = this.criteriaCardData.filter((i) => i.type === this.taskData.type);

                this.isLoading = false;
            });
        },
        async handleGetTaskRadar() {
            this.isLoading = true;
            await getWeeklyEmailsTaskRadar(this.$route.query?.email_id).then((res) => {
                res.data.forEach((item, index) => {
                    var arraynew = [];
                    this.taskDay = item.task.doneDate
                        ? moment(item.task.doneDate).day()
                        : moment(item.task.dueDate).day();
                    this.taskDay = this.taskDay ? this.taskDay : 1;
                    for (let i = 1; i < 8; i++) {
                        if (this.taskDay === i) {
                            arraynew.push(item.task.hoursCompletion ? item.task.hoursCompletion : 0);
                        } else {
                            arraynew.push(null);
                        }
                    }

                    this.chartData[index] = {
                        label: item.task.title,
                        data: arraynew,
                        fill: false,
                        borderWidth: 0,
                        pointBackgroundColor: '#e9495d',
                        pointBorderColor: '#e9495d',
                        pointBorderWidth: 6,
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: '#e9495d',
                        pointHoverBorderWidth: 2,
                    };
                });

                this.isLoading = false;
            });
        },
        async handleGetTaskLogs() {
            this.tblTaskPlanLogsData.loading = true;
            this.tblTaskPlanLogsData = await getWeeklyEmailsTaskLog(this.$route.query?.email_id).then((res) => {
                return {
                    ...res.data,
                    data: res.data,
                    loading: false,
                };
            });
        },
        async handleGetChattyLogs() {
            this.tblChattyLogsData.loading = true;
            this.tblChattyLogsData = await getWeeklyEmailsChattyLog(this.$route.query?.email_id).then((res) => {
                return {
                    ...res.data,
                    data: res.data,
                    loading: false,
                };
            });
        },
        async handleGetGeekyLogs() {
            this.tblGeekyLogsData.loading = true;
            this.tblGeekyLogsData = await getWeeklyEmailsGeekyLog(this.$route.query?.email_id).then((res) => {
                return {
                    ...res.data,
                    data: res.data,
                    loading: false,
                };
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.task-radar-wrapper {
    .goBackBtn {
        display: flex;
        justify-content: end;
    }
    .boldData {
        font-size: 25px;
    }
    .team-name-date-wrapper {
        display: flex;
        width: 70% !important;

        .ivu-date-picker {
            i,
            input {
                color: #463c6e;
            }
            input {
                border-color: #463c6e;
            }
        }
    }
    .back-btn-wrapper {
        display: flex;
        justify-content: end;

        .goBackBtn button {
            color: #463c6e;
            border-color: #463c6e;
        }
    }
    .criteria-cards {
        .ivu-card {
            margin: 5px;
            border-radius: 5px;
            box-shadow: none !important;

            .ivu-card-body {
                font-weight: 600;
                padding-top: 0 !important;

                .custom-icon {
                    margin-right: 5px;
                    position: relative;
                    top: 5px;
                    filter: invert(100%) sepia(100%) saturate(0) hue-rotate(134deg) brightness(106%) contrast(102%);
                }
            }
        }
        .doneBg {
            background-color: #49e97e;
            color: #fff;
        }
        .chattyBg {
            background-color: #6b5ca7;
            color: #fff;
        }
        .geekyBg {
            background-color: #766d9d;
            color: #fff;
        }
        .orderlyBg {
            background-color: #e9495d;
            color: #fff;
        }
        .hoursBg {
            background-color: #ffc107;
            color: #fff;

            .custom-icon {
                top: 2px !important;
            }
        }
    }
    .logs-table-wrapper {
        .ivu-col-span-12 {
            max-width: 49.5% !important;
            .radar-card {
                box-shadow: 0 2px 2px rgb(0 0 0 / 10%) !important;
                border-color: #eee;
                border-radius: 20px;
                margin-bottom: 20px;
                padding: 10px 0;
                height: 480px;
                display: flex;
                align-items: center;
                justify-content: center;

                .ivu-card-body > div {
                    position: relative;
                    width: 900px;
                    height: 400px;
                    overflow: auto;
                }
                .ivu-card-body > div:hover {
                    width: 2000px;
                }
                .no-data-wrapper {
                    width: auto !important;
                    height: fit-content !important;
                }
            }
        }
    }
    .team-name {
        color: #463c6e;
        text-transform: uppercase;
    }

    @media screen and (max-width: 767px) {
        .team-name-date-wrapper {
            width: 100% !important;
        }
        .criteria-cards {
            .ivu-col {
                flex: 0 0 50%;
                max-width: 50%;

                .ivu-card {
                    min-height: 116px;
                }
            }
        }
        .logs-table-wrapper {
            .ivu-col-span-12 {
                max-width: 100% !important;
                flex: 0 0 100%;

                .radar-card {
                    .ivu-card-body > div {
                        height: 265px;
                    }
                }
            }
        }
    }
}
</style>
