<template>
    <div class="user-dashboard-projects">
        <Card class="section-title"> PROJECTS </Card>
        <Table :columns="tblColumns" :data="tblData" height="250" size="small" :loading="isLoading" />
    </div>
</template>

<script>
import moment from 'moment';
import { getProjectByProjectMembers } from '@/services/project-members.services';

export default {
    data() {
        return {
            user_id: localStorage.getItem('user_id'),
            isLoading: false,
            tblData: [],
            tblColumns: [
                {
                    title: 'Project Name',
                    key: 'name',
                    minWidth: 200,
                },
                {
                    title: 'Start Date',
                    key: 'startDate',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.startDate ? moment(params.row.startDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'End Date',
                    key: 'endDate',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.endDate ? moment(params.row.endDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalProjectHours',
                    width: 120,
                },
                {
                    title: 'Progress %',
                    key: 'projectProgressPercentage',
                    minWidth: 200,
                    render: (h, params) => {
                        return h('div', [
                            h('Progress', {
                                props: {
                                    percent:
                                        Math.round((params.row.projectProgressPercentage * 100) / 100) < 100
                                            ? Math.round((params.row.projectProgressPercentage * 100) / 100)
                                            : 100,
                                    hideInfo: true,
                                    'stroke-color': '#49E97E',
                                },
                                on: {
                                    click: () => {},
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Actions',
                    key: 'id',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-eye',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    class: localStorage.getItem('mainRole') === 'pm' ? '' : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/project-monitoring?id=${params.row.id}`);
                                        },
                                    },
                                },
                                'view'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-analytics',
                                        size: '20',
                                    },
                                    on: {
                                        click: () => {
                                            this.$router.push(`/project-performance?id=${params.row.id}`);
                                        },
                                    },
                                },
                                'view'
                            ),
                        ]);
                    },
                },
            ],
        };
    },

    mounted() {
        this.handleGetProjects();
    },

    methods: {
        handleGetProjects() {
            this.isLoading = true;
            this.tblData = [];
            getProjectByProjectMembers(this.user_id)
                .then((res) => {
                    if (res.data.length) {
                        this.tblData = res.data
                            .filter(
                                (x) =>
                                    x.project.isActive === true &&
                                    x.project.isArchived === false &&
                                    x.isProjectMemberActive === true
                            )
                            .map((i) => {
                                return {
                                    name: i.project.name,
                                    startDate: i.project.startDate,
                                    endDate: i.project.endDate,
                                    totalProjectHours: i.project.totalProjectHours,
                                    projectProgressPercentage: i.project.projectProgressPercentage,
                                    id: i.project._id,
                                };
                            });
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss">
.icon-hide {
    display: none;
}
@media screen and (max-width: 767px) {
    .user-dashboard-projects {
        margin-top: 80px;
    }
}
</style>
