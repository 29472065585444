<template>
    <div class="weekly-email-todo-list">
        <Tabs
            v-if="project && Object.keys(project).length && project.trello.length"
            name="weekly_email_todo_list"
            class="proj-trello-tab-list"
            @on-click="handleOnTrelloChange"
        >
            <TabPane
                v-for="(trello, idx) in project.trello"
                :key="idx"
                :label="trello.name"
                :name="trello.id"
                tab="weekly_email_todo_list"
            >
                <div v-if="trelloBoardList.length">
                    <!-- <WeeklyEmailTodos
                        v-for="(list, idx) in trelloBoardList"
                        :key="idx"
                        class="md-margin-bottom"
                        :list="list"
                        :selected-email-type="selectedEmailType"
                        :selected-project="selectedProject"
                        :selected-trello-assigned-todos="selectedTrelloAssignedTodos"
                        @handleTrelloSelections="handleTrelloSelections"
                    /> -->
                    <WeeklyEmailTodos
                        class="md-margin-bottom"
                        :list="trelloBoardList"
                        :selected-email-type="selectedEmailType"
                        :selected-project="selectedProject"
                        :selected-trello-assigned-todos="selectedTrelloAssignedTodos"
                        @handleTrelloSelections="handleTrelloSelections"
                    />
                </div>
                <p v-else class="text-center">No Data</p>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import { getTrelloBoardList } from '@/services/trello.services';
import WeeklyEmailTodos from '@/components/WeeklyEmails/Trello/WeeklyEmailTodos';

export default {
    components: {
        WeeklyEmailTodos,
    },
    props: {
        project: {
            type: Object,
            default: () => {},
        },
        selectedTrelloAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
        selectedProject: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            trelloBoardList: [],
            trelloSelections: {},
        };
    },

    watch: {
        project: {
            handler(data) {
                this.trelloBoardList = [];
                if (data && Object.keys(data).length && data.trello.length) {
                    this.handleGetTrelloBoardList(data.trello[0].id);
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.trelloBoardList = [];
        if (this.project && Object.keys(this.project).length && this.project.trello.length) {
            this.handleGetTrelloBoardList(this.project.trello[0].id);
        }
    },
    methods: {
        handleGetTrelloBoardList(id) {
            getTrelloBoardList(id)
                .then((res) => {
                    this.trelloBoardList = res.data.filter((x) => {
                        const boardName = x.name.toLowerCase();
                        if (this.selectedEmailType == 'plan') {
                            if (boardName.includes('todo'.toLowerCase()) || boardName.includes('To Do'.toLowerCase())) {
                                this.trelloSelections[x.id] = [];
                                return x;
                            }
                        } else {
                            if (boardName.includes('Done'.toLowerCase())) {
                                this.trelloSelections[x.id] = [];
                                return x;
                            }
                        }
                    });
                })
                .catch(() => {
                    this.$Message.error('Failed to get boards list');
                });
        },

        handleTrelloSelections(id, data) {
            this.trelloSelections[id] = data;
            this.$emit('handleTrelloSelections', this.trelloSelections);
        },

        handleOnTrelloChange(val) {
            this.handleGetTrelloBoardList(val);
        },
    },
};
</script>

<style lang="scss">
.weekly-email-todo-list {
    .proj-trello-tab-list {
        .ivu-tabs-content {
            margin: 20px;
        }
    }
}
</style>
