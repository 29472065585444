<template>
    <div class="weekly-email-wrapper">
        <Card class="page-title" dis-hover :bordered="false"> WEEKLY EMAILS</Card>
        <Card dis-hover :bordered="false">
            <div class="d-flex-center lg-margin-bottom">
                <Row class="fullwidth">
                    <Col flex="400px" class="d-flex-center project-selector">
                        <p class="md-margin-right">Project</p>
                        <Select
                            v-model="selectedProjectId"
                            filterable
                            clearable
                            :loading="isLoading"
                            style="max-width: 300px"
                            @on-change="handleSelectProjectChange(selectedProjectId)"
                        >
                            <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                                {{ item.label }}
                            </Option>
                        </Select>
                    </Col>
                    <Col v-if="selectedProject" flex="auto" class="text-right send-btn">
                        <div v-if="isDisable == true && selectedEmailType == 'plan'">
                            <Button
                                class="pt-btn-1"
                                style="width: 100px"
                                :loading="isSending"
                                @click="handleOnSendEmail"
                            >
                                Send
                            </Button>
                        </div>
                        <div v-else-if="isDisable == false && selectedEmailType == 'wrapup'">
                            <Button class="pt-btn-1" style="width: 100px" :loading="isSending" :disabled="true">
                                Send
                            </Button>
                        </div>
                        <div v-else>
                            <Button
                                class="pt-btn-1"
                                style="width: 100px"
                                :loading="isSending"
                                @click="handleOnSendEmail"
                            >
                                Send
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div v-if="selectedProject">
                <Row class="radar-wrapper" type="flex" justify="space-between" style="margin-bottom: 20px">
                    <Col span="16">
                        <WeeklyEmailComposer
                            v-if="renderComponent"
                            ref="WeeklyEmailComposer"
                            :selected-tab="selectedEmailType"
                            :disabled="isDisable && selectedEmailType == 'wrapup'"
                            @selectedEmailType="(val) => (selectedEmailType = val)"
                            @messages="(val) => (messages = val)"
                        />
                    </Col>
                    <Col span="8">
                        <Card class="radar-card">
                            <div v-if="isLoading">
                                <Spin fix></Spin>
                            </div>
                            <TrackerRadarChart
                                v-if="radarChartData.length"
                                :chartdata-sets="radarChartData"
                                :chartdata-labels="radarChartDataLabels"
                                :width="400"
                                :height="400"
                            />
                            <div v-if="!radarChartData.length" class="no-data-wrapper">No Data</div>
                        </Card>
                    </Col>
                </Row>
                <Card
                    v-if="renderComponent"
                    class="md-margin-top"
                    dis-hover
                    :bordered="false"
                    :disabled="isDisable && selectedEmailType === 'wrapup'"
                >
                    <div v-if="selectedEmailType === 'plan'">
                        <AsanaTodoList
                            v-if="
                                selectedProjectDetails &&
                                Object.keys(selectedProjectDetails).length &&
                                selectedProjectDetails.asana.length
                            "
                            ref="AsanaTodoList"
                            :project="selectedProjectDetails"
                            :selected-assigned-todos="selectedAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleAsanaSelections="handleAsanaSelections"
                        />
                        <WeeklyEmailTodoList
                            v-else-if="
                                selectedProjectDetails &&
                                Object.keys(selectedProjectDetails).length &&
                                selectedProjectDetails.trello.length
                            "
                            ref="WeeklyEmailTodoList"
                            class="md-margin-top"
                            :project="selectedProjectDetails"
                            :selected-trello-assigned-todos="selectedTrelloAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleTrelloSelections="handleTrelloSelections"
                        />
                        <JiraTodoList
                            v-else-if="
                                selectedProjectDetails &&
                                Object.keys(selectedProjectDetails).length &&
                                selectedProjectDetails.jira.length
                            "
                            ref="JiraTodoList"
                            class="md-margin-top"
                            :project="selectedProjectDetails"
                            :selected-jira-assigned-todos="selectedJiraAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleJiraSelections="handleJiraSelections"
                        />
                        <GeneralTodoList
                            v-else
                            ref="GeneralTodoList"
                            class="md-margin-top"
                            :project="selectedProjectDetails"
                            :selected-assigned-todos="selectedAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleGeneralSelections="handleGeneralSelections"
                        />
                    </div>
                    <div v-else-if="isDisable && selectedEmailType === 'wrapup'">
                        <AsanaTodoList
                            v-if="
                                selectedProjectDetails &&
                                Object.keys(selectedProjectDetails).length &&
                                selectedProjectDetails.asana.length
                            "
                            ref="AsanaTodoList"
                            :project="selectedProjectDetails"
                            :selected-assigned-todos="selectedAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleAsanaSelections="handleAsanaSelections"
                        />
                        <WeeklyEmailTodoList
                            v-else-if="
                                selectedProjectDetails &&
                                Object.keys(selectedProjectDetails).length &&
                                selectedProjectDetails.trello.length
                            "
                            ref="WeeklyEmailTodoList"
                            class="md-margin-top"
                            :project="selectedProjectDetails"
                            :selected-trello-assigned-todos="selectedTrelloAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleTrelloSelections="handleTrelloSelections"
                        />
                        <JiraTodoList
                            v-else-if="
                                selectedProjectDetails &&
                                Object.keys(selectedProjectDetails).length &&
                                selectedProjectDetails.jira.length
                            "
                            ref="JiraTodoList"
                            class="md-margin-top"
                            :project="selectedProjectDetails"
                            :selected-jira-assigned-todos="selectedJiraAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleJiraSelections="handleJiraSelections"
                        />
                        <GeneralTodoList
                            v-else
                            ref="GeneralTodoList"
                            class="md-margin-top"
                            :project="selectedProjectDetails"
                            :selected-assigned-todos="selectedAssignedTodos"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject.value"
                            @handleGeneralSelections="handleGeneralSelections"
                        />
                    </div>
                </Card>
            </div>
        </Card>
        <Card dis-hover style="position: absolute; left: -9999px">
            <TrackerBarGraph
                id="trackerBarGraphEmail"
                style="height: 300px"
                :chartdata-sets="chartData"
                :chartdata-labels="chartDataLabels"
                :max-ticks="chartDataMaxTicks"
            />
        </Card>
    </div>
</template>

<script>
import moment from 'moment';
import { getAsanaProjectSections } from '@/services/asana.services';
import { getUserProjectsWithTimelogs, getProjectsById, getProjects } from '@/services/projects.services';
import { getBarTimelogsByUser, getTimelogsByUserId } from '@/services/timelogs.services';
import { sendWeeklyEmail, sentWeeklyEmailTasks } from '@/services/weekly-emails.services';
import WeeklyEmailComposer from '@/components/WeeklyEmails/WeeklyEmailComposer';
import WeeklyEmailTodoList from '@/components/WeeklyEmails/Trello/WeeklyEmailTodoList';
import AsanaTodoList from '@/components/WeeklyEmails/Asana/AsanaTodoList';
import GeneralTodoList from '@/components/WeeklyEmails/General/GeneralTodoList';
import JiraTodoList from '@/components/WeeklyEmails/Jira/JiraTodoList';
import domtoimage from 'dom-to-image';
import TrackerBarGraph from '@/components/Graphs/BarChart.js';
import TrackerRadarChart from '@/components/Graphs/RadarChart.js';
import Bugsnag from '@bugsnag/js';

export default {
    components: {
        WeeklyEmailComposer,
        WeeklyEmailTodoList,
        AsanaTodoList,
        TrackerBarGraph,
        GeneralTodoList,
        JiraTodoList,
        TrackerRadarChart,
    },
    data() {
        return {
            isDisable: false,
            timelogData: null,
            isLoading: false,
            isSending: false,
            selectedProject: null,
            selectedProjectId: null,
            selectedProjectDetails: null,
            asanaProjectSections: [],
            gidSection: null,
            emailId: null,
            projectsList: [],
            selectedAssignedTodos: [],
            selectedJiraAssignedTodos: [],
            selectedTrelloAssignedTodos: [],
            showThirdPartySelection: false,
            renderComponent: true,
            selectedEmailType: 'plan',
            messages: '',
            emailGraph: '',
            user_id: localStorage.getItem('user_id'),
            chartData: [],
            chartDataLabels: [],
            daily8Data: [],
            timelogsData: [],
            chartDataMaxTicks: [],
            radarChartData: [],
            radarChartDataLabels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        };
    },

    watch: {
        selectedEmailType() {
            this.handleReload();
        },
        selectedProjectDetails: {
            handler(data) {
                this.asanaProjectSections = [];
                if (data && Object.keys(data).length && data.asana.length) {
                    this.handleGetAsanaProjectSections(data.asana[0].id);
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.handleGetProjects();
        // this.handleGetBarTimelogUser();
        this.handleGetTimelogsByUser();
    },

    methods: {
        handleGetAsanaProjectSections(id) {
            return getAsanaProjectSections(id)
                .then((res) => {
                    if (res.data && res.data.data.length) {
                        this.asanaProjectSections = res.data.data.filter((x) => {
                            const ptSectionName = x.name.toLowerCase();
                            if (
                                ptSectionName.includes('going'.toLowerCase()) ||
                                ptSectionName.includes('Going'.toLowerCase())
                            ) {
                                return x;
                            }
                        });
                        this.gidSection = this.asanaProjectSections[0].gid;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get asana project tasks');
                });
        },

        handleGetTimelogsByUser() {
            getTimelogsByUserId(this.user_id)
                .then((res) => {
                    this.timeLogData = res.data.data[0];
                    if (this.timeLogData.endTime != null) {
                        this.isDisable = true;
                    } else {
                        this.isDisable = false;
                    }
                    this.radarChartData = [];
                })
                .catch(() => {
                    // this.$Message.error('Failed to get timelog user')
                    this.isLoading = false;
                });
        },

        handleReload() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
            this.radarChartData = [];
        },

        async handleGetProjects() {
            this.isLoading = true;
            this.projectsList = [];
            let allData = [];
            if (localStorage.getItem('mainRole') === 'admin') {
                allData = await this.handleGetAllProjects();
            } else {
                allData = await this.handleGetUserProjectsWithTimelogs();
            }
            if (allData) {
                const parsedAllData = allData.reduce((acc, obj) => {
                    if (obj.isActive && obj.isArchived === false) {
                        acc.push({
                            value: obj._id,
                            label: obj.name,
                        });
                    }
                    return acc;
                }, []);
                this.projectsList = await Promise.all(parsedAllData);
                this.projectsList = this.projectsList.sort((a, b) => (a.label > b.label ? 1 : -1));
            }

            if (this.selectedProjectId) {
                this.handleGetProjectById(this.selectedProjectId);
            }
            this.isLoading = false;
        },

        handleGetUserProjectsWithTimelogs() {
            return getUserProjectsWithTimelogs(localStorage.getItem('user_id'))
                .then((res) => {
                    if (res.data.length) {
                        return res.data;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                });
        },

        handleGetAllProjects() {
            return getProjects()
                .then((res) => {
                    if (res.data.length) {
                        return res.data;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get all projects');
                });
        },

        handleOnSendEmail() {
            if (this.selectedProject !== null) {
                this.isSending = true;
                let email_content = '';
                const node = document.getElementById('trackerBarGraphEmail');
                this.isShow = true;
                let vm = this;
                domtoimage
                    .toPng(node)
                    .then(function (dataUrl) {
                        const img = new Image();
                        img.src = dataUrl;
                        document.body.appendChild(img);
                        vm.emailGraph = img.src;
                        email_content = vm.parseselectedTodos();
                        if (vm.selectedAssignedTodos.length) {
                            const data = {
                                projectId: vm.selectedProject.value,
                                userId: localStorage.getItem('user_id'),
                                type: vm.selectedEmailType,
                                email_content: email_content,
                                wordCount: document
                                    .getElementsByClassName('tox-statusbar__wordcount')[0]
                                    .innerText.replace(' WORDS', ''),
                                numOfTasks: vm.selectedAssignedTodos.length,
                                base64File: vm.emailGraph,
                            };
                            vm.handleSendWeeklyEmail(data);
                        } else if (vm.selectedTrelloAssignedTodos.length) {
                            const data = {
                                projectId: vm.selectedProject.value,
                                userId: localStorage.getItem('user_id'),
                                type: vm.selectedEmailType,
                                email_content: email_content,
                                wordCount: document
                                    .getElementsByClassName('tox-statusbar__wordcount')[0]
                                    .innerText.replace(' WORDS', ''),
                                numOfTasks: vm.selectedTrelloAssignedTodos.length,
                                base64File: vm.emailGraph,
                            };
                            vm.handleSendWeeklyEmail(data);
                        } else {
                            const data = {
                                projectId: vm.selectedProject.value,
                                userId: localStorage.getItem('user_id'),
                                type: vm.selectedEmailType,
                                email_content: email_content,
                                wordCount: document
                                    .getElementsByClassName('tox-statusbar__wordcount')[0]
                                    .innerText.replace(' WORDS', ''),
                                numOfTasks: vm.selectedJiraAssignedTodos.length,
                                base64File: vm.emailGraph,
                            };
                            vm.handleSendWeeklyEmail(data);
                        }
                    })
                    .catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    });
            } else {
                this.$Message.error('Please select a project');
            }
        },

        handleSendWeeklyEmail(data) {
            const requestDate = moment().format('YYYY-MM-DD');
            if (this.selectedAssignedTodos.length) {
                sendWeeklyEmail(data, requestDate)
                    .then((data) => {
                        this.emailId = data.data.emailId;
                        this.handleSentAsanaWeeklyEmailTask();
                        this.$Message.success('Email successfully sent');
                        this.selectedAssignedTodos = [];
                        this.handleReload();
                        this.isSending = false;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to send your weekly email');
                        this.isSending = false;
                    });
            } else if (this.selectedTrelloAssignedTodos.length) {
                sendWeeklyEmail(data, requestDate)
                    .then((data) => {
                        this.emailId = data.data.emailId;
                        this.handleSentTrelloWeeklyEmailTask();
                        this.$Message.success('Email successfully sent');
                        this.selectedTrelloAssignedTodos = [];
                        this.handleReload();
                        this.isSending = false;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to send your weekly email');
                        this.isSending = false;
                    });
            } else {
                sendWeeklyEmail(data, requestDate)
                    .then((data) => {
                        this.emailId = data.data.emailId;
                        this.handleSentJiraWeeklyEmailTask();
                        this.$Message.success('Email successfully sent');
                        this.selectedJiraAssignedTodos = [];
                        this.handleReload();
                        this.isSending = false;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to send your weekly email');
                        this.isSending = false;
                    });
            }
        },

        handleSentAsanaWeeklyEmailTask() {
            const dataTasks = {
                emailId: this.emailId,
                tasks: this.selectedAssignedTodos.map((x) => {
                    const a = x.done;
                    const b = x.ongoing;
                    let d = '';
                    let o = '';
                    if (a) {
                        Object.entries(a).forEach(([key, value]) => {
                            const sectionId = key;
                            const sectionName = key.toLowerCase();
                            if (sectionId.includes(x.section.gid)) {
                                d = value;
                            } else if (sectionName.includes('Done'.toLowerCase())) {
                                d = value;
                            }
                        });
                    }
                    if (b) {
                        Object.entries(b).forEach(([key, value]) => {
                            const sectionId = key;
                            const sectionName = key.toLowerCase();
                            if (sectionId.includes(this.gidSection)) {
                                o = value;
                            } else if (sectionName.includes('Going'.toLowerCase())) {
                                o = value;
                            }
                        });
                    }
                    return {
                        taskId: x._id,
                        task: {
                            title: x.name,
                            dueDate: x.due,
                            hoursCompletion: x.hours,
                            onGoingDate: o,
                            doneDate: d,
                        },
                    };
                }),
            };
            sentWeeklyEmailTasks(dataTasks)
                .then(() => {
                    this.$Message.success('Email tasks successfully saved');
                    this.selectedAssignedTodos = [];
                    this.handleReload();
                    this.isSending = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to save your weekly email tasks');
                    this.isSending = false;
                });
        },

        handleSentTrelloWeeklyEmailTask() {
            const dataTasks = {
                emailId: this.emailId,
                tasks: this.selectedTrelloAssignedTodos.map((x) => {
                    const d = moment(x.done).format('ddd DD MMM, YYYY');
                    return {
                        taskId: x._id,
                        task: {
                            title: x.card_name,
                            dueDate: x.due,
                            doneDate: d,
                        },
                    };
                }),
            };
            sentWeeklyEmailTasks(dataTasks)
                .then(() => {
                    this.$Message.success('Email tasks successfully saved');
                    this.selectedTrelloAssignedTodos = [];
                    this.handleReload();
                    this.isSending = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to save your weekly email tasks');
                    this.isSending = false;
                });
        },

        handleSentJiraWeeklyEmailTask() {
            const dataTasks = {
                emailId: this.emailId,
                tasks: this.selectedJiraAssignedTodos.map((x) => {
                    const updatedAt = moment(x.updatedAt).format('DD-MMM-YYYY');
                    return {
                        taskId: x._id,
                        task: {
                            title: x.name,
                            issueNo: x.key,
                            status: x.status,
                            updatedAt: updatedAt,
                        },
                    };
                }),
            };
            sentWeeklyEmailTasks(dataTasks)
                .then(() => {
                    this.$Message.success('Email tasks successfully saved');
                    this.selectedJiraAssignedTodos = [];
                    this.handleReload();
                    this.isSending = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to save your weekly email tasks');
                    this.isSending = false;
                });
        },

        parseselectedTodos() {
            let email_content = '';
            let initialStrng = '';
            let taskTr = '';
            email_content = `<div style="max-width: 80%;margin:auto;"><div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">${
                this.selectedEmailType == 'plan' ? 'WEEKLY PLAN' : 'END OF WEEK WRAPUP'
            }</div>`;
            if (this.selectedAssignedTodos.length) {
                initialStrng = `<div style='background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;'>${
                    this.selectedEmailType == 'plan' ? 'PM TOOL > TASKS TO DO' : 'PM TOOL > DONE TASKS'
                }</div><table style="border-collapse: collapse;width: 100%;width: 95%;margin: 15px auto;margin-bottom: 30px;"><thead><tr><th style="background-color: #f8f8f9;padding: 8px;white-space: nowrap;overflow: hidden;text-align: left;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Task Title</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Due Date</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Hours #</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">${
                    this.selectedEmailType == 'plan' ? '' : 'On-going'
                }</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">${
                    this.selectedEmailType == 'plan' ? '' : 'Done'
                }</th></tr></thead><tbody>`;
                this.selectedAssignedTodos.map((i) => {
                    const a = i?.done ? moment(i?.done).format('ddd DD MMM, YYYY') : '-';
                    const b = i?.ongoing ? moment(i?.ongoing).format('ddd DD MMM, YYYY') : '-';
                    // let d = '';
                    // let o = '';
                    // if (a) {
                    //     Object.entries(a).forEach(([key, value]) => {
                    //         const sectionId = key;
                    //         const sectionName = key.toLowerCase();
                    //         if (sectionId.includes(i.section.gid)) {
                    //             d = value;
                    //         } else if (sectionName.includes('Done'.toLowerCase())) {
                    //             d = value;
                    //         }
                    //     });
                    //     d = d ? moment(a).format('ddd DD MMM, YYYY') : '-';
                    // }
                    // if (b) {
                    //     Object.entries(b).forEach(([key, value]) => {
                    //         const sectionId = key;
                    //         const sectionName = key.toLowerCase();
                    //         if (sectionId.includes(this.gidSection)) {
                    //             o = value;
                    //         } else if (sectionName.includes('Going'.toLowerCase())) {
                    //             o = value;
                    //         }
                    //     });
                    //     o = o ? moment(b).format('ddd DD MMM, YYYY') : '-';
                    // }
                    taskTr += `<tr style="border-bottom: 1px solid #EBEBEB !important;border-collapse: collapse !important;"><td style="text-align: left;padding: 8px;height: 100%;">${
                        i?.name ? i.name : i?.card_name || i?.title
                    }</td><td style="text-align: center;padding: 8px;height: 100%;">${
                        i?.due ? moment(i.due).format('ddd DD MMM, YYYY') : '-'
                    }</td><td style="padding: 8px;text-align: center;height: 100%;">${i?.hours ? i.hours : '-'}</td>${
                        this.selectedEmailType == 'wrapup'
                            ? '<td style="padding: 8px;text-align: center;height: 100%;">' + b + '</td>'
                            : ''
                    }${
                        this.selectedEmailType == 'wrapup'
                            ? '<td style="padding: 8px;text-align: center;height: 100%;">' + a + '</td>'
                            : ''
                    }</tr>`;
                });

                initialStrng = initialStrng + taskTr;
                initialStrng = initialStrng + '</tbody></table>';
                initialStrng =
                    initialStrng +
                    `${
                        this.selectedEmailType == 'wrapup'
                            ? `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">TIME TRACKER</div><div style="text-align:center;margin: 15px;"><img src='000000000000000000000000'></div>`
                            : ''
                    }`;
                initialStrng =
                    initialStrng +
                    `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">THANKS FOR WORKING WITH US!</div><div style="width: 95%;margin: auto;padding: 8px;"><p>Access to <a href="https://projects.startechup.dev/login" target="_blank"><button style="background: rgb(233,73,93);padding: 5px 10px;border-radius: 3px;color: rgb(255,255,255);border: none;">Project Tracker</button></a></p></div><div style="display:flex;justify-content:center;margin:auto;"><div style="text-align: center;background-color: #463c6e;"><a href="https://www.facebook.com/startechup" target="_blank" style=" margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/fb.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://wa.me/message/D5J327ZFZKQ3B1" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/whatsapp.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://twitter.com/StarTechUp" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/twitter.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.instagram.com/startech_up/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/insta.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.linkedin.com/company/startechup/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/linkedin.png" style="margin-top:5px;margin-bottom: 2px;height:25px;"></a><a href="https://www.youtube.com/c/Startechup" target="_blank" style="margin: 0px 5px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/youtube.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a></div></div>`;
                email_content =
                    email_content +
                    `<div style="width: 95%;margin: auto;padding: 8px;">` +
                    this.messages.replace(/(?:\r\n|\r|\n)/g, '') +
                    `</div>` +
                    initialStrng;
            } else if (this.selectedTrelloAssignedTodos.length) {
                initialStrng = `<div style='background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;'>${
                    this.selectedEmailType == 'plan' ? 'PM TOOL > TASKS TO DO' : 'PM TOOL > DONE TASKS'
                }</div><table style="border-collapse: collapse;width: 100%;width: 95%;margin: 15px auto;margin-bottom: 30px;"><thead><tr><th style="background-color: #f8f8f9;padding: 8px;white-space: nowrap;overflow: hidden;text-align: left;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Card Title</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Due Date</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">${
                    this.selectedEmailType == 'plan' ? '' : 'Done'
                }</th></tr></thead><tbody>`;
                this.selectedTrelloAssignedTodos.map((i) => {
                    const a = i?.done;
                    let d = '';
                    if (a) {
                        d = a.slice(0, 10);
                        d = moment(d).format('ddd DD MMM, YYYY');
                    } else {
                        d = '-';
                    }
                    taskTr += `<tr style="border-bottom: 1px solid #EBEBEB !important;border-collapse: collapse !important;"><td style="text-align: left;padding: 8px;height: 100%;">${
                        i?.name ? i.name : i?.card_name || i?.title
                    }</td><td style="text-align: center;padding: 8px;height: 100%;">${
                        i?.due ? moment(i.due).format('ddd DD MMM, YYYY') : '-'
                    }</td>${
                        this.selectedEmailType == 'wrapup'
                            ? '<td style="padding: 8px;text-align: center;height: 100%;">' + d + '</td>'
                            : ''
                    }</tr>`;
                });

                initialStrng = initialStrng + taskTr;
                initialStrng = initialStrng + '</tbody></table>';
                initialStrng =
                    initialStrng +
                    `${
                        this.selectedEmailType == 'wrapup'
                            ? `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">TIME TRACKER</div><div style="text-align:center;margin: 15px;"><img src='000000000000000000000000'></div>`
                            : ''
                    }`;
                initialStrng =
                    initialStrng +
                    `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">THANKS FOR WORKING WITH US!</div><div style="width: 95%;margin: auto;padding: 8px;"><p>Access to <a href="https://projects.startechup.dev/login" target="_blank"><button style="background: rgb(233,73,93);padding: 5px 10px;border-radius: 3px;color: rgb(255,255,255);border: none;">Project Tracker</button></a></p></div><div style="display:flex;justify-content:center;margin:auto;"><div style="text-align: center;background-color: #463c6e;"><a href="https://www.facebook.com/startechup" target="_blank" style=" margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/fb.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://wa.me/message/D5J327ZFZKQ3B1" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/whatsapp.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://twitter.com/StarTechUp" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/twitter.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.instagram.com/startech_up/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/insta.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.linkedin.com/company/startechup/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/linkedin.png" style="margin-top:5px;margin-bottom: 2px;height:25px;"></a><a href="https://www.youtube.com/c/Startechup" target="_blank" style="margin: 0px 5px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/youtube.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a></div></div>`;
                email_content =
                    email_content +
                    `<div style="width: 95%;margin: auto;padding: 8px;">` +
                    this.messages.replace(/(?:\r\n|\r|\n)/g, '') +
                    `</div>` +
                    initialStrng;
            } else if (this.selectedJiraAssignedTodos.length) {
                initialStrng = `<div style='background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;'>${
                    this.selectedEmailType == 'plan' ? 'PM TOOL > TASKS TO DO' : 'PM TOOL > DONE TASKS'
                }</div><table style="border-collapse: collapse;width: 100%;width: 95%;margin: 15px auto;margin-bottom: 30px;"><thead><tr><th style="background-color: #f8f8f9;padding: 8px;white-space: nowrap;overflow: hidden;text-align: left;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Issue #</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Name</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">UpdatedAt</th><th style="background-color: #f8f8f9;text-align: center;padding: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;vertical-align: middle;height: 100%;">Status</th></tr></thead><tbody>`;
                this.selectedJiraAssignedTodos.map((i) => {
                    taskTr += `<tr style="border-bottom: 1px solid #EBEBEB !important;border-collapse: collapse !important;"><td style="text-align: left;padding: 8px;height: 100%;">${
                        i.key ? i.key : '-'
                    }</td><td style="text-align: center;padding: 8px;height: 100%;">${
                        i.name ? i.name : '-'
                    }</td><td style="padding: 8px;text-align: center;height: 100%;">${
                        i.updatedAt ? moment(i.updatedAt).format('DD-MMM-YYYY') : '-'
                    }</td><td style="padding: 8px;text-align: center;height: 100%;">${
                        i.status ? i.status : '-'
                    }</td></tr>`;
                });

                initialStrng = initialStrng + taskTr;
                initialStrng = initialStrng + '</tbody></table>';
                initialStrng =
                    initialStrng +
                    `${
                        this.selectedEmailType == 'wrapup'
                            ? `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">TIME TRACKER</div><div style="text-align:center;margin: 15px;"><img src='000000000000000000000000'></div>`
                            : ''
                    }`;
                initialStrng =
                    initialStrng +
                    `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">THANKS FOR WORKING WITH US!</div><div style="width: 95%;margin: auto;padding: 8px;"><p>Access to <a href="https://projects.startechup.dev/login" target="_blank"><button style="background: rgb(233,73,93);padding: 5px 10px;border-radius: 3px;color: rgb(255,255,255);border: none;">Project Tracker</button></a></p></div><div style="display:flex;justify-content:center;margin:auto;"><div style="text-align: center;background-color: #463c6e;"><a href="https://www.facebook.com/startechup" target="_blank" style=" margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/fb.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://wa.me/message/D5J327ZFZKQ3B1" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/whatsapp.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://twitter.com/StarTechUp" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/twitter.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.instagram.com/startech_up/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/insta.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.linkedin.com/company/startechup/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/linkedin.png" style="margin-top:5px;margin-bottom: 2px;height:25px;"></a><a href="https://www.youtube.com/c/Startechup" target="_blank" style="margin: 0px 5px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/youtube.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a></div></div>`;
                email_content =
                    email_content +
                    `<div style="width: 95%;margin: auto;padding: 8px;">` +
                    this.messages.replace(/(?:\r\n|\r|\n)/g, '') +
                    `</div>` +
                    initialStrng;
            } else {
                initialStrng = initialStrng + taskTr;
                initialStrng = initialStrng + '</tbody></table>';
                initialStrng =
                    initialStrng +
                    `${
                        this.selectedEmailType == 'wrapup'
                            ? `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">TIME TRACKER</div><div style="text-align:center;margin: 15px;"><img src='000000000000000000000000'></div>`
                            : ''
                    }`;
                initialStrng =
                    initialStrng +
                    `<div style="background: #463C6E;border-radius: 10px;text-align:center;color: #fff;padding: 15px;">THANKS FOR WORKING WITH US!</div><div style="width: 95%;margin: auto;padding: 8px;"><p>Access to <a href="https://projects.startechup.dev/login" target="_blank"><button style="background: rgb(233,73,93);padding: 5px 10px;border-radius: 3px;color: rgb(255,255,255);border: none;">Project Tracker</button></a></p></div><div style="display:flex;justify-content:center;margin:auto;"><div style="text-align: center;background-color: #463c6e;"><a href="https://www.facebook.com/startechup" target="_blank" style=" margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/fb.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://wa.me/message/D5J327ZFZKQ3B1" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/whatsapp.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://twitter.com/StarTechUp" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/twitter.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.instagram.com/startech_up/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/insta.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a><a href="https://www.linkedin.com/company/startechup/" target="_blank" style="margin: 0px 0px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/linkedin.png" style="margin-top:5px;margin-bottom: 2px;height:25px;"></a><a href="https://www.youtube.com/c/Startechup" target="_blank" style="margin: 0px 5px 0 5px;"><img src="https://www.startechup.com/wp-content/uploads/youtube.png" style="margin-top:5px;margin-bottom:2px;height:25px;"></a></div></div>`;
                email_content =
                    email_content +
                    `<div style="width: 95%;margin: auto;padding: 8px;">` +
                    this.messages.replace(/(?:\r\n|\r|\n)/g, '') +
                    `</div>` +
                    initialStrng;
            }
            return email_content;
        },

        handleGetProjectById(id) {
            this.selectedProjectDetails = null;
            getProjectsById(id)
                .then((res) => {
                    this.selectedProjectDetails = res.data;
                    this.selectedProject = {
                        value: this.selectedProjectDetails._id,
                        label: this.selectedProjectDetails.name,
                    };
                    this.showThirdPartySelection =
                        this.selectedProjectDetails.trello.length &&
                        this.selectedProjectDetails.asana.length &&
                        this.selectedProjectDetails.jira.length;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                });
        },

        handleGetChartDataLabels() {
            const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY');
            const startDate = moment().subtract(10, 'days').format('MM/DD/YYYY');

            let date = [];
            let currentDate = startDate;
            while (moment(currentDate) <= moment(endDate)) {
                // date.push(currentDate);
                currentDate = moment(currentDate).add(1, 'days').format('MM/DD/YYYY');
                date.push(moment(currentDate).add(1, 'days').format('MM/DD'));
            }
            return (this.chartDataLabels = date);
        },

        handleGetBarTimelogUser() {
            this.handleGetChartDataLabels();
            this.chartData = [];
            getBarTimelogsByUser(this.user_id).then((res) => {
                const preDefinedArr = [];
                preDefinedArr.length = this.chartDataLabels.length;
                const dataArr = preDefinedArr.fill(0);
                const projId = this.selectedProjectId;
                try {
                    if (res.data) {
                        res.data
                            .filter((u) => u._id == projId)
                            .map((x) => {
                                const dataObj = {
                                    label: x.name,
                                    backgroundColor: x.timelogs[0].project.legendColor,
                                    data: Array.from(dataArr),
                                };
                                x.timelogs.map((i) => {
                                    const date = moment(i.startTime).utc().format('MM/DD');
                                    const dateIdx = this.chartDataLabels.indexOf(date);
                                    dataObj.data[dateIdx] = i.rowDaily - i.rowExtra; // += i.daily8
                                });
                                this.chartDataMaxTicks = Math.max(...dataObj.data);
                                this.chartData.push(dataObj);
                            });
                    }
                } catch (e) {
                    Bugsnag.notify(e);
                }
            });
        },

        handleSelectProjectChange(val) {
            if (val) {
                this.handleGetBarTimelogUser();
                this.handleGetProjectById(val);
                this.selectedAssignedTodos = [];
                this.selectedTrelloAssignedTodos = [];
                this.selectedJiraAssignedTodos = [];
                this.radarChartData = [];
            }
        },

        handleAsanaSelections(data) {
            this.selectedAssignedTodos = [];
            Object.keys(data).map((x) => {
                this.selectedAssignedTodos = this.selectedAssignedTodos.concat(data[x]);
            });
            this.handleGetRadarChartData(this.selectedAssignedTodos);
        },

        handleTrelloSelections(data) {
            this.selectedTrelloAssignedTodos = [];
            Object.keys(data).map((x) => {
                this.selectedTrelloAssignedTodos = this.selectedTrelloAssignedTodos.concat(data[x]);
            });
            this.handleGetRadarChartData(this.selectedTrelloAssignedTodos);
        },

        handleGeneralSelections(data) {
            this.selectedAssignedTodos = [];
            Object.keys(data).map((x) => {
                this.selectedAssignedTodos = this.selectedAssignedTodos.concat(data[x]);
            });
            this.handleGetRadarChartData(this.selectedAssignedTodos);
        },

        handleJiraSelections(data) {
            console.log('handleJiraSelections data: ', data);
            this.selectedJiraAssignedTodos = [];
            Object.keys(data).map((x) => {
                this.selectedJiraAssignedTodos = this.selectedJiraAssignedTodos.concat(data[x]);
            });
            console.log('selectedJiraAssignedTodos: ', this.selectedJiraAssignedTodos);
            this.handleGetRadarChartData(this.selectedJiraAssignedTodos);
        },
        async handleGetRadarChartData(data) {
            this.radarChartData = [];
            let tempData = [];
            await data.forEach((item, index) => {
                var arraynew = [];
                this.taskDay = item.done ? moment(item.done).day() : moment(item.due).day();
                this.taskDay = this.taskDay ? this.taskDay : 1;
                for (let i = 1; i < 8; i++) {
                    if (this.taskDay === i) {
                        arraynew.push(item.hours ? item.hours : 0);
                    } else {
                        arraynew.push(null);
                    }
                }

                this.radarChartData[index] = {
                    label: item.name ? item.name : item.card_name,
                    data: arraynew,
                    fill: false,
                    borderWidth: 0,
                    pointBackgroundColor: '#e9495d',
                    pointBorderColor: '#e9495d',
                    pointBorderWidth: 6,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: '#e9495d',
                    pointHoverBorderWidth: 2,
                };
                tempData = this.radarChartData;
            });
            this.radarChartData = tempData;
        },
    },
};
</script>

<style lang="scss" scoped>
.weekly-email-wrapper {
    .radar-wrapper {
        .ivu-col {
            padding: 10px;
        }

        .radar-card {
            box-shadow: 0 1px 2px rgb(0 0 0 / 10%) !important;
            border-color: #eee;
            border-radius: 0px;
            margin-bottom: 20px;
            padding: 10px 0;
            height: 430px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ivu-card-body > div {
                position: relative;
                width: 500px;
                height: 400px;
                overflow: hidden;
            }

            .ivu-card-body > div:hover {
                width: 2000px;
            }

            .no-data-wrapper {
                width: auto !important;
                height: fit-content !important;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .radar-wrapper {
            .radar-card {
                .ivu-card-body > div {
                    position: relative;
                    width: 330px;
                    height: 280px;
                    overflow: auto;
                }

                .ivu-card-body > div:hover {
                    width: 2000px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .send-btn {
            margin-top: 20px;

            button {
                width: 100% !important;
            }
        }
        .radar-wrapper {
            .ivu-col-span-16,
            .ivu-col-span-8 {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
}
</style>
