<template>
    <div class="client-projects">
        <Card class="section-title"> TEAM MEMBERS </Card>
        <Table :columns="tblColumns" :data="tblData" height="250" size="small" :loading="isLoading" />
    </div>
</template>

<script>
import moment from 'moment';
import { getProjectMembersByProjectId } from '@/services/project-members.services';

export default {
    props: {
        project: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            user_id: localStorage.getItem('user_id'),
            isLoading: false,
            tblData: [],
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    fixed: 'left',
                    minWidth: 200,
                },
                {
                    title: 'Email',
                    key: 'email',
                    width: 200,
                },
                {
                    title: 'Role',
                    key: 'mainRole',
                    width: 130,
                    render: (h, params) => {
                        if (params.row.mainRole) {
                            const role = this.mainRole.find((x) => x.value === params.row.mainRole).name;
                            return h('span', role);
                        } else {
                            return h('span', '');
                        }
                    },
                },
                {
                    title: 'Last Login',
                    key: 'monitoring_last_login',
                    width: 180,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.monitoring_last_login
                                ? moment(params.row.monitoring_last_login).format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'First Time tracker',
                    key: 'monitoring_last_time_tracker',
                    width: 180,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.monitoring_last_time_tracker
                                ? moment(params.row.monitoring_last_time_tracker).format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'Slack',
                    key: 'monitoring_slack',
                    width: 70,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_slack ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'PM tool',
                    key: 'monitoring_pmTool',
                    width: 85,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_pmTool ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Git',
                    key: 'monitoring_git',
                    width: 60,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_git ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Salarium',
                    key: 'monitoring_salarium',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_salarium ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Last Fri',
                    key: 'monitoring_lastFriEmail',
                    width: 80,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_lastFriEmail ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Monday',
                    key: 'monitoring_monEmail',
                    width: 85,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_monEmail ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Friday',
                    key: 'monitoring_friEmail',
                    width: 75,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_friEmail ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Actions',
                    key: 'id',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-star',
                                    size: '20',
                                },
                                on: {
                                    click: () => {
                                        this.$router.push(`/team-feedback?id=${params.row.id}`);
                                    },
                                },
                            }),
                        ]);
                    },
                },
            ],
        };
    },

    // mounted() {
    //   this.handleGetProjectMembers()
    // },
    watch: {
        project(val) {
            if (val) this.handleGetProjectMembers(val);
        },
    },

    methods: {
        handleGetProjectMembers(projId) {
            this.isLoading = true;
            this.tblData = [];
            getProjectMembersByProjectId(projId)
                .then((res) => {
                    if (res.data.length) {
                        this.tblData = res.data
                            .filter((x) => x.projectRole != 'client' && x.user.mainRole != 'admin')
                            .map((i) => {
                                return {
                                    name: i.user.name,
                                    email: i.user.email,
                                    mainRole: i.user.mainRole,
                                    monitoring_last_login: i.user.monitoring_last_login,
                                    monitoring_last_time_tracker: i.user.monitoring_last_time_tracker,
                                    monitoring_pmTool: i.user.monitoring_pmTool,
                                    monitoring_git: i.user.monitoring_git,
                                    monitoring_salarium: i.user.monitoring_salarium,
                                    monitoring_slack: i.user.monitoring_slack,
                                    monitoring_lastFriEmail: i.user.monitoring_lastFriEmail,
                                    monitoring_monEmail: i.user.monitoring_monEmail,
                                    monitoring_friEmail: i.user.monitoring_friEmail,
                                    id: i.user._id,
                                };
                            });
                    }
                    this.isLoading = false;
                    return [];
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members');
                    this.isLoading = false;
                    return [];
                });
        },
    },
};
</script>

<style></style>
