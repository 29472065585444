<template>
    <div class="weekly-email-composer" style="position: relative">
        <Tabs type="card" class="pt-tabs" name="composer" :value="selectedTab" @on-click="handleEmailTypeOnChange">
            <TabPane label="Weekly Plan Email" name="plan" tab="composer">
                <TinyMceEditor
                    v-if="renderComponent && selectedTab == 'plan'"
                    @messages="(val) => (emailMessages = val)"
                />
            </TabPane>
            <TabPane label="EoW Wrap-up Email" name="wrapup" tab="composer">
                <Alert v-if="isDisable" show-icon>
                    <template slot="desc">
                        Please stop your timer first before sending your end of week email. Thanks!
                    </template>
                </Alert>
                <TinyMceEditor
                    v-if="renderComponent && selectedTab == 'wrapup' && isDisable == false"
                    @messages="(val) => (emailMessages = val)"
                />
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import TinyMceEditor from '@/components/UI/TinyMceEditor';
import { getTimelogsByUserId } from '@/services/timelogs.services';

export default {
    components: {
        TinyMceEditor,
    },
    props: {
        selectedTab: {
            type: String,
            default: 'plan',
        },
    },
    data() {
        return {
            renderComponent: true,
            isLoading: false,
            emailMessages: '',
            isDisable: false,
            timelogData: null,
            user_id: localStorage.getItem('user_id'),
        };
    },

    watch: {
        emailMessages(val) {
            this.$emit('messages', val);
        },
    },

    mounted() {
        this.handleGetTimelogsByUser();
    },

    methods: {
        handleGetTimelogsByUser() {
            getTimelogsByUserId(this.user_id)
                .then((res) => {
                    this.timeLogData = res.data.data[0];
                    if (this.timeLogData.endTime) {
                        this.isDisable = false;
                    } else {
                        this.isDisable = true;
                    }
                })
                .catch(() => {
                    // this.$Message.error('Failed to get timelog user')
                    this.isLoading = false;
                });
        },

        handleReload() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.emailMessages = '';
                this.renderComponent = true;
            });
        },

        handleEmailTypeOnChange(name) {
            this.$emit('selectedEmailType', name);
        },
    },
};
</script>

<style></style>
