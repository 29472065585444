<template>
    <Modal
        v-model="showModal"
        title="Are you Checked-in on Salarium?"
        footer-hide
        class="salarium-reminder-modal-wrapper"
        :closable="false"
        :mask-closable="false"
    >
        <div>
            <p>Hello Team Member!</p>

            <p style="text-align: justify">
                Unfortunately Salarium cannot be integrated to our Project Tracker. Though Salarium is important for us
                to generate the payroll. So please make sure you are checking on Salarium so we'll be able to do our HR
                job properly. Thank you!
            </p>
            <br />
            <p>
                If you haven't yet checkin on Salarium >
                <a href="https://app.v3.salarium.com/login/" target="_blank">https://app.v3.salarium.com/login/</a>
            </p>

            <Row class="confirmation-actions md-margin-top" :gutter="10">
                <Col :xs="24" :sm="12">
                    <Button style="height: auto" long @click="handleConfirmation(false)">
                        NO <br />
                        Cancel Time Tracking
                    </Button>
                </Col>
                <Col :xs="24" :sm="12">
                    <Button class="pt-btn-3" style="height: auto" long @click="handleConfirmation(true)">
                        YES<br />
                        Start Time Tracking
                    </Button>
                </Col>
            </Row>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },

    methods: {
        openModal() {
            this.showModal = true;
        },
        handleConfirmation(status) {
            this.$emit('confirm', status);
            this.showModal = false;
        },
    },
};
</script>

<style>
.salarium-reminder-modal-wrapper.v-transfer-dom .ivu-modal-body,
.ivu-modal-confirm {
    padding: 16px;
}
</style>
