import { Radar } from 'vue-chartjs';

export default {
    extends: Radar,

    props: ['chartdataLabels', 'chartdataSets'],

    data() {
        return {
            chartdata: {
                labels: this.chartdataLabels,
                datasets: this.chartdataSets,
            },
            options: {
                data: {
                    labels: this.chartdataLabels,
                    datasets: this.chartdataSets,
                },
                layout: {
                    padding: {
                        bottom: 25, //set that fits the best
                    },
                },
                legend: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
                scale: {
                    ticks: {
                        beginAtZero: true,
                        max: 8,
                        stepSize: 1,
                        display: false,
                    },
                    pointLabels: {
                        fontColor: '#463C6E',
                        fontSize: 11,
                    },
                    gridLines: {
                        color: ['#463C6E', '#766D9D', '#463C6E', '#766D9D', '#463C6E', '#766D9D', '#463C6E', '#766D9D'],
                        circular: true,
                        lineWidth: 2,
                    },
                    angleLines: { color: '#6B5CA7' },
                },
                tooltips: {
                    enabled: true,
                    title: {
                        text: '',
                    },
                    backgroundColor: '#fff',
                    bodyFontColor: '#463C6E',
                    displayColors: false,
                    borderColor: '#e9495d',
                    titleFontColor: '#463C6E',
                    borderWidth: 1,
                    position: 'nearest',
                    xAlign: 'right',
                    yAlign: 'center',
                    callbacks: {
                        title: (context) => {
                            let hourText = context[0].value < 2 ? ' Hour' : ' Hours';
                            return context[0].value + hourText;
                        },
                        label: (context, data) => {
                            let label = data.datasets[context.datasetIndex].label;
                            return '• ' + label;
                        },
                    },
                },
            },
        };
    },
    mounted() {
        if (this.chartdata.datasets.length && this.chartdata.labels.length) {
            this.renderChart(this.chartdata, this.options);
        }
    },
    watch: {
        chartdataSets(val) {
            if (val) {
                this.chartdata.datasets = val;
                this.options.data.datasets = val;
                this.renderChart(this.chartdata, this.options);
            }
        },
        chartdataLabels(val) {
            if (val) {
                this.chartdata.labels = val;
                this.renderChart(this.chartdata, this.options);
            }
        },
    },
    // mounted() {
    //     this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    //     this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);

    //     this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)');
    //     this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    //     this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');

    //     this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    //     this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    //     this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)');
    //     this.renderChart(
    //         {
    //             labels: ['Books', 'Magazines', 'Newspapers'],
    //             datasets: [
    //                 {
    //                     backgroundColor: [this.gradient, this.gradient2, '#00D8FF'],
    //                     data: [40, 20, 10],
    //                 },
    //             ],
    //         },
    //         { responsive: true, maintainAspectRatio: false }
    //     );
    // },
};
