<template>
    <div class="jira-email-todo-list">
        <Select v-model="listType" style="max-width: 300px">
            <Option v-for="item in listTypes" :key="item.key" :value="item.key">
                {{ item.label }}
            </Option>
        </Select>

        <Tabs
            v-if="project && Object.keys(project).length && project.jira.length"
            name="jira_todo_list"
            class="proj-jira-tab-list"
            @on-click="handleOnJiraChange"
        >
            <TabPane
                v-for="(jira, idx) in project.jira"
                :key="idx"
                :label="`${jira.key}`"
                :name="jira.id"
                tab="jira_todo_list"
            >
                <div v-if="jiraSectionList.length">
                    <div v-if="listType === 'all'">
                        <JiraTodos
                            v-for="(status, idx) in jiraSectionList"
                            :key="idx"
                            :view-type="listType"
                            class="md-margin-bottom"
                            :status="status"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject"
                            :selected-jira-assigned-todos="selectedJiraAssignedTodos"
                            @handleJiraSelections="handleJiraSelections"
                        />
                    </div>
                    <div v-else-if="listType === 'assignedToMe'">
                        <JiraTodos
                            key="0"
                            :view-type="listType"
                            class="md-margin-bottom"
                            :sections="jiraSectionList"
                            :selected-email-type="selectedEmailType"
                            :selected-project="selectedProject"
                            :selected-jira-assigned-todos="selectedJiraAssignedTodos"
                            @handleJiraSelections="handleJiraSelections"
                        />
                    </div>
                </div>
                <p v-else class="text-center">No Data</p>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import { getJiraSections } from '@/services/jira.services';
import JiraTodos from '@/components/WeeklyEmails/Jira/JiraTodos';

export default {
    components: {
        JiraTodos,
    },
    props: {
        project: {
            type: Object,
            default: () => {},
        },
        selectedJiraAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
        selectedProject: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            listTypes: [
                {
                    key: 'all',
                    label: 'All',
                },
                {
                    key: 'assignedToMe',
                    label: 'Assigned to me',
                },
            ],
            listType: 'assignedToMe',
            jiraSectionList: [],
            jiraSelections: {},
        };
    },

    watch: {
        selectedProject: {
            handler(data) {
                this.handleGetJiraSectionList(data);
            },
            deep: true,
        },
    },

    mounted() {
        this.jiraSectionList = [];
        this.handleGetJiraSectionList(this.selectedProject);
    },
    methods: {
        handleGetJiraSectionList(id) {
            getJiraSections(id)
                .then((res) => {
                    this.jiraSectionList = res.data
                        .filter((x) => {
                            const secName = x.section.name.toLowerCase();
                            if (this.selectedEmailType == 'plan') {
                                if (
                                    !secName.includes('done'.toLowerCase()) ||
                                    !secName.includes('Done'.toLowerCase())
                                ) {
                                    this.jiraSelections[x.id] = [];
                                    return x;
                                }
                            } else {
                                this.jiraSelections[x.id] = [];
                                return x;
                            }
                        })
                        .map((i) => {
                            return {
                                section: i.section,
                            };
                        });
                    return this.jiraSectionList;
                })
                .catch(() => {
                    this.$Message.error('Failed to get jira section list');
                });
        },

        handleJiraSelections(id, data) {
            this.jiraSelections[id] = data;
            this.$emit('handleJiraSelections', this.jiraSelections);
        },

        handleOnJiraChange(val) {
            this.handleGetJiraSectionList(val);
        },
    },
};
</script>

<style lang="scss">
.weekly-email-todo-list {
    .proj-trello-tab-list {
        .ivu-tabs-content {
            margin: 20px;
        }
    }
}
</style>
