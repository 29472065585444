<template>
    <div class="user-dashboard-wrapper">
        <div class="lg-margin-bottom">
            <div>
                <TimeTracker />
            </div>
        </div>
        <Projects />
    </div>
</template>

<script>
import TimeTracker from '@/components/Dashboard/Dev/TimeTracker';
// import Notifications from '@/components/Dashboard/Dev/Notifications';
import Projects from '@/components/Dashboard/Dev/Projects';

export default {
    components: {
        TimeTracker,
        // Notifications,
        Projects,
    },
};
</script>
