<template>
    <div>
        <div class="lg-margin-bottom leaderboard-wrapper">
            <Row type="flex" justify="space-between" class="code-row-bg" style="margin-bottom: 30px">
                <div class="team-name-date-wrapper">
                    <Col>
                        <h2 class="md-margin-right team-name">{{ userData.name }}</h2>
                    </Col>
                    <Col span="9">
                        <DatePicker
                            v-model="selectedMonth"
                            type="month"
                            format="yyyy-MM"
                            placeholder="Select month"
                            @on-change="handleGetData(selectedMonth)"
                        />
                    </Col>
                </div>
                <div class="back-btn-wrapper">
                    <Col span="3" class="goBackBtn">
                        <Button style="margin-right: 10px; padding: 0 30px" @click="$router.go(-1)">
                            <strong>Go Back</strong>
                        </Button>
                    </Col>
                </div>
            </Row>
            <div v-if="selectedMonth">
                <Row class="criteria-cards" type="flex" justify="space-between" style="margin-bottom: 20px">
                    <Col v-for="criteria in criteriaCardData" :key="criteria.key" span="4"
                        ><Card :class="criteria.class">
                            <!-- <Icon :type="criteria.icon" size="35" /> -->
                            <img :src="criteria.icon" :width="criteria.width" class="custom-icon" />
                            <span class="boldData">{{ parseInt(criteria.value) }}</span>
                            <p>{{ criteria.name }}</p></Card
                        ></Col
                    >
                </Row>

                <!-- EMAIL LOGS TABLE -->
                <EmailLogsList
                    v-if="$route.query.id"
                    ref="EmailLogsList"
                    :count="tblEmailData.totalCount"
                    :emaildata="tblEmailData.data"
                    :loading="isLoading"
                    @on-page-change="handleGetUserEmailLogs"
                />

                <div v-if="$route.query.id">
                    <Row class="logs-table-wrapper" type="flex" justify="space-between" style="margin-bottom: 20px">
                        <!-- WEEKLY PLAN TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="TASKS WEEKLY PLAN"
                                :count="tblWeeklyPlanData.totalCount"
                                :weeklyplandata="tblWeeklyPlanData.data"
                                :loading="tblWeeklyPlanData.loading"
                                :tblColumns="tblWeeklyTasksColumns"
                                @on-page-change="handleGetWeeklyPlans"
                            />
                        </Col>

                        <!-- WEEKLY WRAPUP TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="TASKS END OF WEEK"
                                :count="tblWeeklyWrapUpData.totalCount"
                                :weeklyplandata="tblWeeklyWrapUpData.data"
                                :tblColumns="tblWeeklyTasksColumns"
                                :loading="tblWeeklyWrapUpData.loading"
                                @on-page-change="handleGetWeeklyWrapUps"
                            />
                        </Col>

                        <!-- THUMB TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="THUMBS"
                                :count="tblLikesData.totalCount"
                                :weeklyplandata="tblLikesData.data"
                                :tblColumns="tblThumbsColumns"
                                :loading="tblLikesData.loading"
                                @on-page-change="handleGetUserLikes"
                            />
                        </Col>

                        <!-- GEEKY LOG TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="GEEKY LOGS"
                                :count="tblGeekyLogsData.totalCount"
                                :weeklyplandata="tblGeekyLogsData.data"
                                :tblColumns="tblGeekColumns"
                                :loading="tblGeekyLogsData.loading"
                                @on-page-change="handleGetUserGeekyLogs"
                            />
                        </Col>

                        <!-- DAILY STANDUP TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="DAILY STAND-UP"
                                :count="tblDailyStandUpData.totalCount"
                                :weeklyplandata="tblDailyStandUpData.data"
                                :tblColumns="tblDailyStandUpColumns"
                                :loading="tblDailyStandUpData.loading"
                                @on-page-change="handleGetUserDailyStandUp"
                            />
                        </Col>

                        <!-- CHATTY LOG TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="CHATTY LOGS"
                                :count="tblChattyLogsData.totalCount"
                                :weeklyplandata="tblChattyLogsData.data"
                                :tblColumns="tblChattyColumns"
                                :loading="tblChattyLogsData.loading"
                                @on-page-change="handleGetUserChattyLogs"
                            />
                        </Col>

                        <!-- SWEAT EQUITY TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="SWEAT EQUITY"
                                :count="tblSweatData.totalCount"
                                :weeklyplandata="tblSweatData.data"
                                :tblColumns="tblSweatColumns"
                                :loading="tblSweatData.loading"
                                @on-page-change="handleGetUserSweatLogs"
                            />
                        </Col>

                        <!-- BRAIN EQUITY TABLE -->
                        <Col span="12">
                            <LogsTableList
                                ref="LogsTableList"
                                title="BRAIN EQUITY"
                                :count="tblBrainData.totalCount"
                                :weeklyplandata="tblBrainData.data"
                                :tblColumns="tblBrainColumns"
                                :loading="tblBrainData.loading"
                                @on-page-change="handleGetUserBrainLogs"
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import EmailLogsList from '@/components/Leaderboard/EmailLogsList';
import LogsTableList from '@/components/Leaderboard/LogsTableList';
import {
    getLeaderboard,
    getLeaderboardByProject,
    getLeaderboardAllProject,
    getUserEmailLogs,
    getUserWeeklyTasks,
    getUserLikes,
    getUserGeekyLogs,
    getUserDailyStandUp,
    getUserChattyLogs,
    getUserSweatLogs,
    getUserBrainLogs,
} from '@/services/rewards.services';
import envelopeIcon from '@/assets/images/envelope.svg';
import notepadIcon from '@/assets/images/notepad.svg';
import checklistIcon from '@/assets/images/checklist.svg';
import cloudClockIcon from '@/assets/images/cloud-clock.svg';
import clockCheckIcon from '@/assets/images/clock-check.svg';
import chatIcon from '@/assets/images/chat.svg';
import thumbsUpIcon from '@/assets/images/thumbsup.svg';
import thumbsDownIcon from '@/assets/images/thumbsdown.svg';
import brainIcon from '@/assets/images/brain.svg';
import smileIcon from '@/assets/images/smile.svg';
import gitBranchIcon from '@/assets/images/git-branch.svg';
import sweatIcon from '@/assets/images/sweat.svg';
export default {
    components: { EmailLogsList, LogsTableList },
    data() {
        return {
            selectedMonth: null,
            isLoading: false,
            currentPage: 1,
            totalCount: null,
            criteriaCardData: [
                {
                    key: 'getItDoneRate',
                    name: 'DONE RATE',
                    value: null,
                    icon: checklistIcon,
                    class: 'doneBg',
                    width: 25,
                },
                {
                    key: 'completedHours',
                    name: 'COMPLETED HOURS',
                    value: null,
                    icon: clockCheckIcon,
                    class: 'hoursBg',
                    width: 25,
                },
                {
                    key: 'chattyRate',
                    name: 'CHATTY RATE',
                    value: null,
                    icon: smileIcon,
                    class: 'chattyBg',
                    width: 23,
                },
                {
                    key: 'geekyRate',
                    name: 'GEEKY RATE',
                    value: null,
                    icon: gitBranchIcon,
                    class: 'geekyBg',
                    width: 18,
                },
                {
                    key: 'thumbsUp',
                    name: 'THUMBS UP',
                    value: null,
                    icon: thumbsUpIcon,
                    class: 'thumbsUpBg',
                    width: 25,
                },
                {
                    key: 'sweatEquity',
                    name: 'SWEAT EQUITY',
                    value: null,
                    icon: sweatIcon,
                    class: 'timeBg',
                    width: 16,
                },
                {
                    key: 'orderlyRate',
                    name: 'ORDERLY RATE',
                    value: null,
                    icon: notepadIcon,
                    class: 'orderlyBg',
                    width: 22,
                },
                {
                    key: 'forecastedHours',
                    name: 'FORECASTED HOURS',
                    value: null,
                    icon: cloudClockIcon,
                    class: 'hoursBg',
                    width: 30,
                },
                {
                    key: 'dailyStandUp',
                    name: 'DAILY STAND UP',
                    value: null,
                    icon: chatIcon,
                    class: 'dailyBg',
                    width: 23,
                },
                {
                    key: 'eloquencyRate',
                    name: 'EMAIL ELOQUENCY',
                    value: null,
                    icon: envelopeIcon,
                    class: 'emailBg',
                    width: 28,
                },
                {
                    key: 'thumbsDown',
                    name: 'THUMBS DOWN',
                    value: null,
                    icon: thumbsDownIcon,
                    class: 'thumbsDownBg',
                    width: 25,
                },
                {
                    key: 'brainEquityRate',
                    name: 'BRAIN EQUITY',
                    value: null,
                    icon: brainIcon,
                    class: 'brainBg',
                    width: 25,
                },
            ],
            userData: {},
            tblWeeklyTasksColumns: [
                {
                    title: 'Task',
                    key: 'title',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('span', params.row.task.title);
                    },
                },
                {
                    title: 'Due Date',
                    key: 'dueDate',
                    width: 120,
                    render: (h, params) => {
                        if (moment(params.row.task.dueDate).format('DD-MMM-YYYY') === moment().format('DD-MMM-YYYY')) {
                            return h('span', 'Today');
                        } else {
                            return h(
                                'span',
                                params.row.task.dueDate ? moment(params.row.task.dueDate).format('DD-MMM-YYYY') : '-'
                            );
                        }
                    },
                },
                {
                    title: '# of Hrs',
                    key: 'hoursCompletion',
                    width: 100,
                    render: (h, params) => {
                        return h('span', params.row.task.hoursCompletion ? params.row.task.hoursCompletion : '-');
                    },
                },
            ],
            tblThumbsColumns: [
                {
                    title: 'Message',
                    key: 'reason',
                    minWidth: 200,
                },
                {
                    title: 'Thumb',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: params.row.isLike ? 'md-thumbs-up' : 'md-thumbs-down',
                                        size: '20',
                                    },
                                },
                                'isLike'
                            ),
                        ]);
                    },
                },
                {
                    title: 'Who',
                    key: 'likedBy',
                    width: 150,
                    render: (h, params) => {
                        return h('span', params.row.likedBy.name);
                    },
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            tblGeekColumns: [
                {
                    title: 'Commit #',
                    key: 'commitNum',
                    width: 100,
                    render: (h, params) => {
                        return h('span', params.row.commitDetails.short_hash);
                    },
                },
                {
                    title: 'Message',
                    key: 'message',
                    minWidth: 200,
                    render: (h, params) => {
                        return h('span', params.row.commitDetails.message);
                    },
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            tblDailyStandUpColumns: [
                {
                    title: 'Message',
                    key: 'message',
                    minWidth: 250,
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            tblChattyColumns: [
                {
                    title: 'Message',
                    key: 'message',
                    minWidth: 250,
                    render: (h, params) => {
                        if (params.row.message) {
                            return h('span', params.row.message);
                        } else if (!params.row.message && params.row.messageDetails.files) {
                            return h('span', 'File');
                        } else {
                            return h('span', '-');
                        }
                    },
                },
                {
                    title: 'Timestamp',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.createdAt ? moment(params.row.createdAt).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            tblSweatColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    minWidth: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.timelog.createdAt
                                ? moment(params.row.timelog.createdAt).format('DD-MMM-YYYY')
                                : '-'
                        );
                    },
                },
                {
                    title: 'Start',
                    key: 'startTime',
                    minWidth: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.timelog.startTime ? moment(params.row.timelog.startTime).format('hh:mm A') : '-'
                        );
                    },
                },
                {
                    title: 'End',
                    key: 'endTime',
                    minWidth: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.timelog.endTime ? moment(params.row.timelog.endTime).format('hh:mm A') : '-'
                        );
                    },
                },
                {
                    title: 'D8',
                    key: 'daily8',
                    width: 70,
                    render: (h, params) => {
                        return h('span', params.row.timelog.daily8);
                    },
                },
                {
                    title: 'RD',
                    key: 'rowDaily',
                    width: 70,
                    render: (h, params) => {
                        return h('span', params.row.timelog.rowDaily);
                    },
                },
                {
                    title: 'Extra',
                    key: 'rowExtra',
                    width: 70,
                    render: (h, params) => {
                        return h('span', params.row.timelog.rowExtra);
                    },
                },
                {
                    title: 'Appr',
                    key: 'approvedExtra',
                    width: 70,
                    render: (h, params) => {
                        return h('span', params.row.timelog.approvedExtra);
                    },
                },
            ],
            tblBrainColumns: [
                {
                    title: 'Task',
                    key: 'task',
                    minWidth: 250,
                    render: (h, params) => {
                        return h('span', params.row.task.title);
                    },
                },
                {
                    title: 'Date',
                    key: 'date',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'span',
                            params.row.task.doneDate ? moment(params.row.task.doneDate).format('DD-MMM-YYYY') : '-'
                        );
                    },
                },
            ],
            tblData: [],
            tblEmailData: {},
            tblWeeklyPlanData: {},
            tblWeeklyWrapUpData: {},
            tblLikesData: {},
            tblGeekyLogsData: {},
            tblDailyStandUpData: {},
            tblChattyLogsData: {},
            tblSweatData: {},
            tblBrainData: {},
        };
    },
    beforeDestroy() {
        // Set flag to reload/resume leaderboard previous data
        localStorage.setItem('leaderboard.action', 'reload_data');
    },
    async mounted() {
        this.handleGetData(this.$route.params.month);
    },
    methods: {
        async handleGetData(date) {
            this.selectedMonth = moment(date).format('yyyy-MM');
            localStorage.setItem('leaderboard.date', this.selectedMonth);
            this.handleGetUserData();
            if (this.$route.query.id) {
                this.handleGetUserEmailLogs(this.currentPage);
                this.handleGetWeeklyPlans(this.currentPage);
                this.handleGetWeeklyWrapUps(this.currentPage);
                this.handleGetUserLikes(this.currentPage);
                this.handleGetUserGeekyLogs(this.currentPage);
                this.handleGetUserDailyStandUp(this.currentPage);
                this.handleGetUserChattyLogs(this.currentPage);
                this.handleGetUserSweatLogs(this.currentPage);
                this.handleGetUserBrainLogs(this.currentPage);
            }
        },
        async handleGetUserData() {
            this.isLoading = true;
            if (this.$route.query.id && this.$route.query.project) {
                await getLeaderboardByProject(this.selectedMonth, this.$route.query.project, this.$route.query.id).then(
                    (res) => {
                        this.userData = res.data[0];
                        this.criteriaCardData = this.criteriaCardData.map((criteria) => {
                            if ((Object.prototype.hasOwnProperty.call(res.data[0]), criteria.key)) {
                                criteria.value = res.data[0][criteria.key];
                            }
                            return criteria;
                        });
                        this.isLoading = false;
                    }
                );
            } else if (this.$route.query.project && !this.$route.query.id) {
                await getLeaderboardAllProject(this.selectedMonth, this.$route.query.project).then((res) => {
                    this.userData = res.data[0];
                    this.criteriaCardData = this.criteriaCardData.map((criteria) => {
                        if ((Object.prototype.hasOwnProperty.call(res.data[0]), criteria.key)) {
                            criteria.value = res.data[0][criteria.key];
                        }
                        return criteria;
                    });
                    this.isLoading = false;
                });
            } else {
                await getLeaderboard(this.selectedMonth, this.$route.query.id).then((res) => {
                    this.userData = res.data[0];
                    this.criteriaCardData = this.criteriaCardData.map((criteria) => {
                        if ((Object.prototype.hasOwnProperty.call(res.data[0]), criteria.key)) {
                            criteria.value = res.data[0][criteria.key];
                        }
                        return criteria;
                    });
                    this.isLoading = false;
                });
            }
        },
        async handleGetUserEmailLogs(page) {
            this.isLoading = true;
            this.tblEmailData = await getUserEmailLogs(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return res.data;
            });
            this.isLoading = false;
        },
        async handleGetWeeklyPlans(page) {
            this.tblWeeklyPlanData.loading = true;
            this.tblWeeklyPlanData = await getUserWeeklyTasks(
                this.selectedMonth,
                this.$route.query.id,
                page,
                'plan',
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetWeeklyWrapUps(page) {
            this.tblWeeklyWrapUpData.loading = true;
            this.tblWeeklyWrapUpData = await getUserWeeklyTasks(
                this.selectedMonth,
                this.$route.query.id,
                page,
                'wrapup',
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetUserLikes(page) {
            this.tblLikesData.loading = true;
            this.tblLikesData = await getUserLikes(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetUserGeekyLogs(page) {
            this.tblGeekyLogsData.loading = true;
            this.tblGeekyLogsData = await getUserGeekyLogs(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetUserDailyStandUp(page) {
            this.tblDailyStandUpData.loading = true;
            this.tblDailyStandUpData = await getUserDailyStandUp(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetUserChattyLogs(page) {
            this.tblChattyLogsData.loading = true;
            this.tblChattyLogsData = await getUserChattyLogs(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetUserSweatLogs(page) {
            this.tblSweatData.loading = true;
            this.tblSweatData = await getUserSweatLogs(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
        async handleGetUserBrainLogs(page) {
            this.tblBrainData.loading = true;
            this.tblBrainData = await getUserBrainLogs(
                this.selectedMonth,
                this.$route.query.id,
                page,
                this.$route.query?.project
            ).then((res) => {
                return {
                    ...res.data,
                    loading: false,
                };
            });
        },
    },
};
</script>
<style lang="scss">
.leaderboard-wrapper {
    .goBackBtn {
        display: flex;
        justify-content: end;
    }
    .boldData {
        font-size: 25px;
    }
    .team-name-date-wrapper {
        display: flex;
        width: 70% !important;

        .ivu-date-picker {
            i,
            input {
                color: #463c6e;
            }
            input {
                border-color: #463c6e;
            }
        }
    }
    .back-btn-wrapper {
        display: flex;
        justify-content: end;

        .goBackBtn button {
            color: #463c6e;
            border-color: #463c6e;
        }
    }
    .criteria-cards {
        .ivu-card {
            margin: 5px;
            border-radius: 5px;
            box-shadow: none !important;

            .ivu-card-body {
                font-weight: 600;
                padding-top: 0 !important;

                .custom-icon {
                    margin-right: 5px;
                    position: relative;
                    top: 5px;
                    filter: invert(100%) sepia(100%) saturate(0) hue-rotate(134deg) brightness(106%) contrast(102%);
                }
            }
        }
        .doneBg {
            background-color: #49e97e;
            color: #fff;
        }
        .chattyBg {
            background-color: #6b5ca7;
            color: #fff;
        }
        .geekyBg {
            background-color: #766d9d;
            color: #fff;
        }
        .thumbsUpBg {
            background-color: #076e00;
            color: #fff;
        }
        .thumbsDownBg {
            background-color: #ad7a20;
            color: #fff;
        }
        .timeBg,
        .dailyBg,
        .brainBg {
            background-color: #211841;
            color: #fff;
        }
        .orderlyBg {
            background-color: #e9495d;
            color: #fff;
        }
        .emailBg {
            background-color: #463c6e;
            color: #fff;

            .custom-icon {
                top: 1px !important;
            }
        }
        .hoursBg {
            background-color: #ffc107;
            color: #fff;

            .custom-icon {
                top: 2px !important;
            }
        }
    }
    .logs-table-wrapper {
        .ivu-col-span-12 {
            max-width: 49.5% !important;
        }
    }
    .team-name {
        color: #463c6e;
        text-transform: uppercase;
    }
}
@media screen and (max-width: 767px) {
    .leaderboard-wrapper {
        .team-name-date-wrapper {
            display: block;
            width: 100% !important;
            margin-bottom: 10px;

            .ivu-col {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        .criteria-cards {
            .ivu-col {
                flex: 0 0 50%;
                max-width: 50%;

                .ivu-card {
                    min-height: 100px;

                    .ivu-card-body {
                        word-break: break-word;
                    }
                }
            }
        }
        .logs-table-wrapper {
            .ivu-col-span-12 {
                max-width: 100% !important;
                flex: 0 0 100%;
            }
        }
    }
}
</style>
