<template>
    <div class="leaderboard-table-list-section">
        <Table :columns="tblColumns" :data="leaderboardData" width="auto" size="small" :loading="loading" />
        <!-- <Table v-else :columns="pmtblColumns" :data="tblData" width="auto" size="small" :loading="isLoading"></Table> -->
    </div>
</template>

<script>
import envelopeIcon from '@/assets/images/envelope.svg';
import notepadIcon from '@/assets/images/notepad.svg';
import checklistIcon from '@/assets/images/checklist.svg';
import cloudClockIcon from '@/assets/images/cloud-clock.svg';
import clockCheckIcon from '@/assets/images/clock-check.svg';
import chatIcon from '@/assets/images/chat.svg';
import thumbsUpIcon from '@/assets/images/thumbsup.svg';
import thumbsDownIcon from '@/assets/images/thumbsdown.svg';
import brainIcon from '@/assets/images/brain.svg';
import smileIcon from '@/assets/images/smile.svg';
export default {
    props: {
        leaderboardData: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        monthData: {
            type: Date,
            default: false,
        },
        boardtype: {
            type: String,
            default: '',
        },
        project: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            userRole: localStorage.getItem('mainRole'),
            currentSort: { key: 'eloquencyRate', type: 'desc', __id: '' },
            sortTypes: ['normal', 'desc', 'asc'],
            tblColumns: [
                {
                    title: 'Rank',
                    key: 'rank',
                    type: 'index',
                    minWidth: 80,
                    maxWidth: 100,
                },
                {
                    title: 'Name',
                    key: 'name',
                    minWidth: 200,
                    render: (h, params) => {
                        if (this.boardtype === 'allEmp') {
                            return h('div', [
                                h('strong', params.row.name),
                                h('span', this.mainRole?.find((x) => x.value === params.row?.mainRole)?.name),
                            ]);
                        } else if (this.boardtype === 'byProj') {
                            return h('div', [
                                h('strong', params.row.name),
                                h('span', this.projectRole?.find((x) => x.value === params.row?.projectRole)?.name),
                            ]);
                        } else {
                            return h('div', [h('strong', params.row.name)]);
                        }
                    },
                },
                {
                    title: 'ER',
                    key: 'eloquencyRate',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Eloquency Rate',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'ER'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id} on`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'ios-mail',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: envelopeIcon,
                                    class: 'custom-icon',
                                    width: 20,
                                },
                            }),
                            h('strong', params.row.eloquencyRate),
                        ]);
                    },
                },
                {
                    title: 'OR',
                    key: 'orderlyRate',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Orderly Rate',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'OR'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'ios-list-box',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: notepadIcon,
                                    class: 'custom-icon',
                                    width: 15,
                                },
                            }),
                            h('strong', params.row.orderlyRate),
                        ]);
                    },
                },
                {
                    title: 'DR',
                    key: 'getItDoneRate',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Done Rate',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'DR'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'md-checkmark-circle-outline',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: checklistIcon,
                                    class: 'custom-icon',
                                    width: 18,
                                },
                            }),
                            h('strong', params.row.getItDoneRate),
                        ]);
                    },
                },
                {
                    title: 'FH',
                    key: 'forecastedHours',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Forecasted Hours',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'FH'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'ios-time',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: cloudClockIcon,
                                    class: 'custom-icon',
                                    width: 22,
                                },
                            }),
                            h('strong', params.row.forecastedHours),
                        ]);
                    },
                },
                {
                    title: 'CH',
                    key: 'completedHours',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Completed Hours',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'CH'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'ios-time',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: clockCheckIcon,
                                    class: 'custom-icon',
                                    width: 18,
                                },
                            }),
                            h('strong', params.row.completedHours),
                        ]);
                    },
                },
                {
                    title: 'CR',
                    key: 'chattyRate',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Chatty Rate',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'CR'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'ios-happy',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: smileIcon,
                                    class: 'custom-icon',
                                    width: 17,
                                },
                            }),
                            h('strong', params.row.chattyRate),
                        ]);
                    },
                },
                {
                    title: 'DSU',
                    key: 'dailyStandUp',
                    width: 80,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Daily Stand Up',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'DSU'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'ios-time',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: chatIcon,
                                    class: 'custom-icon',
                                    width: 16,
                                },
                            }),
                            h('strong', params.row.dailyStandUp),
                        ]);
                    },
                },
                {
                    title: 'GR',
                    key: 'geekyRate',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Geeky Rate',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'GR'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-git-branch',
                                        size: '20',
                                    },
                                },
                                'view'
                            ),
                            h('strong', params.row.geekyRate),
                        ]);
                    },
                },
                {
                    title: 'TU',
                    key: 'thumbsUp',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Thumbs Up',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'TU'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'md-thumbs-up',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: thumbsUpIcon,
                                    class: 'custom-icon',
                                    width: 18,
                                },
                            }),
                            h('strong', params.row.thumbsUp),
                        ]);
                    },
                },
                {
                    title: 'TD',
                    key: 'thumbsDown',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Thumbs Down',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'TD'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'md-thumbs-down',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: thumbsDownIcon,
                                    class: 'custom-icon',
                                    width: 18,
                                },
                            }),
                            h('strong', params.row.thumbsDown),
                        ]);
                    },
                },
                {
                    title: 'SE',
                    key: 'sweatEquity',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Sweat Equity',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'SE'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-water',
                                        size: '20',
                                    },
                                },
                                'view'
                            ),
                            h('strong', parseInt(params.row.sweatEquity)),
                        ]);
                    },
                },
                {
                    title: 'BE',
                    key: 'brainEquityRate',
                    width: 75,
                    renderHeader: (h, params) => {
                        return h('div', { class: `custom-sort-wrapper custom-sort-wrapper-${params.column.__id}` }, [
                            h(
                                'div',
                                {
                                    class: 'tooltip-wrapper',
                                },
                                [
                                    h(
                                        'Tooltip',
                                        {
                                            props: {
                                                content: 'Brain Equity',
                                                transfer: true,
                                                theme: 'light',
                                                placement: 'top',
                                            },
                                        },
                                        [
                                            h(
                                                'span',
                                                {
                                                    class: 'ivu-table-cell-sort',
                                                    on: {
                                                        click: () => {
                                                            this.toggleSort(params.column.key, params.column.__id);
                                                        },
                                                    },
                                                },
                                                'BE'
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            h(
                                'span',
                                {
                                    class: 'ivu-table-sort',
                                },
                                [
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropup asc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'asc', params.column.__id);
                                                    this.updatSortUI('asc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                    h(
                                        'i',
                                        {
                                            class: `ivu-icon ivu-icon-md-arrow-dropdown desc-${params.column.__id}`,
                                            on: {
                                                click: () => {
                                                    this.sortData(params.column.key, 'desc', params.column.__id);
                                                    this.updatSortUI('desc', params.column.__id);
                                                },
                                            },
                                        },
                                        ''
                                    ),
                                ]
                            ),
                        ]);
                    },
                    render: (h, params) => {
                        return h('div', [
                            // h(
                            //     'Icon',
                            //     {
                            //         props: {
                            //             type: 'md-water',
                            //             size: '20',
                            //         },
                            //     },
                            //     'view'
                            // ),
                            h('img', {
                                attrs: {
                                    src: brainIcon,
                                    class: 'custom-icon',
                                    width: 20,
                                },
                            }),
                            h('strong', parseInt(params.row.brainEquityRate)),
                        ]);
                    },
                },
                {
                    title: 'Action',
                    minWidth: 80,
                    maxWidth: 120,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-eye',
                                        size: '28',
                                    },
                                    on: {
                                        click: () => {
                                            if (this.boardtype === 'allEmp') {
                                                this.$router.push({
                                                    name: 'WeeklyEmailTasksMonitoring',
                                                    query: { id: params.row._id },
                                                    params: {
                                                        month: this.monthData,
                                                        userData: params.row,
                                                        boardtype: this.boardtype,
                                                    },
                                                });
                                            } else if (this.boardtype === 'byProj') {
                                                this.$router.push({
                                                    name: 'WeeklyEmailTasksMonitoring',
                                                    query: { id: params.row._id, project: this.project },
                                                    params: {
                                                        month: this.monthData,
                                                        userData: params.row,
                                                        boardtype: this.boardtype,
                                                    },
                                                });
                                            } else {
                                                this.$router.push({
                                                    name: 'WeeklyEmailTasksMonitoring',
                                                    query: { project: params.row._id },
                                                    params: {
                                                        month: this.monthData,
                                                        userData: params.row,
                                                        boardtype: this.boardtype,
                                                    },
                                                });
                                            }
                                        },
                                    },
                                },
                                'view'
                            ),
                        ]);
                    },
                },
                {
                    title: 'Reward',
                    minWidth: 120,
                    maxWidth: 150,
                    align: 'center',
                    render: (h) => {
                        return h('div', [
                            h(
                                'Button',
                                {
                                    props: {
                                        size: 'default',
                                        type: 'default',
                                    },
                                    class: 'pt-btn-2',
                                },
                                'Reward'
                            ),
                        ]);
                    },
                },
            ],
        };
    },
    methods: {
        async toggleSort(key, __id) {
            let type = this.currentSort.type;

            // update sort type. toggle through sort types by incrementing index +1
            let typeIdx = this.sortTypes.indexOf(type);
            typeIdx = typeIdx + 1 > this.sortTypes.length - 1 ? 0 : typeIdx + 1;
            type = this.sortTypes[typeIdx];

            // when swicthing sort key, set type to default 'desc'
            if (this.currentSort.key !== key) type = 'desc';

            // Sort table data
            this.sortData(key, type, __id);

            // UI Updates
            this.updatSortUI(type, __id);
        },

        async sortData(key, type, __id) {
            const typeIdx = this.sortTypes.indexOf(type);
            if (!typeIdx) {
                this.leaderboardData.sort((a, b) => b['eloquencyRate'] - a['eloquencyRate']);
            } else {
                this.leaderboardData.sort((a, b) => (typeIdx % 2 ? b[key] - a[key] : a[key] - b[key]));
            }

            // Update current sort key type and id
            this.currentSort = { key, type, __id };
        },

        async updatSortUI(type, id) {
            const domArrows = document.querySelectorAll('span.ivu-table-sort i.ivu-icon');
            const arrArrows = [...domArrows];
            if (arrArrows.length) arrArrows.forEach((arrow) => arrow.classList.remove('on'));

            const domCurArrow = document.querySelector(`span.ivu-table-sort i.${type}-${id}`);
            if (domCurArrow) domCurArrow.classList.add('on');
        },
    },
};
</script>

<style lang="scss">
.leaderboard-table-list-section {
    table {
        border-collapse: collapse;
    }
    .custom-sort-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .ivu-table-sort {
            margin-top: -0.3em;
        }
    }
    .ivu-table {
        th {
            background: #fff !important;

            .ivu-table-cell {
                color: #463c6e;
                display: inline-flex;

                // .tooltip-wrapper {
                //     position: absolute;
                // }
                // .ivu-table-sort {
                //     width: 30px;
                //     height: 15px;

                //     display: flex;
                //     justify-content: end;

                //     i {
                //         height: 10px;
                //         width: 50px;
                //         right: -18px;
                //     }
                //     i:first-child {
                //         top: 2px;
                //     }
                //     i:last-child {
                //         bottom: -2px;
                //     }
                // }
            }
        }
        th:first-child {
            text-align: center;
        }
        .ivu-table-body {
            table {
                border-collapse: separate;
                border-spacing: 0 5px;

                tbody {
                    .ivu-table-row:first-child td {
                        background: #6b5ca7;
                        color: #fff;
                        border-color: #6b5ca7 !important;

                        img.custom-icon {
                            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(134deg) brightness(106%)
                                contrast(102%);
                        }
                    }
                    .ivu-table-row:nth-child(2) td {
                        background: #766d9d;
                        color: #fff;
                        border-color: #766d9d !important;

                        img.custom-icon {
                            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(134deg) brightness(106%)
                                contrast(102%);
                        }
                    }
                    .ivu-table-row:nth-child(3) td {
                        background: #211841;
                        color: #fff;
                        border-color: #211841 !important;

                        img.custom-icon {
                            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(134deg) brightness(106%)
                                contrast(102%);
                        }
                    }
                    .ivu-table-row {
                        border-radius: 25px;

                        td:first-child {
                            border-radius: 10px 0 0 10px;
                            border-color: #211841;
                            border-style: solid;
                            border-width: 1px 0 1px 1px;

                            .ivu-table-cell {
                                font-size: 30px;
                                font-weight: 600;
                                text-align: center;
                            }
                        }
                        td:last-child {
                            border-radius: 0 10px 10px 0;
                            border-color: #211841;
                            border-style: solid;
                            border-width: 1px 1px 1px 0;

                            button {
                                background: #e9495d;
                                border: solid 1px #e9495d !important;
                            }

                            button:hover {
                                background: #fff;
                                color: #e9495d;
                            }
                        }
                        td:nth-child(2) {
                            .ivu-table-cell > div {
                                text-align: left !important;

                                strong {
                                    text-transform: uppercase;
                                }
                            }
                        }
                        td:nth-child(15) {
                            .ivu-icon {
                                cursor: pointer;
                            }
                        }
                        td {
                            border-color: #211841;
                            border-style: solid;
                            border-width: 1px 0 1px 0;
                            height: 60px;
                            color: #463c6e;

                            .ivu-table-cell > div {
                                display: inline-grid;
                                text-align: center;
                                justify-content: center;

                                img.custom-icon {
                                    //width: 20px;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
