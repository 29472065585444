<template>
    <Modal
        v-model="showModal"
        draggable
        sticky
        title=" "
        footer-hide
        :before-close="handleClose"
        width="900"
        class-name="monitorList"
    >
        <div slot="header" style="color: #fff; text-align: left">Edit Team Member Monitoring List</div>
        <Row :gutter="50" class="md-margin-bottom">
            <Col :xs="24" :md="6" class="md-margin-bottom">
                <InfoCard
                    message="Slack"
                    :status="monitoringTools.monitoring_slack"
                    @click.native="monitoringTools.monitoring_slack = !monitoringTools.monitoring_slack"
                />
            </Col>
            <Col :xs="24" :md="6">
                <InfoCard
                    message="PM tool"
                    :status="monitoringTools.monitoring_pmTool"
                    @click.native="monitoringTools.monitoring_pmTool = !monitoringTools.monitoring_pmTool"
                />
            </Col>
            <Col :xs="24" :md="6">
                <InfoCard
                    message="Git"
                    :status="monitoringTools.monitoring_git"
                    @click.native="monitoringTools.monitoring_git = !monitoringTools.monitoring_git"
                />
            </Col>
            <Col :xs="24" :md="6">
                <InfoCard
                    message="Salarium"
                    :status="monitoringTools.monitoring_salarium"
                    @click.native="monitoringTools.monitoring_salarium = !monitoringTools.monitoring_salarium"
                />
            </Col>
            <Col :xs="24" :md="6">
                <InfoCard
                    message="Last Fri"
                    :status="monitoringTools.monitoring_lastFriEmail"
                    @click.native="monitoringTools.monitoring_lastFriEmail = !monitoringTools.monitoring_lastFriEmail"
                />
            </Col>
            <Col :xs="24" :md="6">
                <InfoCard
                    message="Monday"
                    :status="monitoringTools.monitoring_monEmail"
                    @click.native="monitoringTools.monitoring_monEmail = !monitoringTools.monitoring_monEmail"
                />
            </Col>
            <Col :xs="24" :md="6">
                <InfoCard
                    message="Friday"
                    :status="monitoringTools.monitoring_friEmail"
                    @click.native="monitoringTools.monitoring_friEmail = !monitoringTools.monitoring_friEmail"
                />
            </Col>
        </Row>
        <div style="display: flex; justify-content: center">
            <Button class="pt-btn-2" style="margin-right: 10px" @click="handleClose"> CANCEL </Button>
            <Button class="pt-btn-3" :loading="isLoading" @click="handleEditMonitoringTools"> SAVE </Button>
        </div>
    </Modal>
</template>

<script>
import InfoCard from '@/components/UI/InfoCard';
import { getUsersById, updateUsersById } from '@/services/users.services.js';
export default {
    components: {
        InfoCard,
    },
    data() {
        return {
            isLoading: false,
            userId: null,
            showModal: false,
            getUserData: {},
            monitoringTools: {
                monitoring_slack: null,
                monitoring_pmTool: null,
                monitoring_git: null,
                monitoring_salarium: null,
                monitoring_lastFriEmail: null,
                monitoring_monEmail: null,
                monitoring_friEmail: null,
            },
        };
    },
    methods: {
        openModal(data) {
            this.showModal = true;
            this.userId = data;
            getUsersById(this.userId).then((res) => {
                this.getUserData = res.data;
                this.monitoringTools.monitoring_slack = res.data.monitoring_slack;
                this.monitoringTools.monitoring_pmTool = res.data.monitoring_pmTool;
                this.monitoringTools.monitoring_git = res.data.monitoring_git;
                this.monitoringTools.monitoring_salarium = res.data.monitoring_salarium;
                this.monitoringTools.monitoring_lastFriEmail = res.data.monitoring_lastFriEmail;
                this.monitoringTools.monitoring_monEmail = res.data.monitoring_monEmail;
                this.monitoringTools.monitoring_friEmail = res.data.monitoring_friEmail;
            });
        },
        handleClose() {
            this.showModal = false;
        },
        async handleEditMonitoringTools() {
            this.isLoading = true;
            this.monitoringTools = {
                ...this.getUserData,
                ...this.monitoringTools,
            };
            await updateUsersById(this.userId, this.monitoringTools)
                .then(() => {
                    this.$Message.success('Sucessfully update user');
                    // this.$router.push({ name: 'Users' });
                    this.$emit('handle');
                    this.isLoading = false;
                    this.showModal = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to update user');
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style>
.monitorList .ivu-modal-body,
.monitorList .ivu-modal-confirm {
    padding: 16px !important;
}
</style>
