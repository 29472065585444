<template>
    <div class="histogram-wrapper">
        <Row type="flex" class="code-row-bg" style="margin-bottom: 20px">
            <Col span="24">
                <Spin v-if="isLoading" size="large" fix />
                <Card dis-hover class="md-margin-bottom" style="border-radius: 30px"
                    ><h3 class="header1"><strong>HISTOGRAM</strong></h3>
                    <div class="histogram-selector">
                        <div>
                            <span style="margin-right: 10px; color: #463c6e">Row Daily</span
                            ><i-switch
                                v-model="switchRowdaily"
                                size="large"
                                class="switchColor"
                                false-color="#ccc"
                                true-color="#13ce66"
                            >
                                <span slot="open">ON</span><span slot="close">OFF</span></i-switch
                            >
                        </div>
                        <div class="month-selector">
                            <span style="margin-left: 30px; color: #463c6e">Month</span
                            ><span style="margin-left: 10px"
                                ><Select
                                    v-model="selectedMonth"
                                    filterable
                                    clearable
                                    :loading="isLoading"
                                    class="md-margin-right"
                                    style="max-width: 300px"
                                    @on-change="handleSelectMonthChange($route.query.id)"
                                >
                                    <Option value="">All</Option>
                                    <Option v-for="item in months" :key="item.month" :value="item.month"
                                        >{{ item.month }}
                                    </Option>
                                </Select></span
                            >
                        </div>
                    </div>

                    <div
                        v-for="item in usersHistogramLogs"
                        :key="item._id.userId"
                        :value="item._id.userId"
                        :loading="isLoading"
                        class="team-list"
                    >
                        <Row class="code-row-bg" style="margin-top: 20px">
                            <Col span="4">
                                <p>{{ item._id.name }}</p>
                            </Col>
                            <!-- use item.dataSetsDaily8 | dataSetsRowDaily  to switch between row daily on or off-->
                            <Col v-if="switchRowdaily === false" span="20">
                                <TrackerBarGraph
                                    id="trackerBarGraphEmail"
                                    style="height: 300px; position: relative"
                                    :chartdata-sets="item.dataSetsDaily8"
                                    :chartdata-labels="item.labels"
                                    :loading="isLoading"
                                />
                            </Col>
                            <Col v-else span="20">
                                <TrackerBarGraph
                                    id="trackerBarGraphEmail"
                                    style="height: 300px; position: relative"
                                    :chartdata-sets="item.dataSetsRowDaily"
                                    :chartdata-labels="item.labels"
                                    :loading="isLoading"
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
    </div>
</template>
<script>
import moment from 'moment';
import lodash from 'lodash';
import TrackerBarGraph from '@/components/Graphs/Histogram.js';
import { getProjectMonitoringHistogram } from '@/services/projects.services';

import { generateDaysForLabels } from '../../../utility/helper';

export default {
    components: {
        TrackerBarGraph,
    },

    data() {
        return {
            isLoading: false,
            chartData: [],
            chartDataLabels: [],
            chartDataMaxTicks: [],
            usersHistogramLogs: [],
            switchRowdaily: false,
            selectedMonth: null,
            months: [],
        };
    },

    mounted() {
        this.handleGetProjectHistogramData(this.$route.query.id);
    },

    methods: {
        async handleGetProjectHistogramData(id) {
            this.isLoading = true;
            const month = this.selectedMonth;
            // apply month parameters. default all(last three months)
            const { data } = await getProjectMonitoringHistogram(id, month);

            // process labels and datasets for the graph from histogram api response
            const usersHistogramLogs =
                data &&
                data.length &&
                data.map((userHistogram) => {
                    // create labels for graph on each user
                    const labels = lodash.flatten(
                        userHistogram.timelog.map((timelog) => {
                            const { yearMonthUTC } = timelog;

                            const yearMonth = yearMonthUTC.split('-');

                            const days = generateDaysForLabels(parseInt(yearMonth[0]), parseInt(yearMonth[1]));

                            return days;
                        })
                    );

                    // create data sets for each month for daily8
                    const dataSetsDaily8 = userHistogram.timelog.map((timelog) => {
                        // create default data array of null for the datasets
                        const defaultDataDaily8 = new Array(labels.length).fill(null);

                        // array of daily logs within month
                        // generate the data sets array for each month
                        timelog.timelog.map((dailyTimeLog) => {
                            const date = moment(dailyTimeLog.startTime).format('MM/DD');
                            const dateIdx = labels.indexOf(date);
                            defaultDataDaily8[dateIdx] = dailyTimeLog.daily8;
                        });

                        return {
                            align: 'start',
                            label: moment(timelog.yearMonthUTC.split('-')[1], 'MM').format('MMMM'),
                            skipNull: true,
                            backgroundColor: '#463c6e',
                            data: defaultDataDaily8,
                        };
                    });

                    // create data sets for each month for rowDaily
                    const dataSetsRowDaily = userHistogram.timelog.map((timelog) => {
                        // create default data array of null for the datasets
                        const defaultDataRowDaily = new Array(labels.length).fill(null);

                        // array of daily logs within month
                        // generate the data sets array for each month
                        timelog.timelog.map((dailyTimeLog) => {
                            const date = moment(dailyTimeLog.startTime).format('MM/DD');
                            const dateIdx = labels.indexOf(date);
                            defaultDataRowDaily[dateIdx] = dailyTimeLog.rowDaily;
                        });

                        return {
                            align: 'start',
                            label: moment(timelog.yearMonthUTC.split('-')[1], 'MM').format('MMMM'),
                            skipNull: true,
                            backgroundColor: '#463c6e',
                            data: defaultDataRowDaily,
                        };
                    });

                    const dataMonthly = userHistogram.timelog.map((timelog) => {
                        return {
                            label: timelog.yearMonthUTC,
                        };
                    });

                    //get months to display in selection
                    this.months = userHistogram.timelog.length;
                    if (dataMonthly.length >= 3) {
                        const labelMonths = dataMonthly.map((labels) => {
                            return {
                                month: labels.label,
                            };
                        });
                        this.months = labelMonths;
                    } else {
                        const labelMonths = dataMonthly.map((labels) => {
                            return {
                                month: labels.label,
                            };
                        });
                        this.months = labelMonths;
                    }

                    return {
                        _id: userHistogram._id,
                        labels,
                        dataSetsDaily8: dataSetsDaily8,
                        dataSetsRowDaily: dataSetsRowDaily,
                    };
                });
            this.usersHistogramLogs = usersHistogramLogs;
            this.isLoading = false;
        },

        handleSelectMonthChange(val) {
            if (val) {
                this.handleGetProjectHistogramData(val);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
p {
    color: $primary-color;
}
.histogram-selector {
    display: flex;
    align-items: center;

    .month-selector {
        display: flex;
        align-items: center;
    }
}
@media screen and (max-width: 767px) {
    .histogram-wrapper {
        .histogram-selector {
            display: block;
            .month-selector {
                margin-top: 10px;

                span:first-child {
                    margin-left: 0 !important;
                }
            }
        }
    }
}
</style>
