<template>
    <div class="project-team-members-section">
        <!-- <Card class="section-title"> TEAM MEMBERS<Icon class="add-icon" type="md-add" @click="handleModal" /> </Card> -->
        <Row>
            <Col span="12">
                <h2>TEAM MEMBERS</h2>
            </Col>
            <Col span="12" style="justify-content: end; display: flex">
                <Button class="pt-btn-1" @click="handleModal">Add</Button>
            </Col>
        </Row>
        <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="md-margin-top">
            <template slot="projectRole" slot-scope="{ row }">
                <Select
                    v-model="row.projectRole"
                    @on-change="onChangeProjectRole(row._index, row.projectRole, row._id)"
                >
                    <Option
                        v-for="(role, idx) in projectMemberRoles.filter((x) => x != 'client')"
                        :key="idx"
                        :value="role"
                    >
                        {{ role }}
                    </Option>
                </Select>
            </template>
        </Table>
        <!-- <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading"></Table> -->
        <AddProjectTeamMember ref="AddProjectTeamMember" @addPT="handleGetProjectMembersByProjectId" />
    </div>
</template>

<script>
import {
    getProjectMembersByProjectId,
    updateProjectMember,
    deleteProjectMember,
    getProjectMembersRole,
} from '@/services/project-members.services';
import AddProjectTeamMember from './AddProjectTeamMember';
export default {
    components: {
        AddProjectTeamMember,
    },
    props: {
        projectId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            projectMemberRoles: [],
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    minWidth: 200,
                    render: (h, params) => {
                        return h('span', params.row.user.name);
                    },
                },
                {
                    title: 'Project Role',
                    slot: 'projectRole',
                    minWidth: 180,
                },
                // {
                //   title: 'Role',
                //   key: 'projectRole',
                //   render: (h,params) => {
                //     if(params.row.projectRole) {
                //       const role = this.projectRole.find(x => x.value === params.row.projectRole).name
                //       return h('span', role);
                //     } else {
                //       return h('span', '');
                //     }
                //   }
                // },
                {
                    title: 'Actions',
                    align: 'center',
                    width: 120,
                    render: (h, params) => {
                        return h('div', [
                            h('i-switch', {
                                props: {
                                    value: params.row.isProjectMemberActive,
                                    size: 'small',
                                    trueValue: false,
                                    falseValue: true,
                                    trueColor: '#CFCFCF',
                                    falseColor: '#44E53E',
                                },
                                on: {
                                    'on-change': (val) => {
                                        this.handleUpdateProjectMember(params.row._id, val);
                                    },
                                },
                            }),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-close-circle',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    on: {
                                        click: () => {
                                            this.handleRemoveProjectMember(params.row._id);
                                        },
                                    },
                                },
                                'edit'
                            ),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    mounted() {
        this.handleGetProjectMemberRoles();
        this.handleGetProjectMembersByProjectId();
    },

    methods: {
        handleGetProjectMembersByProjectId() {
            this.isLoading = true;
            this.tblData = [];
            getProjectMembersByProjectId(this.$route.query.id)
                .then((res) => {
                    if (res.data.length) {
                        this.tblData = res.data.filter((x) => x.projectRole !== 'client' && x.user.isArchived != true);
                    }
                    this.isLoading = false;
                    return [];
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members');
                    this.isLoading = false;
                    return [];
                });
        },
        onChangeProjectRole(rowIdx, selectedData, user_id) {
            this.tblData[rowIdx].projectRole = selectedData;
            updateProjectMember(user_id, { projectRole: selectedData })
                .then(() => {
                    this.handleGetProjectMembersByProjectId();
                })
                .catch(() => {
                    this.$Message.error('Failed to update a project member');
                });
        },
        handleGetProjectMemberRoles() {
            getProjectMembersRole().then((res) => {
                this.projectMemberRoles = [];
                if (res.data.length) {
                    this.projectMemberRoles = res.data;
                }
            });
        },
        handleUpdateProjectMember(id, val) {
            updateProjectMember(id, { isProjectMemberActive: val })
                .then(() => {
                    this.handleGetProjectMembersByProjectId();
                })
                .catch(() => {
                    this.$Message.error('Failed to update a project member');
                });
        },

        handleModal() {
            this.$refs.AddProjectTeamMember.openModal();
        },

        handleRemoveProjectMember(id) {
            deleteProjectMember(id)
                .then(() => {
                    this.handleGetProjectMembersByProjectId();
                    this.$Message.success('Project member removed successfully');
                })
                .catch(() => {
                    this.$Message.error('Failed to remove a project member');
                });
        },
    },
};
</script>
<style>
.add-icon {
    float: right;
    margin-top: 6px;
    margin-right: 10px;
    color: #fff;
    font-size: 18px;
    background: #e9495d;
    border-radius: 50%;
    vertical-align: baseline;
}
.add-icon-form {
    color: #fff;
    font-size: 15px;
    background: #e9495d;
    border-radius: 50% !important;
    vertical-align: baseline !important;
}
.btn-color {
    background: #e9495d;
    color: #fff;
}
.project-team-members-section {
    color: #463c6e;
}

@media screen and (max-width: 767px) {
    .project-team-members-section {
        margin-bottom: 40px;
    }
}
</style>
