<template>
    <div class="project-performance-wrapper">
        <Row type="flex" justify="space-between" class="code-row-bg" style="margin-bottom: 20px">
            <Col span="6">
                <Card class="page-title no-padding" dis-hover :bordered="false"> PROJECT PERFORMANCE </Card>
            </Col>
            <Col span="6" class="text-right">
                <Button class="pt-btn-2" style="margin-right: 10px" @click="$router.go(-1)"> Cancel </Button>
            </Col>
        </Row>
        <Card class="section-title">
            PROJECT DETAILS<span v-if="mainRole" class="editBtn" @click="goToEditProject">Edit</span>
        </Card>
        <Card class="project-config-section" style="margin-bottom: 20px" dis-hover>
            <Form ref="ProjectForm" :model="formData" :label-width="120" label-position="left">
                <FormItem label="Project Name">
                    <Input v-model="formData.name" readonly />
                </FormItem>
                <FormItem label="Project Type">
                    <RadioGroup v-model="formData.projectType">
                        <Radio v-for="(type, idx) in projectTypes" :key="idx" :label="type.value" disabled>
                            {{ type.name }}
                        </Radio>
                    </RadioGroup>
                </FormItem>
                <Row :gutter="20">
                    <i-col>
                        <FormItem label="Start Date">
                            <DatePicker v-model="formData.startDate" format="yyyy/MM/dd" readonly />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="End Date">
                            <DatePicker v-model="formData.endDate" format="yyyy/MM/dd" readonly />
                        </FormItem>
                    </i-col>
                </Row>
                <Row :gutter="10">
                    <i-col>
                        <FormItem label="Total Hours">
                            <InputNumber v-model="formData.totalProjectHours" controls-outside readonly />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="UI/UX" :label-width="50">
                            <InputNumber v-model="formData.totalUIUXHours" controls-outside readonly />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Frontend" :label-width="65">
                            <InputNumber v-model="formData.totalFrontendHours" controls-outside readonly />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Backend" :label-width="65">
                            <InputNumber v-model="formData.totalBackendHours" controls-outside readonly />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="PM" :label-width="50">
                            <InputNumber v-model="formData.totalPMHours" controls-outside readonly />
                        </FormItem>
                    </i-col>
                </Row>
            </Form>
        </Card>
        <Row :gutter="20" class="project-hours-cards">
            <Col :xs="20" :sm="20" :md="8" :lg="8">
                <ProjectDeveloper />
            </Col>
            <Col :xs="20" :sm="20" :md="8" :lg="8">
                <ProjectManagers />
            </Col>
            <Col :xs="20" :sm="20" :md="8" :lg="8">
                <ProjectUIUX />
            </Col>
        </Row>
    </div>
</template>

<script>
import { getProjectsById, getProjects } from '@/services/projects.services';
import ProjectDeveloper from './ProjectDeveloper';
import ProjectManagers from './ProjectManagers';
import ProjectUIUX from './ProjectUIUX';

export default {
    components: {
        ProjectDeveloper,
        ProjectManagers,
        ProjectUIUX,
    },
    data() {
        return {
            isLoading: false,
            formData: {
                name: null,
                projectType: null,
                startDate: null,
                endDate: null,
                totalProjectHours: 0,
                totalUIUXHours: 0,
                totalFrontendHours: 0,
                totalBackendHours: 0,
                totalPMHours: 0,
            },
            mainRole: null,
        };
    },

    async mounted() {
        getProjects()
            .then((res) => {
                this.usersData = res.data;
            })
            .catch((err) => {
                console.log('Failed to fetch data', err);
            });
        this.setFormDataValues();
        this.mainRole = localStorage.getItem('mainRole') === 'admin' || localStorage.getItem('mainRole') === 'pm';
    },

    methods: {
        async setFormDataValues() {
            if (this.$route.query.id) {
                this.formData = await this.handleGetProjectsById(this.$route.query.id);
            }
        },
        async handleGetProjectsById(id) {
            return await getProjectsById(id)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                });
        },
        goToEditProject() {
            this.$router.push(`/form-project?id=${this.$route.query.id}`);
        },
    },
};
</script>
<style lang="scss">
.editBtn {
    color: #e9495d;
    font-size: 15px !important;
    float: right;
    margin: 4px 10px;
}

@media screen and (max-width: 767px) {
    .project-config-section {
        .ivu-form-item-content {
            margin-left: 0 !important;
            display: block !important;
        }
    }
    .project-hours-cards {
        .ivu-col-span-xs-20 {
            margin-bottom: 10px;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
</style>
