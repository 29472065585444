<template>
    <Sider ref="Sider" v-model="isSiderCollapsed" hide-trigger collapsible :collapsed-width="59" class="app-sider">
        <Menu width="auto" :class="menuitemClasses" :active-name="selectedMenu" @on-select="handleOnSelectMenu">
            <MenuItem name="Home">
                <Icon type="ios-home" size="27" />
                <span>Home</span>
            </MenuItem>
            <MenuItem v-if="userRole !== 'client'" name="WeeklyEmails">
                <Icon type="ios-mail" size="27" />
                <span>Weekly Email</span>
            </MenuItem>
            <MenuItem v-if="userRole !== 'client'" name="WeeklyEmailsMonitoring">
                <Icon type="ios-search-outline" size="27" />
                <span>Monitoring</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'dev' || userRole === 'pm'" name="Requests">
                <Icon type="md-document" size="27" />
                <span>Requests</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'dev' || userRole === 'pm'" name="Attendance">
                <Icon type="md-clipboard" size="27" />
                <span>Attendance</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'admin' || userRole === 'pm'" name="Timetracker">
                <Icon type="md-watch" size="27" />
                <span>Time Tracker</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'admin'" name="AdminRequests">
                <Icon type="md-document" size="27" />
                <span>Requests</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'admin'" name="AdminAttendance">
                <Icon type="md-clipboard" size="27" />
                <span>Attendance</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'admin' || userRole === 'pm'" name="Users">
                <Icon type="md-people" size="27" />
                <span>Team</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'admin' || userRole === 'pm'" name="Logs">
                <Icon type="ios-paper" size="27" />
                <span>Logs</span>
            </MenuItem>
            <MenuItem v-if="userRole !== 'client'" name="Rewards">
                <Icon type="md-trophy" size="27" />
                <span>Reward System</span>
            </MenuItem>
            <MenuItem v-if="userRole !== 'dev'" name="Leaderboard">
                <Icon type="md-trophy" size="27" />
                <span>Rewards</span>
            </MenuItem>
            <MenuItem v-if="userRole !== 'client'" name="Policies">
                <Icon type="md-list" size="27" />
                <span>Policies</span>
            </MenuItem>
            <MenuItem v-if="userRole === 'admin' || userRole === 'pm'" name="NFT">
                <Icon type="ios-cube" size="27" />
                <span>NFT</span>
            </MenuItem>
        </Menu>
    </Sider>
</template>

<script>
import Bugsnag from '@bugsnag/js';
export default {
    props: {
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        menuitemClasses: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            userRole: localStorage.getItem('mainRole'),
            selectedMenu: 'Projects',
        };
    },
    computed: {
        isSiderCollapsed: {
            get() {
                return this.isCollapsed;
            },
            set(val) {
                this.$emit('collapsed', val);
            },
        },
    },
    methods: {
        toggleCollapse() {
            this.$refs['Sider'].toggleCollapse();
        },
        handleOnSelectMenu(name) {
            try {
                if (name === 'Home') {
                    switch (this.userRole) {
                        case 'admin':
                            this.$router.push({ name: 'Projects' }).catch(() => {});
                            break;
                        case 'client':
                            this.$router.push({ name: 'UserDashboard' }).catch(() => {});
                            break;
                        default:
                            this.$router.push({ name: 'UserDashboard' }).catch(() => {});
                    }
                } else {
                    this.$router.push({ name: name }).catch(() => {});
                }
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
    },
};
</script>

<style lang="scss">
.app-sider {
    background: #ffffff;
    max-width: 250px !important;
    .ivu-menu {
        &::after {
            background: transparent;
        }
        .ivu-menu-item {
            display: flex;
            padding: 14px 12px;
        }
    }
}
.menu-icon {
    transition: all 0.3s;
}
.rotate-icon {
    transform: rotate(-90deg);
}
.menu-item span {
    display: inline-block;
    overflow: hidden;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width 0.2s ease 0.2s;
}
.menu-item i {
    transform: translateX(0px);
    transition: font-size 0.2s ease, transform 0.2s ease;
    vertical-align: middle;
    font-size: 16px;
}
.collapsed-menu span {
    width: 0px;
    transition: width 0.2s ease;
}
.collapsed-menu i {
    transform: translateX(5px);
    transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
    vertical-align: middle;
    font-size: 22px;
}

@media screen and (max-width: 767px) {
    .app-sider {
        width: 59px !important;

        .ivu-menu-item {
            padding: 8px !important;
        }
    }
}
</style>
