<template>
    <div class="general-todo-list">
        <Card class="section-title">
            <Row>
                <Col flex="auto">
                    {{ selectedEmailType === 'plan' ? 'TO DO' : 'DONE' }}
                </Col>
            </Row>
        </Card>
        <Table
            :columns="tblColumns"
            :data="generalList"
            height="220"
            size="small"
            :loading="isLoading"
            class="disable-selectall weekly-email-table-list"
            @on-selection-change="handleOnSelect"
        />
        <!-- <GeneralTodos :section="generalList" :selectedEmailType="selectedEmailType" class="md-margin-bottom" :selectedAssignedTodos="selectedAssignedTodos"/> -->
    </div>
</template>

<script>
// import moment from "moment"
import { getWeeklyEmailsGeneralTickets } from '@/services/weekly-emails.services';

export default {
    props: {
        project: {
            type: Object,
            default: () => {},
        },
        selectedAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
        selectedProject: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoading: false,
            generalSelections: {},
            generalList: [],
            tblColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'Title',
                    key: 'title',
                },
                {
                    title: 'Due Date',
                    // key: 'due',
                    width: 150,
                    // render: (h, params) => {
                    //   return h('div', (params.row.details.due_on) ? moment(params.row.details.due_on).format('ddd DD MMM, YYYY') : '')
                    // }
                },
                {
                    title: '# Hours',
                    // key: 'hours',
                    width: 90,
                },
            ],
        };
    },
    watch: {
        selectedProject: {
            handler(data) {
                this.handleGetGeneralProjectSections(data);
            },
            deep: true,
        },
    },
    mounted() {
        this.handleGetGeneralProjectSections(this.selectedProject);
    },

    methods: {
        handleGetGeneralProjectSections(id) {
            this.isLoading = true;
            let status = '';
            if (this.selectedEmailType == 'plan') {
                status = 'todo';
            } else {
                status = 'done';
            }

            const apiKey = this.project?.apiKey;
            getWeeklyEmailsGeneralTickets(id, status, apiKey).then((res) => {
                this.generalList = res.data;
            });
            this.isLoading = false;
        },

        handleOnSelect(data) {
            if (data) {
                this.$emit('handleGeneralSelections', data);
            } else {
                this.$emit('handleGeneralSelections', []);
            }
        },
    },
};
</script>

<style></style>
