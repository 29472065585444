<template>
    <div>
        <div v-for="card in cards" :key="card.name" class="weekly-email-todo-list">
            <Card class="section-title">
                <Row>
                    <Col flex="auto">
                        {{ selectedEmailType === 'plan' ? 'TO DO' : 'DONE' }} ({{ card.name }})
                        <span v-if="card.data.length">[{{ card.data.length }}]</span>
                    </Col>
                </Row>
            </Card>
            <Table
                v-if="selectedEmailType === 'plan'"
                :columns="tblColumns"
                :data="card.data"
                height="200"
                size="small"
                :loading="isLoading"
                class="disable-selectall"
                @on-select="handleOnSelect"
                @on-select-cancel="handleOnSelect"
            />
            <Table
                v-else
                :columns="tblDoneColumns"
                :data="card.data"
                height="200"
                size="small"
                :loading="isLoading"
                class="disable-selectall"
                @on-select="handleOnSelect"
                @on-select-cancel="handleOnSelect"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getTrelloCardsListById } from '@/services/trello.services';

export default {
    props: {
        // list: {
        //     type: Object,
        //     default: () => {},
        // },
        list: {
            type: Array,
            default: () => [],
        },
        selectedTrelloAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedProject: {
            type: String,
            default: '',
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
    },

    data() {
        return {
            isLoading: false,
            trelloTaskLength: null,
            currentUser: localStorage.getItem('user_name'),
            cards: [
                {
                    name: 'ASSIGNED TO ME',
                    data: [],
                },
                {
                    name: 'OTHERS',
                    data: [],
                },
            ],
            tblColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'Card Title',
                    minWidth: 200,
                    key: 'card_name',
                },
                {
                    title: 'Due Date',
                    // key: 'due',
                    width: 300,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.trelloCardDetails.due != null
                                ? moment(params.row.trelloCardDetails.due).format('ddd DD MMM, YYYY')
                                : ''
                        );
                    },
                },
            ],
            tblDoneColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'Card Title',
                    key: 'card_name',
                    minWidth: 200,
                },
                {
                    title: 'Due Date',
                    width: 250,
                    // key: 'due',
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.trelloCardDetails.due != null
                                ? moment(params.row.trelloCardDetails.due).format('ddd DD MMM, YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Done',
                    width: 250,
                    render: (h, params) => {
                        const boardName = params.row.trelloListDetails
                            ? params.row.trelloListDetails.name.toLowerCase()
                            : '';
                        return h(
                            'div',
                            boardName.includes('Done'.toLowerCase()) && params.row.updatedAt
                                ? moment(params.row.updatedAt).format('ddd DD MMM, YYYY')
                                : ''
                        );
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        list: {
            handler(data) {
                this.handleGetTrelloCardListByBoard(data.id);
            },
            deep: true,
        },
    },

    mounted() {
        this.handleGetTrelloCardListByBoard(this.list.id);
    },

    methods: {
        handleGetTrelloTasksList(projId, list) {
            const boardName = list.name.toLowerCase();
            if (boardName.includes('Done'.toLowerCase())) {
                let dateTime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(10, 'days');
                return getTrelloCardsListById(
                    projId,
                    {
                        'trelloListDetails.id': list.id,
                        updatedAt: { $gte: dateTime },
                    },
                    1,
                    1000
                )
                    .then((res) => {
                        const temp = res.data.data
                            .filter((data) => data.trelloCardDetails && data.trelloCardDetails.closed == false)
                            .map((i) => {
                                const boardName = i.trelloListDetails.name.toLowerCase();
                                return {
                                    ...i,
                                    due: i.trelloCardDetails.due ? i.trelloCardDetails.due : '',
                                    done: boardName.includes('Done'.toLowerCase()) && i.updatedAt,
                                };
                            });
                        this.trelloTaskLength = temp.length;
                        return temp;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to get boards list');
                    });
            } else {
                return getTrelloCardsListById(projId, { 'trelloListDetails.id': list.id }, 1, 1000)
                    .then((res) => {
                        const temp = res.data.data
                            .filter((data) => data.trelloCardDetails && data.trelloCardDetails.closed == false)
                            .map((i) => {
                                const boardName = i.trelloListDetails.name.toLowerCase();
                                return {
                                    ...i,
                                    due: i.trelloCardDetails.due ? i.trelloCardDetails.due : '',
                                    done: boardName.includes('Done'.toLowerCase()) && i.updatedAt,
                                };
                            });
                        this.trelloTaskLength = temp.length;
                        return temp;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to get boards list');
                    });
            }
        },
        async handleGetTrelloCardListByBoard() {
            this.isLoading = true;
            this.tblData = [];
            let promises = [];

            await this.list.forEach((list) => {
                promises.push(this.handleGetTrelloTasksList(this.selectedProject, list));
            });

            Promise.all(promises).then((data) => {
                data.forEach((items) => {
                    items.forEach((item) => {
                        this.tblData.push(item);
                    });
                });

                this.tblData.forEach((item) => {
                    if (item.details.entities.memberCreator.username === localStorage.getItem('trelloId')) {
                        item.card = 'currentUser';
                        this.cards[0].data.push(item);
                    } else {
                        item.card = 'otherUser';
                        this.cards[1].data.push(item);
                    }
                });

                this.isLoading = false;
            });
        },
        // handleOnSelect(data) {
        //     if (data) {
        //         this.$emit('handleTrelloSelections', this.list.id, data);
        //     } else {
        //         this.$emit('handleTrelloSelections', []);
        //     }
        // },

        handleOnSelect(data, row) {
            if (data) {
                this.$emit('handleTrelloSelections', row.card, data);
            } else {
                this.$emit('handleTrelloSelections', row.card, []);
            }
        },
    },
};
</script>

<style lang="scss">
.weekly-email-todo-list {
    margin-bottom: 30px;
}
</style>
