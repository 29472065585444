<template>
    <div>
        <Modal
            v-model="showModal"
            :title="title"
            footer-hide
            class="text-center base-modal-confirmation"
            :closable="false"
        >
            <p>{{ message }}</p>
            <div style="margin-top: 20px">
                <Button
                    v-if="showCancel"
                    class="pt-btn-2"
                    style="width: 100px; margin-right: 10px"
                    @click="handleCancel"
                >
                    CANCEL
                </Button>
                <Button class="pt-btn-3" style="width: 100px" @click="handleConfirm"> OK </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default: 'Are you sure?',
        },
        title: {
            type: String,
            default: 'Confirmation',
        },
        showCancel: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showModal: false,
            selectedId: null,
        };
    },
    methods: {
        openModal(id) {
            this.selectedId = id;
            this.showModal = true;
        },
        handleCancel() {
            this.selectedId = null;
            this.$emit('cancel');
            this.showModal = false;
        },
        handleConfirm() {
            this.$emit('confirm', this.selectedId);
            this.showModal = false;
        },
    },
};
</script>

<style lang="scss">
.base-modal-confirmation {
    .ivu-modal-content {
        overflow: hidden;
        .ivu-modal-header {
            background: $primary-color;
            .ivu-modal-header-inner {
                color: #ffffff;
            }
        }
        .ivu-modal-body {
            padding: 40px;
        }
    }
}
</style>
