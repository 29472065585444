<template>
    <Modal
        v-model="showModal"
        title="Are you sure you want to generate a new API Key?"
        footer-hide
        class="salarium-reminder-modal-wrapper"
        :closable="false"
        :mask-closable="false"
    >
        <div>
            <p>Hello Team Member!</p>
            <br />

            <p style="text-align: justify">
                <span style="color: red">Note:</span> If you wish you to generate a new API Key, you should be aware
                that the tasks under this project, will also be removed. You should copy and paste the new generated API
                Key to your platform, to be able to display again the tasks. So please make sure you understand this
                before generating a new API Key. Thank you!
            </p>
            <br />

            <Row class="confirmation-actions md-margin-top" :gutter="10">
                <Col :xs="24" :sm="12">
                    <Button long @click="handleCancel()"> NO </Button>
                </Col>
                <Col :xs="24" :sm="12">
                    <Button class="pt-btn-3" long @click="handleConfirmation()"> YES </Button>
                </Col>
            </Row>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },

    methods: {
        openModal() {
            this.showModal = true;
        },
        handleConfirmation() {
            this.$emit('confirm');
            this.showModal = false;
        },
        handleCancel() {
            this.showModal = false;
        },
    },
};
</script>

<style>
.salarium-reminder-modal-wrapper.v-transfer-dom .ivu-modal-body,
.ivu-modal-confirm {
    padding: 16px;
}
</style>
