<template>
    <div>
        <div v-for="card in cards" :key="card.name" class="asana-todos">
            <Card class="section-title">
                <Row>
                    <Col v-if="mainRole" span="23">
                        {{ selectedEmailType === 'plan' ? 'TO DO' : 'DONE' }} ({{ card.name }})
                        <span v-if="card.data.length">[{{ card.data.length }}]</span>
                    </Col>
                    <Col v-else flex="auto">
                        {{ selectedEmailType === 'plan' ? 'TO DO' : 'DONE' }} ({{ card.name }})
                        <span v-if="card.data.length">[{{ card.data.length }}]</span>
                    </Col>
                    <!-- <Col v-if="mainRole" span="1" style="margin-top: -3px">
                        <Icon type="md-sync" @click="handleSyncTasks" />
                    </Col> -->
                </Row>
            </Card>
            <Table
                v-if="selectedEmailType === 'plan'"
                :columns="tblColumns"
                :data="card.data"
                height="220"
                size="small"
                :loading="isLoading"
                class="disable-selectall weekly-email-table-list"
                @on-select="handleOnSelect"
                @on-select-cancel="handleOnSelect"
            />
            <Table
                v-else
                :columns="tblDoneColumns"
                :data="card.data"
                height="220"
                size="small"
                :loading="isLoading"
                class="disable-selectall weekly-email-table-list"
                @on-select="handleOnSelect"
                @on-select-cancel="handleOnSelect"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getPaginatedAsanaTasks, syncTasks, getAsanaProjectSections } from '@/services/asana.services';
import { getProjectsById } from '@/services/projects.services';

export default {
    props: {
        // section: {
        //     type: Object,
        //     default: () => {},
        // },
        section: {
            type: Array,
            default: () => [],
        },
        selectedAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedProject: {
            type: String,
            default: '',
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
        project: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            isLoading: false,
            selectedAsanaID: null,
            page: 1,
            list: null,
            tasksLength: null,
            asanaProjectSections: [],
            gidSection: null,
            tblDataCurrentUser: [],
            mainRole: localStorage.getItem('mainRole') === 'admin' || localStorage.getItem('mainRole') === 'pm',
            currentUser: localStorage.getItem('user_name'),
            cards: [
                {
                    name: 'ASSIGNED TO ME',
                    data: [],
                },
                {
                    name: 'OTHERS',
                    data: [],
                },
            ],
            tblColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'Task Title',
                    minWidth: 200,
                    key: 'name',
                },
                {
                    title: 'Due Date',
                    key: 'due',
                    width: 300,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.details.due_on
                                ? moment(params.row.details.due_on).format('ddd DD MMM, YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Hours #',
                    key: 'hours',
                    width: 90,
                },
            ],
            tblDoneColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'Task Title',
                    minWidth: 200,
                    key: 'name',
                },
                {
                    title: 'Due Date',
                    key: 'due',
                    width: 150,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.details.due_on
                                ? moment(params.row.details.due_on).format('ddd DD MMM, YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Hours #',
                    key: 'hours',
                    width: 90,
                },
                {
                    title: 'On-going',
                    key: 'ongoing',
                    width: 150,
                    render: (h, params) => {
                        let valueData = '';
                        if (params.row.sectionDetails) {
                            Object.entries(params.row.sectionDetails).forEach(([key, value]) => {
                                const sectionId = key;
                                const sectionName = key.toLowerCase();
                                if (sectionId.includes(this.gidSection)) {
                                    valueData = value;
                                } else if (sectionName.includes('Going'.toLowerCase())) {
                                    valueData = value;
                                }
                            });
                            return h('div', valueData ? moment(valueData).format('ddd DD MMM, YYYY') : '');
                        }
                    },
                },
                {
                    title: 'Done',
                    key: 'done',
                    width: 150,
                    render: (h, params) => {
                        let valueData = '';
                        if (params.row.sectionDetails) {
                            Object.entries(params.row.sectionDetails).forEach(([key, value]) => {
                                const sectionId = key;
                                const sectionName = key.toLowerCase();
                                if (sectionId.includes(this.section.gid)) {
                                    valueData = value;
                                } else if (sectionName.includes('Done'.toLowerCase())) {
                                    valueData = value;
                                }
                            });
                            return h('div', valueData ? moment(valueData).format('ddd DD MMM, YYYY') : '');
                        }
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        section: {
            handler(data) {
                this.handleGetAsanaSectionTasks(data.gid);
            },
            deep: true,
        },
    },

    mounted() {
        this.handleGetAsanaSectionTasks();
        if (this.project && Object.keys(this.project).length && this.project.asana.length) {
            this.handleGetAsanaProjectSections(this.project.asana[0].id);
        }
    },

    methods: {
        async handleGetAsanaID(id) {
            return await getProjectsById(id)
                .then((res) => {
                    return res.data.asana[0].id;
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                });
        },
        async handleGetAsanaSectionTasks() {
            this.isLoading = true;
            this.tblData = [];
            let promises = [];

            // this.selectedAsanaID = await this.handleGetAsanaID(this.selectedProject)
            await this.section.forEach((sec) => {
                promises.push(this.handleGetAsanaSectionTasksList(this.selectedProject, sec));
            });

            Promise.all(promises).then((data) => {
                data.forEach((items) => {
                    items.forEach((item) => {
                        this.tblData.push(item);
                    });
                });

                this.tblData.forEach((item) => {
                    if (item.user === localStorage.getItem('user_id')) {
                        item.card = 'currentUser';
                        this.cards[0].data.push(item);
                    } else {
                        item.card = 'otherUser';
                        this.cards[1].data.push(item);
                    }
                });
            });

            this.isLoading = false;
        },

        async handleSyncTasks(asanaId) {
            this.isLoading = true;
            const secName = this.section.name.toLowerCase();
            let projectGid = this.project.asana[0].id;
            asanaId = projectGid;
            if (secName.includes('Done'.toLowerCase())) {
                let dateTime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(9, 'days');
                let completed_since = dateTime;
                let sectionGid = this.section.gid;
                return await syncTasks(asanaId, sectionGid, {
                    [sectionGid]: completed_since,
                })
                    .then(() => {
                        this.$Message.success('Successful sync of asana tasks');
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to sync of asana tasks');
                        this.isLoading = false;
                    });
            } else {
                return await syncTasks(asanaId, this.section.gid)
                    .then(() => {
                        this.$Message.success('Successful sync of asana tasks');
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to sync of asana tasks');
                        this.isLoading = false;
                    });
            }
        },

        handleGetAsanaSectionTasksList(asanaId, sec) {
            const secName = sec.name.toLowerCase();
            if (secName.includes('Done'.toLowerCase())) {
                let dateTime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(9, 'days');
                let done_since = dateTime.toDate();
                return getPaginatedAsanaTasks(
                    asanaId,
                    {
                        'section.gid': sec.gid,
                    },
                    sec.name,
                    done_since
                )
                    .then((res) => {
                        const temp = res.data.data.map((data) => {
                            const idx = data.details.custom_fields.map((data) => {
                                if (data.name == 'Hours to completion') {
                                    return data.number_value;
                                }
                            });
                            return {
                                ...data,
                                due: data.details.due_on,
                                hours: idx[0],
                                ongoing: data.sectionDetails['On Going'],
                                done: data.sectionDetails['Done'],
                            };
                        });
                        this.tasksLength = temp.length;
                        return temp;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to get asana task');
                    });
            } else {
                return getPaginatedAsanaTasks(
                    asanaId,
                    {
                        'section.gid': sec.gid,
                    },
                    sec.name
                )
                    .then((res) => {
                        const temp = res.data.data.map((data) => {
                            const idx = data.details.custom_fields.map((data) => {
                                if (data.name == 'Hours to completion') {
                                    return data.number_value;
                                }
                            });
                            return {
                                ...data,
                                due: data.details.due_on,
                                hours: idx[0],
                                // ongoing: data.details.start_on,
                                // done: data.updatedAt
                            };
                        });
                        this.tasksLength = temp.length;
                        return temp;
                    })
                    .catch(() => {
                        this.$Message.error('Failed to get asana task');
                    });
            }
        },

        handleGetAsanaProjectSections(id) {
            return getAsanaProjectSections(id)
                .then((res) => {
                    if (res.data && res.data.data.length) {
                        this.asanaProjectSections = res.data.data.filter((x) => {
                            const ptSectionName = x.name.toLowerCase();
                            if (
                                ptSectionName.includes('going'.toLowerCase()) ||
                                ptSectionName.includes('Going'.toLowerCase())
                            ) {
                                return x;
                            }
                        });
                        this.gidSection = this.asanaProjectSections[0].gid;
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get asana project tasks');
                });
        },

        handleOnSelect(data, row) {
            if (data) {
                this.$emit('handleAsanaSelections', row.card, data);
            } else {
                this.$emit('handleAsanaSelections', row.card, []);
            }
        },
    },
};
</script>

<style lang="scss">
.asana-todos {
    margin-bottom: 30px;
}
@media screen and (min-width: 1024) {
    .ivu-table-body {
        overflow-x: hidden;
    }
}
</style>
