<template>
    <Modal
        v-model="showModal"
        title="Are you done for today?"
        footer-hide
        class="salarium-reminder-modal-wrapper"
        :closable="false"
        :mask-closable="false"
    >
        <div>
            <p>Yo Team!</p>

            <p style="text-align: justify">
                Hope you had a good day today, and that you've been happy to produce that much. If not, please reach out
                to the management, so we can discuss on how to make your day better.
            </p>
            <br />
            <p>
                To check-out on Salarium >
                <a href="https://app.v3.salarium.com/login/" target="_blank">https://app.v3.salarium.com/login/</a>
            </p>

            <Row class="confirmation-actions md-margin-top" :gutter="10">
                <Col :xs="24" :sm="12">
                    <Button style="height: auto" long @click="handleConfirmation(false)">
                        NO <br />
                        Switching Time Tracking
                    </Button>
                </Col>
                <Col :xs="24" :sm="12">
                    <Button class="pt-btn-3" style="height: auto" long @click="handleConfirmation(true)">
                        YES<br />
                        Stop Time Tracking
                    </Button>
                </Col>
            </Row>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },

    methods: {
        openModal() {
            this.showModal = true;
        },
        handleConfirmation(status) {
            this.$emit('confirm', status);
            this.showModal = false;
        },
    },
};
</script>

<style>
.salarium-reminder-modal-wrapper.v-transfer-dom .ivu-modal-body,
.ivu-modal-confirm {
    padding: 16px;
}
</style>
