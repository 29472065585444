<template>
    <div class="policies-page">
        <Card class="section-title"> COMPANY POLICIES & PROJECT TRACKER </Card>
        <div style="width: 90%; margin: auto; text-align: left; font-size: 18px">
            <p>
                Latest version of the company policy doc here:
                <a
                    target="_blank"
                    href="https://docs.google.com/document/d/1rgwuigFuPgdqR6zz3IA7mKpXs7vfYpXP00iN0b8LBb4/edit?usp=sharing"
                    >https://docs.google.com/document/d/1rgwuigFuPgdqR6zz3IA7mKpXs7vfYpXP00iN0b8LBb4/edit?usp=sharing</a
                >
            </p>
            <p>
                This project tracker App has been developed taking in consideration the following required action from
                our team members:
            </p>
            <p>- Time tracking per project: Always track your time on the project you are working on</p>
            <p>- Slack - Daily Standup: Let know all the team what you will work on today</p>
            <p>- Slack - Project Channel: Communicate with client as much as possible</p>
            <p>
                - Git (only for Devs): As long as you have an assigned project, pushing on git regularly is a must (min
                2-3 times per day)
            </p>
            <p>
                - PM tool (Asana, Trello or others): You need to create, update, change status on the tasks you work on
            </p>
            <p>- Bi-weekly email: Weekly Plan and End of Week Wrapup are a must send emails.</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeTab: 'policies',
        };
    },
};
</script>

<style lang="scss">
.policies-page {
    .policies-tabs {
        .ivu-tabs-bar {
            border-bottom: 0px;
            .ivu-tabs-nav-wrap {
                .ivu-tabs-ink-bar {
                    background: transparent;
                }
                .ivu-tabs-tab {
                    opacity: 0.53;
                    font-weight: bold;
                    font-size: 20px;
                    &.ivu-tabs-tab-active {
                        color: #463c6e;
                        opacity: unset;
                    }
                    &:hover {
                        opacity: unset;
                        color: #463c6e;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .policies-page {
        word-break: break-word;
    }
}
</style>
