import { Bar } from 'vue-chartjs';
export default {
    extends: Bar,

    props: ['chartdataLabels', 'chartdataSets', 'max-Ticks'],

    data() {
        return {
            chartdata: {
                labels: this.chartdataLabels,
                datasets: this.chartdataSets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    labels: {
                        fontColor: '#463c6e',
                        boxWidth: 0,
                        textAlign: 'left',
                        fontSize: 24,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                // max: this.maxTicks < 10 ? 10 : this.maxTicks,
                                min: 0,
                                stepSize: 2,
                                beginAtZero: true,
                            },
                            stacked: true,
                        },
                    ],
                    xAxes: [
                        {
                            ticks: {
                                autoSkip: false,
                                maxRotation: 60,
                                minRotation: 60,
                            },
                            stacked: true,
                        },
                    ],
                },
            },
        };
    },

    mounted() {
        if (this.chartdata.datasets.length && this.chartdata.labels.length) {
            this.renderChart(this.chartdata, this.options);
        }
    },
    watch: {
        chartdataSets(val) {
            if (val) {
                this.chartdata.datasets = val;
                this.renderChart(this.chartdata, this.options);
            }
        },
        chartdataLabels(val) {
            if (val) {
                this.chartdata.labels = val;
                this.renderChart(this.chartdata, this.options);
            }
        },
    },
};
