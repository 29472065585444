<template>
    <div>
        <div class="header-greetings">
            <p class="date-header">{{ gDate }}, {{ currentMonth }} {{ currentDay }}</p>
            <strong
                ><p class="greet-header">{{ timeBasedGreetings }}, {{ userName }}!</p></strong
            >
        </div>
        <Row :gutter="30" class="sm-margin-bottom">
            <Col :xs="24" :sm="24" :md="12" :lg="12">
                <TimeTracker />
            </Col>
            <Col :xs="24" :sm="24" :md="12" :lg="12">
                <Card dis-hover class="project-config">
                    <p style="color: #fff; font-size: 18px; font-weight: 600; margin-bottom: 15px">
                        STARTING SOMETHING NEW?
                    </p>
                    <Row type="flex" justify="start" class="code-row-bg" style="margin-bottom: 10px">
                        <Button class="btn-config" style="margin-right: 15px" @click="goToAddProject">
                            <Icon type="ios-jet" size="20" /> New Project
                        </Button>
                        <Button class="btn-config" @click="goToAddPeople">
                            <Icon type="ios-people" size="20" /> New Team Member
                        </Button>
                    </Row>
                </Card>
            </Col>
        </Row>
        <ProjectsTableList />
    </div>
</template>

<script>
import moment from 'moment';
import TimeTracker from '@/components/Dashboard/Dev/TimeTracker';
import ProjectsTableList from '@/components/Projects/ProjectsTableList';

export default {
    components: { TimeTracker, ProjectsTableList },
    data() {
        return {
            gDate: null,
            currentMonth: null,
            currentDay: null,
            morning: null,
            timeBasedGreetings: null,
            evening: null,
            userName: localStorage.getItem('user_name'),
        };
    },
    mounted() {
        this.handleGetHeaderDate();
        this.handleGreeting();
    },
    methods: {
        handleGetHeaderDate() {
            this.gDate = moment().format('dddd');
            this.currentMonth = moment().format('MMMM');
            this.currentDay = moment().format('DD');
        },
        handleGreeting() {
            const hour = moment().hour();

            if (hour > 16) {
                return (this.timeBasedGreetings = 'Good evening');
            }

            if (hour > 11) {
                return (this.timeBasedGreetings = 'Good afternoon');
            }

            return (this.timeBasedGreetings = 'Good morning');
        },
        goToAddProject() {
            this.$router.push({ name: 'ProjectForm' });
        },

        goToAddPeople() {
            this.$router.push({ name: 'UserForm' });
        },
    },
};
</script>
<style lang="scss" scoped>
.header-greetings {
    text-align: center;
}
.date-header {
    font-size: 24px;
    color: #463c6e;
}
.greet-header {
    font-size: 30px;
    font-weight: 600;
    color: #463c6e;
    margin-bottom: 30px;
}
.project-config {
    background-color: #463c6e;
}
.btn-config {
    border-radius: 20px;
    color: #463c6e;
}
.project-config.ivu-card.ivu-card-bordered.ivu-card-dis-hover {
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    border-color: #eee;
    border-radius: 20px;
    // background-image: url('~@/assets/images/Background dots.svg');
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: right;
}
.test {
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    border-color: #eee;
    border-radius: 20px;
    background-image: url('~@/assets/images/Background dots.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}
// .project-config.ivu-card.ivu-card-bordered.ivu-card-dis-hover {
//     background:
//     /* top, transparent red, faked with gradient */ linear-gradient(
//             rgba(255, 0, 0, 0.45),
//             rgba(255, 0, 0, 0.45)
//         ),
//         /* bottom, image */ url('~@/assets/images/Background dots.svg');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: right;
// }
@media screen and (max-width: 767px) {
    .project-config {
        .btn-config {
            margin-bottom: 5px;
            width: 100%;
            margin-right: 0 !important;
        }
    }
}
</style>
