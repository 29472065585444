<template>
    <div
        v-if="(status && Object.keys(status).length) || (sections && Object.keys(sections).length)"
        class="jira-todo-list"
    >
        <div v-if="viewType === 'all'">
            <Collapse accordion class="status-title">
                <Panel>
                    {{ status.section.name }} <span v-if="jiraTasksLength">[{{ jiraTasksLength }}]</span>
                    <template #content>
                        <Table
                            :columns="tblColumns"
                            :data="tblData"
                            height="200"
                            size="small"
                            :loading="isLoading"
                            class="disable-selectall"
                            @on-selection-change="handleOnSelect"
                        />
                    </template>
                </Panel>
            </Collapse>
        </div>
        <div v-else-if="viewType === 'assignedToMe'">
            <Table
                :columns="tblColumns"
                :data="tblData"
                height="500"
                size="small"
                :loading="isLoading"
                class="disable-selectall"
                @on-selection-change="handleOnSelect"
            />
        </div>
        <!-- <div v-else>
      <Card class="section-title">
        <Row>
          <Col flex="auto">{{ status.section.name }}</Col>
        </Row>
      </Card>
      <Table :columns="tblColumns" :data="tblData" height="200" size="small" :loading="isLoading" @on-selection-change="handleOnSelect" class="disable-selectall"></Table>
    </div> -->
    </div>
</template>

<script>
import moment from 'moment';
import { getPaginatedJiraIssues } from '@/services/jira.services';
import Bugsnag from '@bugsnag/js';

export default {
    props: {
        viewType: {
            type: String,
            default: 'all', // all or assignedToMe
        },
        status: {
            type: Object,
            default: () => {},
        },
        sections: {
            type: Array,
            default: () => {},
        },
        selectedJiraAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedProject: {
            type: String,
            default: '',
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
    },

    data() {
        return {
            isLoading: false,
            jiraTasksLength: null,
            tblColumns: [
                {
                    type: 'selection',
                    width: 60,
                    align: 'center',
                },
                {
                    title: 'Issue #',
                    key: 'key',
                    width: 100,
                    render: (h, params) => {
                        return h('div', params.row.issueDetails ? params.row.issueDetails.key : '');
                    },
                },
                {
                    title: 'Name',
                    key: 'name',
                    minWidth: 330,
                    render: (h, params) => {
                        return h('div', params.row.issueDetails ? params.row.issueDetails.name : '');
                    },
                },
                {
                    title: 'UpdatedAt',
                    key: 'updatedAt',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.updatedAt ? moment(params.row.updatedAt).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Status',
                    width: 190,
                    render: (h, params) => {
                        return h('div', params.row.issueDetails ? params.row.issueDetails.status.name : '');
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        status: {
            handler() {
                this.handleGetJiraCardListBySection();
            },
            deep: true,
        },
        sections: {
            handler() {
                this.handleGetJiraCardListBySection();
            },
            deep: true,
        },
    },

    mounted() {
        this.handleGetJiraCardListBySection();
    },

    methods: {
        handleGetJiraTaskList(id) {
            if (this.viewType === 'assignedToMe') {
                const jiraAccountId = localStorage.getItem('jiraId');
                if (jiraAccountId) {
                    const sections = this.sections.map((s) => s.section.name);
                    return getPaginatedJiraIssues(id, {
                        'issueDetails.status.name': { $in: sections },
                        'jiraUserDetails.accountId': localStorage.getItem('jiraId'),
                    }).then((res) => this.parseData(res.data));
                } else {
                    return [];
                }
            }

            const secName = this.status.section.name.toLowerCase();
            if (secName.includes('Done'.toLowerCase())) {
                let dateTime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(10, 'days');
                return getPaginatedJiraIssues(id, {
                    'issueDetails.status.name': this.status.section.name,
                    updatedAt: { $gte: dateTime },
                }).then((res) => this.parseData(res.data));
            } else if (secName.includes('backlog')) {
                let dateTime = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0, date: 1 });
                return getPaginatedJiraIssues(id, {
                    'issueDetails.status.name': this.status.section.name,
                    updatedAt: { $gte: dateTime },
                }).then((res) => this.parseData(res.data));
            } else {
                return getPaginatedJiraIssues(id, {
                    'issueDetails.status.name': this.status.section.name,
                }).then((res) => this.parseData(res.data));
            }
        },
        parseData(data) {
            try {
                if (data.data) {
                    const temp = data.data.map((x) => {
                        return {
                            ...x,
                            key: x.issueDetails.key,
                            name: x.issueDetails.name,
                            updatedAt: x.updatedAt,
                            status: x.issueDetails.status.name,
                        };
                    });
                    this.jiraTasksLength = temp.length;
                    return temp;
                }
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
        async handleGetJiraCardListBySection() {
            this.isLoading = true;
            this.tblData = [];
            this.tblData = await this.handleGetJiraTaskList(this.selectedProject);
            this.isLoading = false;
        },
        handleOnSelect(data) {
            if (data) {
                // Group data by status
                const groupedData = data.reduce((x, y) => {
                    (x[y.status] = x[y.status] || []).push(y);
                    return x;
                }, {});

                // Emit by status
                Object.entries(groupedData).forEach((item) => {
                    const status = item[0];
                    const value = item[1];
                    this.$emit('handleJiraSelections', status, value);
                });
            } else {
                this.$emit('handleJiraSelections', []);
            }
        },
    },
};
</script>

<style>
.status-title {
    background: #463c6e;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px;
}
.status-title.ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
    height: fit-content;
    line-height: 34px;
}
.status-title.ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
    color: #fff;
}
/* .status-title.ivu-collapse>.ivu-collapse-item>.ivu-collapse-header>i.ivu-icon.ivu-icon-ios-arrow-forward {
  font-size: 30px;
} */
.jira-todo-list.md-margin-bottom {
    margin-bottom: 0px;
}
</style>
