<template>
    <div class="users-table-list fullwidth">
        <Row type="flex" justify="end" class="code-row-bg" style="margin-bottom: 20px">
            <Button class="pt-btn-1" @click="goToAddPeople"> Add People </Button>
        </Row>

        <Row :gutter="50" class="length-wrapper xxl-margin-bottom xxl-margin-top">
            <Col :xs="24" :md="8">
                <Card class="teamLength"> Team Members ({{ tblData.length }}) </Card>
            </Col>
            <Col :xs="24" :md="8">
                <Card class="teamLength"> Clients ({{ clientsList.length }}) </Card>
            </Col>
            <Col :xs="24" :md="8">
                <Card class="teamLength"> Archived Users ({{ archivedMembersData.length }}) </Card>
            </Col>
        </Row>
        <div class="users-table-list-section">
            <Card class="section-title"> TEAM MEMBERS </Card>
            <Table :columns="tblColumns" :data="tblData" width="auto" size="small" :loading="isLoading" />
            <!-- <Table v-else :columns="pmtblColumns" :data="tblData" width="auto" size="small" :loading="isLoading"></Table> -->
        </div>
        <div class="clients-table-list-section md-margin-top">
            <Card class="section-title"> CLIENTS </Card>
            <ClientsTableList :clients="clientsList" :is-loading="isLoading" />
        </div>
        <div class="users-table-list-section md-margin-top">
            <Card class="section-title"> ARCHIVED USERS </Card>
            <Table
                :columns="archivedtblColumns"
                width="auto"
                :data="archivedMembersData"
                size="small"
                :loading="isLoading"
            />
            <!-- <Table v-else :columns="pmarchivedtblColumns" :data="archivedMembersData" width="auto" size="small" :loading="isLoading"></Table> -->
        </div>
        <BaseModalConfirmation
            ref="BaseModalConfirmation"
            :message="confirmationMsg"
            @cancel="handleGetUsers"
            @confirm="handleOnConfirmed"
        />
        <EditMonitoringTools ref="EditMonitoringTools" @handle="handleLoading" />
    </div>
</template>

<script>
import moment from 'moment';
import { getUsers, archiveUser, updateUsersById, getAllArchivedUsers } from '@/services/users.services';
import BaseModalConfirmation from '@/components/UI/BaseModalConfirmation';
import ClientsTableList from './ClientsTableList';
import EditMonitoringTools from '@/components/Users/EditMonitoringTools';

export default {
    components: {
        BaseModalConfirmation,
        ClientsTableList,
        EditMonitoringTools,
    },
    data() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
            confirmationMsg: '',
            actionStatus: null,
            isLoading: false,
            userRole: localStorage.getItem('mainRole'),
            archivedtblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    sortable: true,
                    sortType: 'asc',
                    minWidth: 180,
                    sortMethod: (a, b, type) => {
                        if (type == 'asc') {
                            return a > b ? 1 : -1;
                        } else {
                            return a > b ? 1 : -1;
                        }
                    },
                },
                {
                    title: 'Email',
                    key: 'email',
                    minWidth: 180,
                },
                {
                    title: 'Role',
                    key: 'mainRole',
                    minWidth: 125,
                    render: (h, params) => {
                        if (params.row.mainRole) {
                            const role = this.mainRole.find((x) => x.value === params.row.mainRole).name;
                            return h('span', role);
                        } else {
                            return h('span', '');
                        }
                    },
                },
                {
                    title: 'Last Login',
                    key: 'monitoring_last_login',
                    minWidth: 180,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.monitoring_last_login
                                ? moment(params.row.monitoring_last_login).utc().format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'Actions',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-create',
                                        size: '20',
                                    },
                                    on: {
                                        click: () => {
                                            this.$router.push(`/form-user?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'edit'
                            ),
                        ]);
                    },
                },
            ],
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    sortable: true,
                    sortType: 'asc',
                    sortMethod: (a, b, type) => {
                        if (type == 'asc') {
                            return a > b ? 1 : -1;
                        } else {
                            return a > b ? 1 : -1;
                        }
                    },
                    //fixed: 'left',
                    minWidth: 180,
                },
                {
                    title: 'Role',
                    key: 'mainRole',
                    width: 125,
                    render: (h, params) => {
                        if (params.row.mainRole) {
                            const role = this.mainRole.find((x) => x.value === params.row.mainRole).name;
                            return h('span', role);
                        } else {
                            return h('span', '');
                        }
                    },
                },
                {
                    title: 'Last Login',
                    key: 'monitoring_last_login',
                    minWidth: 180,
                    maxWidth: 250,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.monitoring_last_login
                                ? moment(params.row.monitoring_last_login).utc().format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'First Time tracker',
                    key: 'monitoring_last_time_tracker',
                    minWidth: 180,
                    maxWidth: 250,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.monitoring_last_time_tracker
                                ? moment(params.row.monitoring_last_time_tracker).utc().format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'Slack',
                    key: 'monitoring_slack',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_slack ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'PM tool',
                    key: 'monitoring_pmTool',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_pmTool ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Git',
                    key: 'monitoring_git',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_git ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Salarium',
                    key: 'monitoring_salarium',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_salarium ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Last Fri',
                    key: 'monitoring_lastFriEmail',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_lastFriEmail ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Monday',
                    key: 'monitoring_monEmail',
                    width: 90,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_monEmail ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Friday',
                    key: 'monitoring_friEmail',
                    width: 90,
                    class: 'text-orient',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-checkmark-circle',
                                    size: 20,
                                    color: params.row.monitoring_friEmail ? '#44E53E' : '#E9495D',
                                },
                            }),
                        ]);
                    },
                },
                {
                    title: 'Actions',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-create',
                                        size: '20',
                                    },
                                    on: {
                                        click: () => {
                                            this.$router.push(`/form-user?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'edit'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-checkmark-circle',
                                        size: 20,
                                    },
                                    on: {
                                        click: () => {
                                            this.editMonitoringTools(params.row._id);
                                        },
                                    },
                                },
                                'edit-monitoring-tools'
                            ),
                        ]);
                    },
                },
            ],
            tblData: [],
            clientsList: [],
            archivedMembersData: [],
        };
    },
    created() {
        window.addEventListener('resize', this.onResize);
    },
    mounted() {
        this.handleGetUsers();
        this.setTableColumn();
        this.onResize();
    },
    methods: {
        onResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if (this.width < 1280) {
                this.tblColumns[2].width = 90;
                this.tblColumns[3].width = 90;
            } else {
                this.tblColumns[2].width = 180;
                this.tblColumns[3].width = 180;
            }
        },
        setTableColumn() {
            if (this.userRole === 'pm') {
                this.tblColumns.splice(11, 1); //remove Action Column
                this.archivedtblColumns.splice(4, 1); //remove Action Column
            }
        },
        async handleGetUsers() {
            this.isLoading = true;
            this.tblData = [];
            this.clientsList = [];
            this.archivedMembersData = [];
            const allUsers = await this.handleGetAllUsers();
            const allArchivedUsers = await this.handleGetAllArchivedUsers();
            if (allArchivedUsers) {
                const archivedUsers = allArchivedUsers.reduce((acc, obj) => {
                    if (obj.isArchived === true) {
                        acc.push(obj);
                    }
                    return acc;
                }, []);
                this.archivedMembersData = archivedUsers.filter((i) => i.isArchived === true);
            }

            if (allUsers) {
                const activeUsers = allUsers.reduce((acc, obj) => {
                    if (obj.isActive && obj.isArchived === false) {
                        acc.push(obj);
                    }
                    return acc;
                }, []);
                this.tblData = activeUsers.filter((i) => i.mainRole !== 'client');
                this.clientsList = activeUsers.filter((i) => i.mainRole === 'client');
            }
            this.isLoading = false;
        },
        handleGetAllUsers() {
            return getUsers()
                .then((res) => {
                    if (res.data) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    console.log('Failed to fetch all users', err);
                });
        },
        handleGetAllArchivedUsers() {
            return getAllArchivedUsers()
                .then((res) => {
                    if (res.data) {
                        return res.data;
                    }
                })
                .catch((err) => {
                    console.log('Failed to fetch all archived users', err);
                });
        },
        handleArchiveUser(id) {
            this.isLoading = true;
            archiveUser(id)
                .then(() => {
                    this.tblData = this.tblData.filter((item) => {
                        item._id != id;
                    });
                    this.handleGetUsers();
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log('Failed to archive project', err);
                    this.isLoading = false;
                });
        },

        goToAddPeople() {
            this.$router.push({ name: 'UserForm' });
        },

        handleOnConfirmed(id) {
            if (this.actionType == 'archive') {
                this.handleArchiveUser(id);
            } else {
                this.handleUserStatus(id);
            }
        },

        handleUserStatus(id) {
            const status = this.tblData.find((x) => x.id == id).isActive;

            updateUsersById(id, { isActive: !status })
                .then(() => {
                    this.$Message.success('User status successfully updated');
                })
                .catch(() => {
                    this.$Message.error('Failed to update user status');
                });
        },

        async editMonitoringTools(data) {
            if (data) {
                this.$refs.EditMonitoringTools.openModal(data);
            } else {
                this.$Message.info('No monitoring tools yet');
            }
        },

        handleLoading() {
            this.handleGetUsers();
        },
    },
};
</script>

<style lang="scss" scoped>
.projects-table-list {
    margin-bottom: 20px;
}
.length-wrapper {
    text-align: center;
    .teamLength {
        width: 200px;
        margin: auto;
        border: 1px solid $primary-color;
        color: #515a6e;
    }
}
</style>
