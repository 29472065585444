<template>
    <div class="header-greetings">
        <p class="date-header">{{ gDate }}, {{ currentMonth }} {{ currentDay }}</p>
        <strong
            ><p class="greet-header">{{ timeBasedGreetings }}, {{ userName }}!</p></strong
        >
    </div>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            gDate: null,
            currentMonth: null,
            currentDay: null,
            morning: null,
            timeBasedGreetings: null,
            evening: null,
            userName: localStorage.getItem('user_name'),
        };
    },
    mounted() {
        setInterval(() => {
            this.handleGetHeaderDate();
            this.handleGreeting();
        }, 1000);
    },
    methods: {
        handleGetHeaderDate() {
            this.gDate = moment().format('dddd');
            this.currentMonth = moment().format('MMMM DD, yyyy');
            this.currentDay = moment().format('hh:mm A');
        },
        handleGreeting() {
            const hour = moment().hour();

            if (hour > 16) {
                return (this.timeBasedGreetings = 'Good evening');
            }

            if (hour > 11) {
                return (this.timeBasedGreetings = 'Good afternoon');
            }

            return (this.timeBasedGreetings = 'Good morning');
        },
    },
};
</script>
<style lang="scss" scoped>
.header-greetings {
    text-align: left;
}
.date-header {
    font-size: 24px;
    color: #463c6e;
}
.greet-header {
    font-size: 30px;
    font-weight: 600;
    color: #463c6e;
    margin-bottom: 30px;
}
</style>
