<template>
    <div>
        <UsersTableList />
    </div>
</template>

<script>
import UsersTableList from '@/components/Users/UsersTableList';
import '../style.css';
export default {
    components: { UsersTableList },
};
</script>
