<template>
    <div class="project-developer-section">
        <Card class="section-title sec-dev"> DEVELOPERS </Card>
        <Row v-for="item in devStatData" :key="item.value" :value="item.value" class="marginRow">
            <Col span="12">
                <Icon class="statIcon" type="md-eye" size="20" @click="handleRedirectUser(item.label)" />{{
                    item.label
                }} </Col
            ><Col style="text-align: end" span="12">
                Daily 8 <span class="statBorder">{{ item.totalDaily8 }} + {{ item.approvedExtra }}</span>
            </Col>
        </Row>
        <Row v-if="!totalHoursSpent" class="hoursRow"> TOTAL HOURS SPENT: <span class="statHoursBorder">0</span> </Row>
        <Row v-else class="hoursRow">
            TOTAL HOURS SPENT: <span class="statHoursBorder">{{ totalHoursSpent }}</span>
        </Row>
        <Row v-if="!totalHoursEstimate" class="hoursRow">
            TOTAL HOURS EST.: <span class="statHoursBorder">0</span>
        </Row>
        <Row v-else class="hoursRow">
            TOTAL HOURS EST.: <span class="statHoursBorder">{{ totalHoursEstimate }}</span>
        </Row>
        <Progress v-if="!percentage" :percent="0" :stroke-color="'#49E97E'" class="progressRow" />
        <Progress v-else :percent="percentage" :stroke-color="'#49E97E'" class="progressRow" />
    </div>
</template>

<script>
import { getProjects, getProjectsById } from '@/services/projects.services';
import { getUsers } from '@/services/users.services';

export default {
    data() {
        return {
            tblData: [],
            devStatData: [],
            timelogsData: [],
            totalHoursSpent: null,
            totalHoursEstimate: null,
            percentage: null,
            selectedUserId: null,
        };
    },
    mounted() {
        this.handleGetProjects();
    },
    methods: {
        handleGetProjects() {
            getProjects()
                .then((res) => {
                    this.tblData = res.data.filter(function (e) {
                        return e.isActive === true && e.isArchived === false;
                    });
                    this.tblData = this.handleGetProjectsById(this.$route.query.id);
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                    this.isLoading = false;
                });
        },
        handleGetProjectsById(id) {
            return getProjectsById(id)
                .then((res) => {
                    this.totalHoursSpent = res.data.developerProjectPerformanceStats.totalHoursSpent
                        ? res.data.developerProjectPerformanceStats.totalHoursSpent.toFixed(2)
                        : res.data.developerProjectPerformanceStats.totalHoursSpent;
                    this.totalHoursEstimate = res.data.developerProjectPerformanceStats.totalHoursEstimate;
                    this.percentage = Math.round(res.data.developerProjectPerformanceStats.percentage * 100) / 100;
                    if (res.data.developerProjectPerformanceStats.members) {
                        this.devStatData = res.data.developerProjectPerformanceStats.members.map((i) => {
                            return {
                                label: i.name,
                                approvedExtra: i.timelogs.approvedExtra,
                                totalDaily8: i.timelogs.totalDaily8
                                    ? i.timelogs.totalDaily8.toFixed(2)
                                    : i.timelogs.totalDaily8,
                            };
                        });
                    }
                })
                .catch((err) => {
                    console.log('Failed to fetch data', err);
                });
        },
        handleRedirectUser(user) {
            getUsers().then((res) => {
                let resData = res.data.filter((x) => x.name === user);
                this.$router.push(`/timetracker?user=${resData[0]._id}&projectId=${this.$route.query.id}`);
            });
        },
    },
};
</script>
<style>
.project-developer-section {
    border: 1px solid #cfcfcf;
    border-radius: 13px;
    opacity: 1;
    padding-bottom: 40px;
}
</style>
