<template>
    <div class="email-logs-table-list-section">
        <Row type="flex" justify="space-between" style="margin-bottom: 20px"
            ><Col span="24"
                ><Card
                    ><h3>EMAIL LOGS</h3>
                    <Table :columns="tblEmailColumns" :data="emaildata" size="small" :loading="loading" height="240" />

                    <Page :total="count" :current="currentPage" class="pagination" @on-change="handleOnPageChanged"
                /></Card>

                <WeeklyEmailsDetails ref="WeeklyEmailsDetails"
            /></Col>
        </Row>
    </div>
</template>

<script>
import moment from 'moment';
import WeeklyEmailsDetails from '@/views/WeeklyEmailsDetails';
export default {
    components: {
        WeeklyEmailsDetails,
    },
    props: {
        emaildata: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: null,
        },
        loading: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            currentPage: 1,
            tblEmailColumns: [
                {
                    title: 'Date',
                    key: 'dateSent',
                    width: 120,
                    render: (h, params) => {
                        return h('span', params.row.dateSent ? moment(params.row.dateSent).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Type',
                    key: 'type',
                    width: 80,
                    render: (h, params) => {
                        if (params.row.type === 'plan') {
                            return h('span', 'Plan');
                        } else if (params.row.type === 'wrapup') {
                            return h('span', 'Wrapup');
                        }
                    },
                },
                {
                    title: 'Sender',
                    key: 'sender',
                    minWidth: 180,
                    maxWidth: 220,
                },
                {
                    title: 'Subject Line',
                    key: 'subject',
                    minWidth: 200,
                },
                {
                    title: 'Words #',
                    key: 'wordCount',
                    width: 100,
                },
                {
                    title: 'Tasks #',
                    key: 'numOfTasks',
                    width: 100,
                },
                {
                    title: 'Tracker Hours #',
                    key: 'trackerHours',
                    width: 120,
                },
                {
                    title: 'Est. Hours #',
                    key: 'estimatedHours',
                    width: 120,
                },
                {
                    title: 'Rated',
                    key: 'rated',
                    width: 70,
                    render: (h, params) => {
                        return h('span', params.row.rated ? params.row.rated : '-');
                    },
                },
                {
                    title: 'Action',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-eye',
                                        size: '28',
                                    },
                                    on: {
                                        click: () => {
                                            this.$refs.WeeklyEmailsDetails.openModal(params.row);
                                        },
                                    },
                                },
                                'view'
                            ),
                        ]);
                    },
                },
            ],
        };
    },
    methods: {
        async handleOnPageChanged(page) {
            this.currentPage = page;
            this.$emit('on-page-change', this.currentPage);
        },
    },
};
</script>
<style lang="scss">
.email-logs-table-list-section {
    p,
    span {
        color: #463c6e;
    }
    .ivu-card {
        box-shadow: 0 2px 2px rgb(0 0 0 / 10%) !important;
        border-color: #eee;
        border-radius: 20px;
        padding: 10px 0;

        .ivu-table {
            color: #463c6e;

            .ivu-table-header thead tr th {
                background-color: unset;
                color: #463c6e;
            }

            table {
                border-collapse: collapse;

                tbody tr {
                    border-bottom: solid 1px rgba(70, 60, 110, 10%);

                    td:last-child {
                        .ivu-icon {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .pagination {
            li {
                border: none;
            }
            .ivu-page-next,
            .ivu-page-prev {
                line-height: 25px;

                a {
                    color: #463c6e;
                    font-size: 18px;
                }
            }
            .ivu-page-item-active {
                background: #463c6e;
                border-radius: 25px;

                a {
                    color: #fff !important;
                }
            }
            .ivu-page-item {
                font-weight: 600;
                font-size: 12px;
                height: 22px;
                min-width: 22px;
                line-height: 22px;

                a {
                    color: #463c6e;
                }
            }
            .ivu-page-disabled {
                a {
                    color: #ccc;
                }
            }
        }
    }
    h3 {
        color: #463c6e;
        padding: 0 18px;
        font-size: 20px;
    }
    .pagination {
        margin-top: 20px;
    }
}
</style>
