<template>
    <div class="projects-table-list fullwidth">
        <Card
            ><p class="projTitle">PROJECTS</p>
            <div>
                <Tabs v-model="selectedTab" type="card" class="fullwidth pt-tabs" @on-click="handleOnTabChanged">
                    <TabPane v-for="(tab, index) in projectTypes" :key="index" :label="tab.label" :name="tab.name">
                        <Table
                            :columns="tab.name != 'RPm' && tab.name != 'RT' ? tblColumns : retainerstblColumns"
                            :data="tblData"
                            size="small"
                            :loading="isLoading"
                        />
                    </TabPane>
                </Tabs>
            </div>
        </Card>

        <BaseModalConfirmation
            ref="BaseModalConfirmation"
            :message="confirmationMsg"
            @cancel="handleGetProjectsByType(selectedTab)"
            @confirm="handleOnConfirmed"
        />
    </div>
</template>

<script>
import moment from 'moment';
import { getProjects, getProjectsByType, updateProjectsById } from '@/services/projects.services';
import BaseModalConfirmation from '@/components/UI/BaseModalConfirmation';

export default {
    components: {
        BaseModalConfirmation,
    },
    data() {
        return {
            confirmationMsg: '',
            actionType: '',
            isLoading: false,
            selectedTab: this.$route.query.tab ? this.$route.query.tab : 'FT',
            projectTypes: [
                {
                    label: 'Fix-terms',
                    name: 'FT',
                },
                {
                    label: 'Startup Oasis',
                    name: 'SO',
                },
                {
                    label: 'Retainers',
                    name: 'RT',
                },
                {
                    label: 'Retainers with PM',
                    name: 'RPm',
                },
                {
                    label: 'Inactive Projects',
                    name: 'inactive-projects',
                },
                {
                    label: 'Archived Projects',
                    name: 'archive-projects',
                },
            ],
            retainerstblColumns: [
                {
                    title: 'Project Name',
                    key: 'name',
                    minWidth: 200,
                },
                {
                    title: 'Start Date',
                    key: 'startDate',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.startDate ? moment(params.row.startDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'End Date',
                    key: 'endDate',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.endDate ? moment(params.row.endDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalProjectHours',
                    width: 120,
                },
                {
                    title: 'Actions',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-eye',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    class:
                                        params.row.isArchived === false && params.row.isActive === true
                                            ? ''
                                            : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/project-monitoring?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'view'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-create',
                                        size: '20',
                                    },
                                    class:
                                        params.row.isArchived === false && params.row.isActive === true
                                            ? ''
                                            : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/form-project?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'edit'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-analytics',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    class:
                                        params.row.isArchived === false && params.row.isActive === true
                                            ? ''
                                            : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/project-performance?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'view'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: params.row.isArchived ? 'md-undo' : 'ios-archive',
                                        size: '25',
                                    },
                                    class: params.row.isActive === true ? '' : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.confirmationMsg = `Are you sure you want ${
                                                params.row.isArchived ? 'restore' : 'archive'
                                            } this project?`;
                                            this.actionType = 'archive';
                                            this.$refs.BaseModalConfirmation.openModal(params.row._id);
                                        },
                                    },
                                },
                                'del'
                            ),
                            h('i-switch', {
                                props: {
                                    value: params.row.isActive,
                                    'before-change': () => {
                                        return new Promise((resolve) => {
                                            this.$Modal.confirm({
                                                title: 'Confirmation',
                                                content: `Are you sure you want to ${
                                                    params.row.isActive ? 'deactivate' : 'activate'
                                                } this project?`,
                                                className: 'pt_custom_modal',
                                                onOk: () => {
                                                    this.handleUpdateProjectById(params.row._id, {
                                                        isActive: !params.row.isActive,
                                                    });
                                                    resolve();
                                                },
                                            });
                                        });
                                    },
                                    size: 'small',
                                    trueValue: false,
                                    falseValue: true,
                                    trueColor: '#CFCFCF',
                                    falseColor: '#44E53E',
                                },
                                class: params.row.isArchived === true ? 'icon-hide' : '',
                                on: {},
                            }),
                        ]);
                    },
                },
            ],
            tblColumns: [
                {
                    title: 'Project Name',
                    key: 'name',
                    minWidth: 200,
                },
                {
                    title: 'Start Date',
                    key: 'startDate',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.startDate ? moment(params.row.startDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'End Date',
                    key: 'endDate',
                    width: 150,
                    render: (h, params) => {
                        return h('div', params.row.endDate ? moment(params.row.endDate).format('DD-MMM-YYYY') : '');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalProjectHours',
                    width: 120,
                },
                {
                    title: 'Progress %',
                    key: 'projectProgressPercentage',
                    minWidth: 200,
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Progress',
                                {
                                    props: {
                                        percent:
                                            Math.round((params.row.projectProgressPercentage * 100) / 100) < 100
                                                ? Math.round((params.row.projectProgressPercentage * 100) / 100)
                                                : 100,
                                        'stroke-color': '#49E97E',
                                    },
                                    on: {
                                        click: () => {},
                                    },
                                },
                                Math.round((params.row.projectProgressPercentage * 100) / 100) + '%'
                            ),
                        ]);
                    },
                },
                {
                    title: 'Actions',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-eye',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    class:
                                        params.row.isArchived === false && params.row.isActive === true
                                            ? ''
                                            : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/project-monitoring?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'view'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-create',
                                        size: '20',
                                    },
                                    class:
                                        params.row.isArchived === false && params.row.isActive === true
                                            ? ''
                                            : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/form-project?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'edit'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-analytics',
                                        size: '20',
                                        color: '#e9495d',
                                    },
                                    class:
                                        params.row.isArchived === false && params.row.isActive === true
                                            ? ''
                                            : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.$router.push(`/project-performance?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'view'
                            ),

                            h(
                                'Icon',
                                {
                                    props: {
                                        type: params.row.isArchived ? 'md-undo' : 'ios-archive',
                                        size: '25',
                                    },
                                    class: params.row.isActive === true ? '' : 'icon-hide',
                                    on: {
                                        click: () => {
                                            this.confirmationMsg = `Are you sure you want ${
                                                params.row.isArchived ? 'restore' : 'archive'
                                            } this project?`;
                                            this.actionType = 'archive';
                                            this.$refs.BaseModalConfirmation.openModal(params.row._id);
                                        },
                                    },
                                },
                                'del'
                            ),
                            h('i-switch', {
                                props: {
                                    value: params.row.isActive,
                                    'before-change': () => {
                                        return new Promise((resolve) => {
                                            this.$Modal.confirm({
                                                title: 'Confirmation',
                                                content: `Are you sure you want to ${
                                                    params.row.isActive ? 'deactivate' : 'activate'
                                                } this project?`,
                                                className: 'pt_custom_modal',
                                                onOk: () => {
                                                    this.handleUpdateProjectById(params.row._id, {
                                                        isActive: !params.row.isActive,
                                                    });
                                                    resolve();
                                                },
                                            });
                                        });
                                    },
                                    size: 'small',
                                    trueValue: false,
                                    falseValue: true,
                                    trueColor: '#CFCFCF',
                                    falseColor: '#44E53E',
                                },
                                class: params.row.isArchived === true ? 'icon-hide' : '',
                                on: {
                                    // 'on-change': () => {
                                    //   this.confirmationMsg = `Are you sure you want to ${(params.row.isActive)? 'deactivate':'activate'} this project?`
                                    //   this.actionType = 'status'
                                    //   this.$refs.BaseModalConfirmation.openModal(params.row._id)
                                    // }
                                },
                            }),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },
    mounted() {
        this.tblData = [];
        this.handleGetProjectsByType(this.selectedTab);
    },
    methods: {
        handleOnTabChanged(name) {
            this.selectedTab = name;
            this.$router.replace({ path: this.$route.path, query: { tab: this.selectedTab } }).catch(() => {});
            if (name === 'archive-projects') {
                this.isLoading = true;
                this.tblData = [];
                getProjects()
                    .then((res) => {
                        this.tblData = res.data.filter(function (e) {
                            return e.isArchived === true;
                        });
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        console.log('Failed to fetch data', err);
                        this.isLoading = false;
                    });
            } else if (name === 'inactive-projects') {
                this.isLoading = true;
                this.tblData = [];
                getProjects()
                    .then((res) => {
                        this.tblData = res.data.filter(function (e) {
                            return e.isActive === false;
                        });
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        console.log('Failed to fetch data', err);
                        this.isLoading = false;
                    });
            } else {
                this.handleGetProjectsByType(name);
            }
        },

        handleGetProjectsByType(type) {
            this.isLoading = true;
            this.tblData = [];
            getProjectsByType(type)
                .then((res) => {
                    this.tblData = res.data.filter(function (e) {
                        return e.isActive === true && e.isArchived === false;
                    });
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log('Failed to get projects by type: ', err);
                    this.isLoading = false;
                });
        },

        goToAddProject() {
            this.$router.push({ name: 'ProjectForm' });
        },

        goToAddPeople() {
            this.$router.push({ name: 'UserForm' });
        },

        handleOnConfirmed(id) {
            const selectedProject = this.tblData.find((x) => x._id === id);
            if (this.actionType == 'archive') {
                this.handleUpdateProjectById(id, {
                    isArchived: !selectedProject.isArchived,
                });
            } else {
                this.handleUpdateProjectById(id, {
                    isActive: !selectedProject.isActive,
                });
            }
        },

        handleUpdateProjectById(id, data) {
            this.isLoading = true;
            updateProjectsById(id, data)
                .then((response) => {
                    console.log(response);
                    this.tblData = this.tblData.filter((item) => {
                        item._id != id;
                    });
                    this.handleOnTabChanged(this.selectedTab);
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log('Failed to restore project', err);
                });
        },
    },
};
</script>

<style lang="scss">
.projects-table-list {
    margin-bottom: 20px;
    .icon-hide {
        display: none !important;
    }
}
i.ivu-icon.ivu-icon-ios-help-circle {
    display: none;
}
.ivu-modal-confirm {
    padding: unset;
}
.ivu-modal-body {
    padding: unset;
}
.ivu-modal-confirm-head {
    text-align: center;
    padding: 15px;
    background-color: #463c6e;
}
.ivu-modal-confirm-head-title {
    color: #fff;
}
.ivu-modal-confirm-body {
    padding: 30px;
    text-align: center;
}
.ivu-modal-confirm-footer {
    text-align: center;
    margin: 0px 0 35px;
}
.ivu-modal-confirm-footer button.ivu-btn.ivu-btn-text {
    background: #6c6c6c 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    color: #fff;
    padding: 0 25px;
    border-radius: 4px;
    &:hover {
        color: $secondary-color;
        background-color: #fff;
        border-color: $secondary-color;
    }
}
.ivu-modal-confirm-footer button.ivu-btn.ivu-btn-primary {
    background: #463c6e 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    border-color: #463c6e;
    padding: 0 30px;
    border-radius: 4px;
    &:hover {
        color: $primary-color;
        background-color: #fff;
        border-color: $primary-color;
    }
}
button.btn-config.ivu-btn.ivu-btn-default:hover {
    border-color: unset;
}
.projTitle {
    color: #463c6e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}
.projects-table-list .ivu-card.ivu-card-bordered {
    box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
    border-color: #eee;
    border-radius: 20px;
}
</style>
