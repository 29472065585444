<template>
    <Header :style="{ padding: 0 }" class="layout-header-bar app-header">
        <Row type="flex" justify="space-between" class="code-row-bg">
            <Col class="sider-trigger">
                <Icon
                    style="margin: 0px; padding: 4.2px"
                    type="md-list"
                    size="30"
                    :class="rotateIcon"
                    :style="rotateIcon.find((a) => a.includes('rotate-icon')) ? 'width: 60px;' : 'width: 201px;'"
                    @click.native="collapsedSider"
                />
            </Col>
            <Col span="9" class="d-flex-center logo-wrapper">
                <img src="@/assets/STU Logo.png" />
            </Col>
            <Col class="text-right">
                <div class="notification">
                    <Icon type="ios-notifications" size="25" @click="showList" />
                    <span v-if="unreadMessage > 0" class="badge">{{ unreadMessage }}</span>
                </div>
                <div v-if="showHideList" class="notifications-container">
                    <h1>Notifications</h1>
                    <ul id="notification-list">
                        <li
                            v-for="(item, index) in notificationList"
                            :key="index"
                            :class="!item.readAt ? 'active' : ''"
                        >
                            <p class="text">{{ item.message }}</p>
                            <p class="date">{{ formatDate(item.createdAt) }}</p>
                            <p v-if="isAdmin && item.data.type === 'timedispute'">
                                <Button @click="viewTimeDisputeRequest(item.data._id)">View</Button>
                            </p>
                            <p v-if="isAdmin && item.data.type === 'leave'">
                                <Button @click="viewLeaveRequest(item.data._id)">View</Button>
                            </p>
                            <p v-if="!isAdmin && item.data.type === 'timedispute'">
                                <Button @click="viewTimeDisputeRequest(item.data._id)">View</Button>
                            </p>
                            <p v-if="!isAdmin && item.data.type === 'leave'">
                                <Button @click="viewLeaveRequest(item.data._id)">View</Button>
                            </p>
                        </li>
                    </ul>
                    <div v-if="totalPage !== currentPage" class="load-more-container">
                        <button id="load-more" @click="handleLoadMore">Load more notifications</button>
                    </div>
                    <div v-else class="load-more-container">
                        <span id="no-more">No more notifications</span>
                    </div>
                </div>
                <Dropdown class="logout-btn">
                    <a href="javascript:void(0)" style="white-space: nowrap">
                        {{ user_name }}
                        <Icon type="md-person" size="18" />
                    </a>
                    <DropdownMenu slot="list">
                        <DropdownItem>
                            <Button type="text" @click="viewProfile">
                                Profile <Icon type="md-person" size="18" />
                            </Button>
                        </DropdownItem>
                        <DropdownItem>
                            <Button type="text" @click="handleLogout">
                                Logout <Icon type="md-exit" size="18" />
                            </Button>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                <div class="right-nav-btns" />
            </Col>
        </Row>
        <ViewTimeDisputeRequestModal ref="ViewTimeDisputeRequest" />
        <ViewLeaveRequestModal ref="ViewLeaveRequest" />
    </Header>
</template>

<script>
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { getPaginatedNotificationList, postReadAllNotifications } from '@/services/notifications.services';
import { getRequestRecordById } from '../../services/requests.services';
import ViewTimeDisputeRequestModal from '../Requests/Dev/TimeDispute/ViewRequestModal.vue';
import ViewLeaveRequestModal from '../Requests/Dev/Leave/ViewRequestModal.vue';

export default {
    components: { ViewTimeDisputeRequestModal, ViewLeaveRequestModal },
    props: {
        rotateIcon: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            user_name: localStorage.getItem('user_name'),
            requestData: null,
            isAdmin: localStorage.getItem('mainRole') === 'admin',
            notificationList: [],
            isLoading: false,
            currentPage: 1,
            totalCount: null,
            totalPerPage: {},
            totalPage: null,
            showHideList: false,
        };
    },
    computed: {
        unreadMessage() {
            let unread = 0;
            this.notificationList.forEach((item) => {
                if (!item.readAt) unread++;
            });
            return unread;
        },
    },
    mounted() {
        this.notificationList = [];
        this.handleGetNotifications();
    },
    methods: {
        collapsedSider() {
            this.$emit('open');
        },
        formatDate(date) {
            return moment(date).format('MMMM, DD YYYY hh:mm A');
        },
        async viewTimeDisputeRequest(id) {
            const { data: request } = await getRequestRecordById(id);
            await this.$refs.ViewTimeDisputeRequest.openModal(request, false);
        },
        async viewLeaveRequest(id) {
            const { data: request } = await getRequestRecordById(id);
            await this.$refs.ViewLeaveRequest.openModal(request, false);
        },
        handleGetNotifications() {
            this.isLoading = true;
            getPaginatedNotificationList({}, this.currentPage, 2)
                .then((res) => {
                    console.log(res);
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.totalPage = res.data.totalPages;
                        res.data.data.map((item) => {
                            this.notificationList.push(item);
                        });
                    }
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.notificationList = [];
                    this.isLoading = false;
                });
        },
        handleLogout() {
            try {
                localStorage.clear();
                window.location.href = '/login';
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
        viewProfile() {
            this.$router.push({ name: 'Profile' });
        },
        handleLoadMore() {
            this.currentPage = this.currentPage + 1;
            this.handleGetNotifications();
        },
        handleReadAll(read) {
            if (read && this.unreadMessage > 0) {
                postReadAllNotifications()
                    .then(async () => {
                        await this.handleGetNotifications();
                        this.notificationList = this.notificationList.map((notification) => {
                            return {
                                ...notification,
                                ...(!notification.readAt && { readAt: new Date() }),
                            };
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        showList() {
            this.showHideList = !this.showHideList;
            this.handleReadAll(this.showHideList);
        },
    },
};
</script>

<style lang="scss" scoped>
.app-header {
    background: #ebebeb 0% 0% no-repeat padding-box !important;
    box-shadow: none !important;
    .sider-trigger {
        background-color: #463c6e;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logout-btn {
        color: $error-color;
        margin-right: 55px;

        .ivu-dropdown-rel {
            .ivu-icon {
                position: relative;
                top: -2px;
                border: solid 1px;
                border-radius: 20px;
                width: 21px;
                font-size: 16px !important;
            }
        }
    }
    .logout-btn a,
    .logout-btn button {
        color: $primary-color;
    }
    .ivu-dropdown-item:hover button {
        color: $error-color;
    }
    .logo-wrapper {
        justify-content: center;
    }
    .notification {
        position: relative;
        display: inline-block;
        margin-right: 10px;
    }

    .notification .badge {
        position: relative;
        top: -10px;
        right: 10px;
        padding: 1px 3px;
        border-radius: 50%;
        background: red;
        color: white;
    }
    .notifications-container {
        position: absolute;
        z-index: 999;
        width: 400px;
        height: 400px;
        overflow-y: auto;
        padding: 1px 1px 1px 10px;
        right: 140px;
        background-color: #f8f8f8;
        border: 1px solid #e8e8e8;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .notifications-container h1 {
        text-align: left;
        margin-top: 0;
        font-size: medium;
    }

    .notifications-container ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .notifications-container li {
        border-bottom: 1px solid #e8e8e8;
        padding: 1px 1px 1px 10px;
        display: grid;
        justify-content: space-between;
        align-items: center;
    }

    .notifications-container li.active::before {
        content: '';
        height: 0.65em;
        width: 0.65em;
        background: currentColor;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -0.65em;
        color: red;
        transform: translateY(-50%);
    }

    .notifications-container li:hover {
        opacity: 1;
        background-color: #f0f0f0;
    }

    .notifications-container .text::first-letter {
        text-transform: uppercase;
    }

    .notifications-container .date {
        text-align: left;
        color: #888;
        font-size: small;
    }

    .load-more-container {
        margin-top: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .sider-trigger .menu-icon {
        padding: 15px 0 !important;
    }
    .logout-btn {
        margin-right: 20px !important;
    }
    .logo-wrapper img {
        width: 100%;
    }
}
</style>
