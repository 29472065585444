<template>
    <Modal v-model="showModal" title="Change Password" footer-hide :before-close="handleReset">
        <div>
            <ErrorMessages v-if="errorMessages.length" ref="ErrorMessages" :errors="errorMessages" />
            <Form
                ref="forgotPasswordForm"
                class="change-pass-form"
                :model="formData"
                :rules="formRules"
                label-position="top"
            >
                <FormItem label="Current Password" prop="old_password">
                    <Input v-model="formData.old_password" type="password" />
                </FormItem>

                <FormItem label="New Password" prop="new_password">
                    <Input v-model="formData.new_password" type="password" />
                </FormItem>

                <FormItem label="Confirm new Password" prop="confirm_password">
                    <Input v-model="formData.confirm_password" type="password" />
                </FormItem>

                <Button type="primary" :loading="isLoading" @click="handleSubmitForm"> Submit </Button>
            </Form>
        </div>
    </Modal>
</template>

<script>
import { userChangePassword } from '@/services/users.services';
import ErrorMessages from '@/components/UI/ErrorMessages';

export default {
    components: {
        ErrorMessages,
    },
    data() {
        return {
            errorMessages: [],
            showModal: false,
            isLoading: false,
            user_id: localStorage.getItem('user_id'),
            formData: {
                old_password: '',
                new_password: '',
                confirm_password: '',
            },
            formRules: {
                old_password: [
                    {
                        required: true,
                        message: 'Please fill in the old password.',
                        trigger: 'blur',
                    },
                ],
                new_password: [
                    {
                        required: true,
                        message: 'Please fill in the new password.',
                        trigger: 'blur',
                    },
                ],
                confirm_password: [
                    {
                        required: true,
                        message: 'Please fill in the confirm password.',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        async handleSubmitForm() {
            this.$refs.forgotPasswordForm.validate((valid) => {
                if (valid) {
                    if (this.formData.new_password === this.formData.confirm_password) {
                        this.handleChangePassword();
                    } else {
                        this.$Message.error('Password must match');
                    }
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },
        handleChangePassword() {
            this.errorMessages = [];
            this.isLoading = true;
            const dataForm = {
                old_password: this.formData.old_password,
                new_password: this.formData.new_password,
            };
            userChangePassword(this.user_id, dataForm)
                .then((res) => {
                    if (res.data) {
                        this.$Message.success('Successfully Change Password');
                        this.isLoading = false;
                    }
                })
                .catch((err) => {
                    this.$Message.error('Failed to Change Password');
                    this.handleErrorResponse(err.response.data);
                    this.isLoading = false;
                });
            this.isLoading = false;
            this.handleReset();
        },
        handleErrorResponse(err) {
            if (err.statusCode === 400 && err.message === 'Invalid old password') {
                this.errorMessages.push('Invalid old password');
            }
        },
        handleReset() {
            this.$refs.forgotPasswordForm.resetFields();
            this.isLoading = false;
            this.errorMessages = [];
            this.showModal = false;
        },
    },
};
</script>
<style>
.change-pass-form .ivu-form-item-content {
    margin-left: 0;
}
</style>
