<template>
    <Modal
        v-model="showModal"
        title="Are you Clocking-in?"
        footer-hide
        class="clock-in-reminder-modal-wrapper"
        :closable="false"
        :mask-closable="false"
    >
        <div>
            <p>Hello Team Member!</p>
            <br />
            <p style="text-align: justify">
                You are about to clock in. Please confirm clock-in.<br />
                <strong>Note: You can only clock in once per day.</strong><br /><br />
                Thank you!
            </p>
            <br />

            <Row class="confirmation-actions md-margin-top" :gutter="10">
                <Col :xs="24" :sm="12">
                    <Button style="height: auto" long @click="handleConfirmation(false)">
                        NO <br />
                        Maybe later
                    </Button>
                </Col>
                <Col :xs="24" :sm="12">
                    <Button class="pt-btn-3" style="height: auto" long @click="handleConfirmation(true)">
                        YES<br />
                        Please proceed
                    </Button>
                </Col>
            </Row>
        </div>
    </Modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },

    methods: {
        openModal() {
            this.showModal = true;
        },
        handleConfirmation(status) {
            if (status) {
                this.$emit('confirm', status);
            }
            this.showModal = false;
        },
    },
};
</script>

<style>
.clock-in-reminder-modal-wrapper.v-transfer-dom .ivu-modal-body,
.ivu-modal-confirm {
    padding: 16px;
}
</style>
