import { axiosInstance } from './base';

export function getProjectMembers() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`project-member`, { headers });
}

export function getProjectMembersRole() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`project-member/roles`, { headers });
}

export function getProjectMembersByProjectId(projectId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`project-member/project/${projectId}`, { headers });
}

export function getTimelogProjectMembersByProjectId(projectId) {
    const urlString = `/project/${projectId}/members`;
    const roleUrlString = localStorage.getItem('mainRole') === 'pm' ? `pm/timelogs` : `timelogs`;
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(roleUrlString + urlString, { headers });
}

export function getProjectByProjectMembers(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`project-member/user/${userId}`, { headers });
}

export function getProjectByClient(clientId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`project-member/client/${clientId}`, { headers });
}

export function updateProjectMember(id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`project-member/${id}`, data, { headers });
}

export function addProjectMember(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`project-member`, data, { headers });
}

export function addBulkProjectMembers(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`project-member/multiple`, data, { headers });
}

export function deleteProjectMember(id) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.delete(`project-member/${id}`, { headers });
}
