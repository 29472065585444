<template>
    <div class="project-form-wrapper">
        <Row type="flex" justify="space-between" class="code-row-bg title-wrapper" style="margin-bottom: 20px">
            <Col span="6">
                <Card class="page-title no-padding" dis-hover :bordered="false">
                    <h2>{{ formTitle }}</h2>
                </Card>
            </Col>
            <Col span="6" class="text-right">
                <Button class="pt-btn-5" style="margin-right: 10px" @click="$router.go(-1)"> Cancel </Button>
                <Button class="pt-btn-1" :loading="isLoading" :disabled="isDisabled" @click="handleSubmitForm">
                    Save
                </Button>
            </Col>
        </Row>
        <!-- <Card class="section-title"> PROJECT CONFIG </Card> -->
        <Card class="project-config-section" style="margin-bottom: 20px" dis-hover>
            <Spin v-if="isLoading" size="large" fix />
            <h2 style="margin-bottom: 15px; color: #463c6e">PROJECT CONFIG</h2>
            <Form ref="ProjectForm" :model="formData" :rules="formRules" :label-width="120" label-position="left">
                <FormItem label="Project Name" prop="name">
                    <Input v-model="formData.name" @on-keyup="checkerSaveBtn" />
                </FormItem>
                <Row :gutter="30">
                    <i-col>
                        <FormItem label="Project Type" prop="projectType">
                            <RadioGroup v-model="formData.projectType" @on-change="checkerSaveBtn">
                                <Radio v-for="(type, idx) in projectTypes" :key="idx" :label="type.value">
                                    {{ type.name }}
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Color Legend" prop="legendColor">
                            <ColorPicker v-model="formData.legendColor" @on-change="checkerSaveBtn" />
                        </FormItem>
                    </i-col>
                </Row>
                <Row :gutter="20">
                    <i-col>
                        <span v-if="formData.projectType != 'RT' && formData.projectType != 'RPm'" class="dateReq"
                            >*</span
                        >
                        <FormItem label="Start Date" prop="startDate" class="dateReq">
                            <DatePicker v-model="formData.startDate" format="yyyy/MM/dd" @on-change="checkerSaveBtn" />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <span v-if="formData.projectType != 'RT' && formData.projectType != 'RPm'" class="dateReq"
                            >*</span
                        >
                        <FormItem label="End Date" prop="endDate" class="dateReq">
                            <DatePicker v-model="formData.endDate" format="yyyy/MM/dd" @on-change="checkerSaveBtn" />
                        </FormItem>
                    </i-col>
                </Row>
                <Row :gutter="10">
                    <i-col>
                        <FormItem label="Total Hours" prop="totalProjectHours">
                            <InputNumber
                                v-model="formData.totalProjectHours"
                                :min="0"
                                controls-outside
                                @on-change="checkerSaveBtn"
                            />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="UI/UX" prop="totalUIUXHours" :label-width="75">
                            <InputNumber
                                v-model="formData.totalUIUXHours"
                                :min="0"
                                controls-outside
                                @on-change="checkerSaveBtn"
                            />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Frontend" prop="totalFrontendHours" :label-width="80">
                            <InputNumber
                                v-model="formData.totalFrontendHours"
                                :min="0"
                                controls-outside
                                @on-change="checkerSaveBtn"
                            />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Backend" prop="totalBackendHours" :label-width="80">
                            <InputNumber
                                v-model="formData.totalBackendHours"
                                :min="0"
                                controls-outside
                                @on-change="checkerSaveBtn"
                            />
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="PM" prop="totalPMHours" :label-width="50">
                            <InputNumber
                                v-model="formData.totalPMHours"
                                :min="0"
                                controls-outside
                                @on-change="checkerSaveBtn"
                            />
                        </FormItem>
                    </i-col>
                </Row>
                <Row :gutter="20">
                    <i-col>
                        <FormItem label="Slack" prop="slack">
                            <Select
                                v-model="formData.slack"
                                filterable
                                multiple
                                style="width: 250px"
                                :loading="isSlackLoading"
                                @on-change="checkerSaveBtn"
                            >
                                <Option v-for="item in slackChannels" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Asana" prop="asana">
                            <Select
                                v-model="formData.asana"
                                multiple
                                filterable
                                :loading="isAsanaLoading"
                                style="width: 250px"
                                @on-change="checkerSaveBtn"
                            >
                                <Option v-for="item in asanaProjects" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Trello" prop="trello">
                            <Select
                                v-model="formData.trello"
                                filterable
                                multiple
                                :loading="isTrelloLoading"
                                style="width: 250px"
                                @on-change="checkerSaveBtn"
                            >
                                <Option v-for="item in trelloBoards" :key="item.value" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col>
                        <FormItem label="Jira" prop="jira">
                            <Input
                                v-model="formData.jira"
                                placeholder="Input Key: ex HHP"
                                style="width: 250px"
                                @on-keyup="checkerSaveBtn"
                            />
                        </FormItem>
                    </i-col>
                    <i-col style="padding: 2px">
                        <Button v-if="!apiKeydata" type="primary" @click="showInputField"> Generate API Key </Button>
                    </i-col>
                    <i-col style="padding: 2px">
                        <Button v-if="apiKeydata" type="primary" @click="openGenerateModal">
                            Generate New API Key
                        </Button>
                    </i-col>
                    <i-col style="padding: 2px">
                        <Input v-if="apiKeydata" v-model="apiKeydata" readonly style="width: 250px" />
                    </i-col>
                </Row>

                <FormItem label="Bitbucket" prop="bitbucket">
                    <Button
                        shape="circle"
                        icon="ios-add"
                        size="small"
                        style="margin-right: 10px; background-color: #e9495d; color: #fff"
                        @click="showBitbucketModalList"
                    />
                    <Tag
                        v-for="(bit, idx) in formData.bitbucket"
                        :key="idx"
                        closable
                        style="margin-right: 10px"
                        @on-close="handleOnRemoveBitbucket(idx)"
                    >
                        {{ bit.name }}
                    </Tag>
                </FormItem>
            </Form>
            <Row v-if="formData._id" :gutter="20" style="margin-top: 40px; margin-bottom: 40px">
                <Col :xs="24" :sm="24" :md="12" :lg="12">
                    <ProjectTeamMembers />
                </Col>
                <Col :xs="24" :sm="24" :md="12" :lg="12">
                    <ProjectClients />
                </Col>
            </Row>
        </Card>

        <BitbucketModalList
            ref="BitbucketModalList"
            :selected="formData.bitbucket"
            @newSelected="(data) => formData.bitbucket.push(data)"
        />
        <GenerateNewAPIKeyModal ref="GenerateNewAPIKeyModal" @confirm="showInputField" />
    </div>
</template>

<script>
import moment from 'moment';
import { postProjects, getProjectsById, updateProjectsById, getProjectsApiKeyById } from '@/services/projects.services';
import { getTrelloBoards } from '@/services/trello.services';
import { getSlackChannels } from '@/services/slack.services';
import { getAsanaProjects } from '@/services/asana.services';
import ProjectTeamMembers from './ProjectTeamMembers';
import ProjectClients from './ProjectClients';
import BitbucketModalList from './BitbucketModalList';
import GenerateNewAPIKeyModal from './GenerateNewAPIKeyModal';

export default {
    components: {
        ProjectTeamMembers,
        ProjectClients,
        BitbucketModalList,
        GenerateNewAPIKeyModal,
    },
    data() {
        const validateStartEndDate = (rule, value, callback) => {
            if (value === null) {
                callback(new Error('Please enter project type'));
            } else if (value != 'RT' && value != 'RPm') {
                this.formRules.startDate[0].required = true;
                this.formRules.endDate[0].required = true;
                callback();
            } else {
                this.formRules.startDate[0].required = false;
                this.formRules.endDate[0].required = false;
                callback();
            }
        };
        return {
            formTitle: this.$route.query.id ? 'EDIT PROJECT' : 'CREATE NEW PROJECT',
            isSlackLoading: false,
            isTrelloLoading: false,
            isBitbucketLoading: false,
            isAsanaLoading: false,
            isLoading: false,
            isDisabled: true,
            dataDefault: {},
            apiKeydata: '',
            formData: {
                name: null,
                projectType: null,
                legendColor: this.getRandomColor(),
                startDate: null,
                endDate: null,
                totalProjectHours: 0,
                totalUIUXHours: 0,
                totalFrontendHours: 0,
                totalBackendHours: 0,
                totalPMHours: 0,
                slack: [],
                bitbucket: [],
                asana: [],
                trello: [],
                jira: '',
                apiKey: '',
            },
            formRules: {
                name: [
                    {
                        required: true,
                        message: 'The name cannot be empty',
                        trigger: 'blur',
                    },
                ],
                projectType: [
                    {
                        validator: validateStartEndDate,
                        required: true,
                        message: 'Please select project type',
                        trigger: 'change',
                    },
                ],
                startDate: [
                    {
                        type: 'date',
                        message: 'Please select the start date',
                        trigger: 'change',
                    },
                ],
                endDate: [
                    {
                        type: 'date',
                        message: 'Please select the end date',
                        trigger: 'change',
                    },
                ],
                totalProjectHours: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Please input total Project hours',
                        trigger: 'blur',
                    },
                ],
                totalUIUXHours: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Please input total UI/UX hours',
                        trigger: 'blur',
                    },
                ],
                totalFrontendHours: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Please input total Frontend hours',
                        trigger: 'blur',
                    },
                ],
                totalBackendHours: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Please input total Backend hours',
                        trigger: 'blur',
                    },
                ],
                totalPMHours: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Please input total PM hours',
                        trigger: 'blur',
                    },
                ],
                legendColor: [
                    {
                        required: true,
                        message: 'Please select color legend of the project',
                        trigger: 'change',
                    },
                ],
            },
            trelloBoards: [],
            slackChannels: [],
            asanaProjects: [],
        };
    },

    async mounted() {
        this.isLoading = true;
        this.trelloBoards = await this.handleGetTrelloBoards();
        this.slackChannels = await this.handleGetSlackChannels();
        this.asanaProjects = await this.handleGetAsanaProjects();
        this.handleSetFormData();
    },

    methods: {
        async handleSetFormData() {
            if (this.$route.query.id) {
                this.dataDefault = await this.handleGetProjectsById(this.$route.query.id);
                this.formData = await this.handleGetProjectsById(this.$route.query.id);
            } else {
                this.dataDefault = {
                    name: null,
                    projectType: null,
                    legendColor: this.getRandomColor(),
                    startDate: null,
                    endDate: null,
                    totalProjectHours: 0,
                    totalUIUXHours: 0,
                    totalFrontendHours: 0,
                    totalBackendHours: 0,
                    totalPMHours: 0,
                    slack: [],
                    bitbucket: [],
                    asana: [],
                    trello: [],
                    jira: '',
                };
                this.formData = {
                    name: null,
                    projectType: null,
                    legendColor: this.getRandomColor(),
                    startDate: null,
                    endDate: null,
                    totalProjectHours: 0,
                    totalUIUXHours: 0,
                    totalFrontendHours: 0,
                    totalBackendHours: 0,
                    totalPMHours: 0,
                    slack: [],
                    bitbucket: [],
                    asana: [],
                    trello: [],
                    jira: '',
                };
                this.isLoading = false;
            }
        },

        getRandomColor() {
            let letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        async handleGetProjectsById(id) {
            return await getProjectsById(id)
                .then((res) => {
                    this.apiKeydata = res.data.apiKey;
                    this.isLoading = false;
                    const result = {
                        ...res.data,
                        apiKey: this.apiKeydata,
                        trello: res.data.trello.map((x) => x.id),
                        slack: res.data.slack.map((x) => x.id),
                        asana: res.data.asana.map((x) => x.id),
                        jira: res.data.jira.map((x) => x.key),
                    };
                    return result;
                })
                .catch(() => {
                    this.$Message.error('Failed to fetch data');
                    this.isLoading = false;
                });
        },

        async handleGetTrelloBoards() {
            this.isTrelloLoading = true;
            this.trelloBoards = [];
            return await getTrelloBoards()
                .then(async (res) => {
                    if (res.data.length) {
                        const result = await res.data.map((boards) => {
                            return {
                                label: boards.name,
                                value: boards.id,
                                id: boards.id,
                                name: boards.name,
                                url: boards.url,
                            };
                        });
                        this.isTrelloLoading = false;
                        return result;
                    } else {
                        this.isTrelloLoading = false;
                        return [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get trello boards');
                    this.isTrelloLoading = false;
                    return [];
                });
        },

        async handleGetSlackChannels() {
            this.isSlackLoading = true;
            this.slackChannels = [];
            return await getSlackChannels()
                .then(async (res) => {
                    if (res.data.channels.length) {
                        const result = await res.data.channels.map((channels) => {
                            return {
                                label: channels.name,
                                value: channels.id,
                                id: channels.id,
                                name: channels.name,
                            };
                        });
                        this.isSlackLoading = false;
                        return result;
                    } else {
                        this.isSlackLoading = false;
                        return [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get trello boards');
                    this.isSlackLoading = false;
                    return [];
                });
        },

        async handleGetAsanaProjects() {
            this.isAsanaLoading = true;
            this.asanaProjects = [];
            return await getAsanaProjects()
                .then(async (res) => {
                    if (res.data.data.length) {
                        const result = await res.data.data.map((proj) => {
                            return {
                                label: proj.name,
                                value: proj.gid,
                                id: proj.gid,
                                name: proj.name,
                            };
                        });
                        this.isAsanaLoading = false;
                        return result;
                    } else {
                        this.isAsanaLoading = false;
                        return [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get asana projects');
                    this.isAsanaLoading = false;
                    return [];
                });
        },

        async handleSubmitForm() {
            this.$refs.ProjectForm.validate((valid) => {
                if (valid) {
                    this.isLoading = true;
                    this.submitForm();
                } else {
                    this.$Message.error('Fill in the required fields');
                    this.isLoading = false;
                }
            });
        },

        async submitForm() {
            this.formData = {
                ...this.formData,
                startDate: this.formData.startDate ? moment(this.formData.startDate).format('YYYY-MM-DD') : '',
                endDate: this.formData.endDate ? moment(this.formData.endDate).format('YYYY-MM-DD') : '',
                trello: this.setSelectedTrelloBoards(),
                slack: this.setSelectedSlackChannels(),
                asana: this.setSelectedAsanaProjects(),
                jira: [
                    {
                        id: '',
                        key: this.formData.jira,
                    },
                ],
            };
            if (this.formData._id) {
                await updateProjectsById(this.formData._id, this.formData)
                    .then(() => {
                        this.$Message.success('Project sucessfully updated');
                        this.isLoading = true;
                        this.$refs.ProjectForm.resetFields();
                        this.$router.go(-1);
                    })
                    .catch((err) => {
                        if (err.data.statusCode === 400 && err.data.message === 'trello already used') {
                            this.$Message.error('Trello board already used');
                        } else if (err.data.statusCode === 400 && err.data.message === 'Legend already exist') {
                            this.$Message.error('Color legend already exist');
                        } else {
                            this.$Message.error('Failed to update project');
                        }
                        this.isLoading = false;
                    });
            } else {
                await postProjects(this.formData)
                    .then(() => {
                        this.$Message.success('Project added sucessfully');
                        this.isLoading = true;
                        this.$refs.ProjectForm.resetFields();
                        this.$router.push({ name: 'Projects' });
                    })
                    .catch((err) => {
                        if (err.data.statusCode === 400 && err.data.message === 'trello already used') {
                            this.$Message.error('Trello board already used');
                        } else if (err.data.statusCode === 400 && err.data.message === 'Legend already exist') {
                            this.$Message.error('Color legend already exist');
                        } else {
                            this.$Message.error('Failed to add project');
                        }
                        this.$refs.ProjectForm.resetFields();
                        this.isLoading = false;
                    });
            }
        },

        setSelectedTrelloBoards() {
            if (this.formData.trello && this.trelloBoards.length) {
                const selectedData = this.trelloBoards.filter((item) => this.formData.trello.indexOf(item.value) > -1);
                return selectedData;
            } else {
                if (this.formData.trello.length) {
                    return this.formData.trello;
                } else {
                    return [];
                }
            }
        },

        setSelectedSlackChannels() {
            if (this.formData.slack && this.slackChannels.length) {
                const selectedData = this.slackChannels.filter((item) => this.formData.slack.indexOf(item.value) > -1);
                return selectedData;
            } else {
                if (this.formData.slack.length) {
                    return this.formData.slack;
                } else {
                    return [];
                }
            }
        },

        setSelectedAsanaProjects() {
            if (this.formData.asana && this.asanaProjects.length) {
                const selectedData = this.asanaProjects.filter((item) => this.formData.asana.indexOf(item.value) > -1);
                return selectedData;
            } else {
                if (this.formData.asana.length) {
                    return this.formData.asana;
                } else {
                    return [];
                }
            }
        },

        showBitbucketModalList() {
            this.isDisabled = false;
            this.$refs.BitbucketModalList.openModal();
        },

        handleOnRemoveBitbucket(idx) {
            this.isDisabled = false;
            this.formData.bitbucket.splice(idx, 1);
        },

        checkerSaveBtn() {
            const isEqual = (...objects) =>
                objects.every((obj) => {
                    obj.endDateFormat = moment(obj.endDate).format('YYYY-MM-DD');
                    objects[0].endDateFormat = moment(objects[0].endDate).format('YYYY-MM-DD');
                    obj.startDateFormat = moment(obj.startDate).format('YYYY-MM-DD');
                    objects[0].startDateFormat = moment(objects[0].startDate).format('YYYY-MM-DD');

                    if (
                        JSON.stringify(obj.startDateFormat) === JSON.stringify(objects[0].startDateFormat) &&
                        JSON.stringify(obj.endDateFormat) === JSON.stringify(objects[0].endDateFormat) &&
                        JSON.stringify(obj.name) === JSON.stringify(objects[0].name) &&
                        JSON.stringify(obj.projectType) === JSON.stringify(objects[0].projectType) &&
                        JSON.stringify(obj.legendColor) === JSON.stringify(objects[0].legendColor) &&
                        JSON.stringify(obj.totalProjectHours) === JSON.stringify(objects[0].totalProjectHours) &&
                        JSON.stringify(obj.totalUIUXHours) === JSON.stringify(objects[0].totalUIUXHours) &&
                        JSON.stringify(obj.totalFrontendHours) === JSON.stringify(objects[0].totalFrontendHours) &&
                        JSON.stringify(obj.totalBackendHours) === JSON.stringify(objects[0].totalBackendHours) &&
                        JSON.stringify(obj.totalPMHours) === JSON.stringify(objects[0].totalPMHours) &&
                        JSON.stringify(obj.slack) === JSON.stringify(objects[0].slack) &&
                        JSON.stringify(obj.asana) === JSON.stringify(objects[0].asana) &&
                        JSON.stringify(obj.trello) === JSON.stringify(objects[0].trello) &&
                        JSON.stringify(obj.jira) === JSON.stringify(objects[0].jira)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
            if (isEqual(this.formData, this.dataDefault)) {
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
            }
        },

        async showInputField(id) {
            id = this.$route.query.id;
            return await getProjectsApiKeyById(id)
                .then((res) => {
                    this.$Message.success('API Key successfully generated');
                    this.isLoading = false;
                    this.apiKeydata = res.data.apiKey;
                    return this.apiKeydata;
                })
                .catch(() => {
                    this.$Message.error('Failed to fetch data');
                    this.isLoading = false;
                });
        },

        openGenerateModal() {
            this.$refs.GenerateNewAPIKeyModal.openModal();
        },
    },
};
</script>
<style lang="scss">
.project-config-section .ivu-form-item-error-tip {
    white-space: nowrap;
}
.dateReq {
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
}
span.dateReq {
    margin-top: 12px;
    color: #ed4014;
}
.ivu-input-number-controls-outside-btn {
    color: #fff;
    background-color: #e9495d;
}

@media screen and (max-width: 767px) {
    .project-form-wrapper {
        .title-wrapper {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .text-right {
                text-align: left;
            }
        }

        .project-config-section {
            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
