<template>
    <div class="client-dashboard">
        <Projects @on-project-select="(data) => (selectedProject = data)" />
        <TeamMembers class="md-margin-top" :project="selectedProject" />
    </div>
</template>

<script>
import Projects from '@/components/Dashboard/Client/Projects';
import TeamMembers from '@/components/Dashboard/Client/TeamMembers';

export default {
    components: {
        Projects,
        TeamMembers,
    },
    data() {
        return {
            selectedProject: null,
        };
    },
};
</script>
