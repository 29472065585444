<template>
    <div>
        <Row :gutter="20" style="margin-bottom: 30px">
            <Col :xs="20" :sm="10" :md="10" :lg="6" class="d-flex-center">
                <span style="margin-right: 20px">Project</span>
                <Select
                    filterable
                    clearable
                    :loading="isLoading"
                    class="md-margin-right"
                    style="max-width: 300px"
                    @on-change="handleOnProjectChanged"
                >
                    <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </Option>
                </Select>
            </Col>
            <Col :xs="20" :sm="10" :md="10" :lg="10" class="d-flex-center">
                <span style="margin-right: 20px">Team Member</span>
                <Select
                    :value="$route.query.id"
                    filterable
                    clearable
                    :loading="isLoading"
                    class="md-margin-right"
                    style="max-width: 300px"
                >
                    <Option v-for="item in projectMembersList" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </Option>
                </Select>
            </Col>
        </Row>
        <div class="questions" style="margin-bottom: 20px">
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Project Rating for the project working with this team member:</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>How do you rate this team member ability to communicate properly?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Are you satisfied with the level of information provided?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Was our team member able to make valuable recommendations?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
            <Row>
                <Col span="10" class="d-flex-center">
                    <p>Is the application developed compliant with your requirements?</p>
                </Col>
                <Col span="10" class="d-flex-center">
                    <Rate />
                </Col>
            </Row>
        </div>
        <div class="project-feedback-composer" style="position: relative">
            <Spin v-if="isLoading" size="large" fix />
            <wysiwyg v-if="renderComponent" v-model="teamFeedback" />
            <Button slot="extra" class="pt-btn-1" style="width: 100px; margin-top: 20px" :loading="isLoading">
                Submit
            </Button>
        </div>
    </div>
</template>

<script>
import { getProjects } from '@/services/projects.services';
import {
    getProjectByClient,
    getProjectMembers,
    getProjectMembersByProjectId,
} from '@/services/project-members.services';
export default {
    data() {
        return {
            user_id: localStorage.getItem('user_id'),
            mainRole: localStorage.getItem('mainRole'),
            renderComponent: true,
            isLoading: false,
            projectsList: [],
            projectMembersList: [],
            selectedProject: null,
            selectedMember: null,
            teamFeedback: '',
        };
    },

    mounted() {
        this.handleGetProjectsList();
        this.handleGetProjectMembersList();
    },

    methods: {
        handleGetProjectsList() {
            this.projectsList = [];
            const projects = this.mainRole === 'client' ? getProjectByClient(this.user_id) : getProjects();
            projects
                .then((res) => {
                    if (res.data.length) {
                        let filteredData = [];
                        if (this.mainRole === 'client') {
                            filteredData = res.data.filter(function (e) {
                                return e.project.isArchived === false && e.project.isActive === true;
                            });
                        } else {
                            filteredData = res.data.filter(function (e) {
                                return e.isArchived === false && e.isActive === true;
                            });
                        }

                        this.projectsList = filteredData.map((i) => {
                            return this.mainRole === 'client'
                                ? {
                                      value: i.project._id,
                                      label: i.project.name,
                                  }
                                : {
                                      value: i._id,
                                      label: i.name,
                                  };
                        });
                    } else {
                        this.projectsList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.projectsList = [];
                });
        },
        handleGetProjectMembersByProjectId(projId) {
            getProjectMembersByProjectId(projId)
                .then((res) => {
                    if (res.data.length) {
                        this.parseMembersListData(res.data);
                    } else {
                        this.projectMembersList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members by id');
                    this.projectMembersList = [];
                });
        },
        handleGetProjectMembersList() {
            this.projectMembersList = [];
            getProjectMembers()
                .then((res) => {
                    if (res.data.length) {
                        this.parseMembersListData(res.data);
                    } else {
                        this.projectMembersList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members list');
                    this.projectMembersList = [];
                });
        },
        parseMembersListData(data) {
            let resArr = [];
            data.filter((item) => {
                if (item.user) {
                    const i = resArr.findIndex((x) => x.user._id == item.user._id);
                    if (i <= -1) {
                        resArr.push(item);
                    }
                }
                return null;
            });
            this.projectMembersList = resArr.map((i) => {
                return {
                    value: i.user._id,
                    label: i.user.name,
                };
            });
        },
        handleOnProjectChanged(projId) {
            this.selectedProject = projId;
            if (projId) {
                this.handleGetProjectMembersByProjectId(projId);
            } else {
                this.handleGetProjectMembersList();
            }
        },

        handleReload() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.teamFeedback = '';
                this.renderComponent = true;
            });
        },

        // handleOnSendEmail() {
        // },
    },
};
</script>
