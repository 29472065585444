import { axiosInstance } from './base';

export function getLeaderboard(month, id, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/rewards/leader-boards`, {
        params: { startDate: month, user: id, project: project },
        headers,
    });
}

export function getLeaderboardByProject(month, project, user) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${project}/rewards/leader-boards`, {
        params: { startDate: month, user: user },
        headers,
    });
}

export function getLeaderboardAllProject(month, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/rewards/leader-boards`, {
        params: { startDate: month, project: project },
        headers,
    });
}

export function getUserEmailLogs(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/email-logs`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}

export function getUserWeeklyTasks(month, id, page, type, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/tasks`, {
        params: { startDate: month, page: page, type: type, project: project },
        headers,
    });
}

export function getUserLikes(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/likes`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}

export function getUserGeekyLogs(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/geeky-logs`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}

export function getUserDailyStandUp(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/daily-stand-up`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}

export function getUserChattyLogs(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/chatty-logs`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}

export function getUserSweatLogs(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/sweat-equity-logs`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}

export function getUserBrainLogs(month, id, page, project) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`users/${id}/brain-equity-logs`, {
        params: { startDate: month, page: page, project: project },
        headers,
    });
}
