<template>
    <div class="clients-table-list">
        <Table :columns="tblColumns" :data="clients" size="small" :loading="isLoading" />
    </div>
</template>

<script>
import moment from 'moment';
import { requestClientResetPassword } from '@/services/auth.services';

export default {
    props: {
        clients: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userRole: localStorage.getItem('mainRole'),
            tblColumns: [
                {
                    title: 'Name',
                    key: 'name',
                    sortable: true,
                    sortType: 'asc',
                    minWidth: 180,
                    sortMethod: (a, b, type) => {
                        if (type == 'asc') {
                            return a > b ? 1 : -1;
                        } else {
                            return a > b ? 1 : -1;
                        }
                    },
                    //fixed: 'left',
                },
                {
                    title: 'Email',
                    key: 'email',
                    minWidth: 180,
                },
                {
                    title: 'Role',
                    key: 'mainRole',
                    minWidth: 125,
                    render: (h, params) => {
                        if (params.row.mainRole) {
                            const role = this.mainRole.find((x) => x.value === params.row.mainRole).name;
                            return h('span', role);
                        } else {
                            return h('span', '');
                        }
                    },
                },
                {
                    title: 'Last Login',
                    key: 'monitoring_last_login',
                    width: 180,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.monitoring_last_login
                                ? moment(params.row.monitoring_last_login).utc().format('DD-MMM-YYYY hh:mm:ss A')
                                : ''
                        );
                    },
                },
                {
                    title: 'Actions',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-create',
                                        size: '20',
                                    },
                                    on: {
                                        click: () => {
                                            this.$router.push(`/form-user?id=${params.row._id}`);
                                        },
                                    },
                                },
                                'edit'
                            ),
                            h(
                                'Icon',
                                {
                                    props: {
                                        type: 'md-share-alt',
                                        size: 20,
                                    },
                                    on: {
                                        click: () => {
                                            requestClientResetPassword(params.row)
                                                .then((res) => {
                                                    if (res.data) {
                                                        this.$Message.success('Successfully Send Email');
                                                    }
                                                })
                                                .catch(() => {
                                                    this.$Message.error('Failed to Send Email');
                                                });
                                        },
                                    },
                                },
                                'send-invite-email'
                            ),
                        ]);
                    },
                },
            ],
        };
    },
    mounted() {
        this.setTableColumn();
    },
    methods: {
        setTableColumn() {
            if (this.userRole === 'pm') {
                this.tblColumns.splice(4, 1); //remove Action Column
            }
        },
    },
};
</script>

<style></style>
